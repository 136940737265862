import React, {useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeProvider } from  '@mui/system';
import theme from '../theme';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const VerifyEmail = () => {
	const navigate = useNavigate(); 
	
    return (
    <ThemeProvider theme={theme}>

		<Typography sx={{m:'64px'}} align='center' variant='h4'>Meadow Console</Typography>
      	<Container component="main" maxWidth="xs">
			  
			
			<Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
				You need to verify your email. 
				</Typography>
				<Typography component="h2" variant="caption" gutterBottom>
				Click on the verification link sent to you in an email. 
				</Typography>
				<Box sx={{ mt: 1 }}>
					
					<Button
						fullWidth
						variant="outlined"
						sx={{ mt: 3, mb: 2 }}
						onClick={()=>navigate('/signin')}
					>
					Sign in
					</Button>
                   
				</Box>
        	</Box>
      	</Container>
    </ThemeProvider>
    );
}

export default VerifyEmail; 