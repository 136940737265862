import React, { useState, useEffect, useContext } from "react";
import { useNavigate} from "react-router-dom";
import MainContext from "../MainContext";


import Box from '@mui/material/Box';

import ListItemIcon from '@mui/material/ListItemIcon';
import { IconButton, Button, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logout from '@mui/icons-material/Logout';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';





const TopBar = ({title, backNavigate }) => {
    const navigate = useNavigate();
    const mainService = useContext(MainContext);
    const uid = mainService.Uid();

    const [signOutButtonDisabled, setSignOutButtonDisabled] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [profileImgUrl, setProfileImgUrl] = useState("");
    const [adminRole, setAdminRole] = useState('collab');

    useEffect(() => {
        mainService.getThisUserProfileImageThumb((url) => {
            if (url && url !== "") {
                setProfileImgUrl(url);
            }
        });

        mainService.addDataListener("adminRoles/" + uid, handleAdminRoleChanged);


        return () => {
            // cleanup
            mainService.removeDataListener("adminRoles/" + uid, handleAdminRoleChanged);

        }

    }, []);


    const handleAdminRoleChanged = (role) => {
        setAdminRole(role);
    }


    const handleAccountMenuOpen = (event) => {
        console.log('handleAccountMenuOpen');
        setAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        mainService.SignOut()
            .then(() => {
                setSignOutButtonDisabled(true);
            })
            .catch(() => {
                setSignOutButtonDisabled(false)
            })
    }


    return (
        <Box sx={{ display: 'flex', alignItems: 'center', px: '10px', height: '60px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
            {backNavigate ? (
                <React.Fragment>
                <IconButton onClick={() => navigate(backNavigate, { tabValue: 1 })}>
                    <ArrowBackIcon />
                </IconButton>
                    <Typography sx={{ ml: '8px' }}>
                        {title}
                     </Typography>
                </React.Fragment>
            ) : (
                <Typography>
                    Meadow Console
                </Typography>
            )}
            <Box sx={{ flexGrow: 1 }}></Box>
            {/* <Button sx={{ mr: '20px' }} onClick={() => navigate({ pathname: '/faq' })} >FAQ</Button> */}
            {/* <Button sx={{ mr: '20px' }} onClick={() => navigate({ pathname: '/guide' })} >Guide</Button> */}
            <Button sx={{ mr: '20px' }} onClick={(e) => {
                e.preventDefault();
                window.location.href = 'https://manual.meadow.space';
            }} >Manual</Button>


            <Tooltip title="Profile">
                <IconButton
                    onClick={handleAccountMenuOpen}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={profileMenuOpen ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={profileMenuOpen ? 'true' : undefined}
                >
                    {profileImgUrl && profileImgUrl !== "" ? (<img
                        src={profileImgUrl}
                        alt="Profile"
                        style={{ width: 35, height: 35, borderRadius: '50px' }} // Adjust size as needed
                    />) : <AccountCircleIcon />}


                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={profileMenuOpen}
                onClose={handleAccountMenuClose}
                onClick={handleAccountMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={() => navigate({ pathname: '/settings' })}>
                    {profileImgUrl !== "" ? (<Avatar alt="profile" src={profileImgUrl} />) : <Avatar />}
                    Profile
                </MenuItem>
                <Divider />
                {adminRole === 'master' && (
                    <MenuItem onClick={() => navigate({ pathname: '/appConfig' })}>
                        <ListItemIcon>

                            <AppSettingsAltIcon fontSize="small" />
                        </ListItemIcon>

                        App Config
                    </MenuItem>
                )}
                {adminRole === 'master' && (
                    <MenuItem onClick={() => navigate({ pathname: '/mctrl' })}>
                        <ListItemIcon>

                            <AdminPanelSettingsIcon fontSize="small" />
                        </ListItemIcon>

                        Master Controls
                    </MenuItem>
                )}

                {adminRole === 'master' && (<Divider />)}



                {/* <MenuItem onClick={handleAccountMenuClose}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Add another account
                </MenuItem> */}
                {/* <MenuItem onClick={() => navigate({ pathname: '/settings' })}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem> */}
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>


        </Box>

    )


}

export default TopBar;