import { initializeApp, getApps } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
    getAuth,
    setPersistence,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInAnonymously,
    signOut,
    sendEmailVerification,
    browserLocalPersistence,
    browserSessionPersistence,
    inMemoryPersistence,
    sendPasswordResetEmail,
    deleteUser,
    updateProfile
} from "firebase/auth";

import { getDatabase, ref, set, get, push, update, onValue, off, remove } from "firebase/database";

import { getMetadata, ref as sref } from "firebase/storage";
import { getStorage, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage";

import { v4 as uuidv4 } from 'uuid';

import validator from 'validator';
import Geo from './Geo';

const GeoFire = require('geofire');




const cloudFunctionsRegion = "europe-west1";

//temp include AdamArtworkLocationsUpdated.json
// import AdamArtworkLocationsUpdated from './AdamArtworkLocationsUpdated.json';


const firebaseConfig = {
    apiKey: "AIzaSyBQzJG7-vPFWKuLlStH58TxSXyu7J5rrI4",
    authDomain: "xref-client.firebaseapp.com",
    databaseURL: "https://xref-client-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "xref-client",
    storageBucket: "xref-client.appspot.com",
    messagingSenderId: "975342479651",
    appId: "1:975342479651:web:21bc6680acb85e31fc8496",
    measurementId: "G-SD7ZZS7C9H"
};

export default class MainService {

    static AUTHED = 2;
    static AUTHING = 1;
    static NOTAUTHED = 0;

    constructor() {

        console.log('Initializing Service Context');

        //Init app
        if (!getApps().length)
            initializeApp(firebaseConfig);

        //auth state 
        this.authed = MainService.AUTHING;

        //Data listners (all listenrs in firebase) 
        this.dataListnerCallbacks = new Map();

        this.geoFireContents = new GeoFire.GeoFire(ref(getDatabase(), "geoFireContents"));
        this.geoFireExhibitions = new GeoFire.GeoFire(ref(getDatabase(), "geoFireExhibitions"));


        //authchange callback
        this.authChangeCallbacks = [];
        let CallAuthChangedCallbacks = () => {
            for (let i = 0; i < this.authChangeCallbacks.length; i++) {
                if (this.authChangeCallbacks[i]) {
                    this.authChangeCallbacks[i](this.authed);
                }
            }
        }

        //Auth state change	
        let OnAuthChange = (user) => {
            if (user) {
                if (user.emailVerified) {
                    // User is signed in, get token and set variables
                    user.getIdToken()

                        //get token
                        .then((token) => {

                            this.authed = MainService.AUTHED;
                            this.user = user;
                            this.idtoken = token;

                            CallAuthChangedCallbacks();
                        })

                        //error
                        .catch((error) => {

                            this.authed = MainService.NOTAUTHED;
                            this.idtoken = null;
                            this.user = null;

                            CallAuthChangedCallbacks();
                        });
                }
                else {
                    // No user is signed in.
                    this.authed = MainService.NOTAUTHED;
                    this.idtoken = null;
                    this.user = null;

                    CallAuthChangedCallbacks();

                }

            } else {
                // No user is signed in.
                this.authed = MainService.NOTAUTHED;
                this.idtoken = null;
                this.user = null;

                CallAuthChangedCallbacks();
            }
        };

        getAuth().onAuthStateChanged(OnAuthChange);


    }
    //#region Auth
    AddAuthChangeCallback(callback) {
        this.authChangeCallbacks.push(callback);
    }

    RemoveAuthChangeCallback(callback) {
        const index = this.authChangeCallbacks.indexOf(callback);
        if (index > -1) {
            this.authChangeCallbacks.splice(index, 1);
        }
    }

    CheckIsAuthed() {
        return this.authed === MainService.AUTHED;
    }

    CheckIsAuthing() {
        return this.authed === MainService.AUTHING;
    }

    CheckNotAuthed() {
        return this.authed === MainService.NOTAUTHED;
    }

    GetAuthed() {
        return this.authed;
    }

    Uid() {
        let auth = getAuth();
        if (!auth || !auth.currentUser || !auth.currentUser.uid)
            return "";
        return auth.currentUser.uid;
    }

    async GetAdminRole() {
        //wait until authed 
        while (this.authed === MainService.AUTHING) {
            await new Promise(r => setTimeout(r, 100));
        }
        let auth = getAuth();
        if (!auth || !auth.currentUser || !auth.currentUser.uid) {
            //return rejected empty promise
            return new Promise((resolve, reject) => {
                console.log("GetAdminRole failed, no user");
                resolve("");
            });
        }
        let db = getDatabase();
        let R = ref(db, "adminRoles/" + auth.currentUser.uid);
        return get(R).then((snapshot) => {
            if (snapshot.exists()) {
                return snapshot.val();
            }
            else {
                return "";
            }
        });

    }
    removeAuthEmail(email) {
        let auth = getAuth();
        const db = getDatabase();
        //remove email from authorizedEmails
        let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
        let earRef = ref(db, 'authorizedEmails/' + safeEmail);
        return remove(earRef);
    }
    addAuthEmail(email) {
        let auth = getAuth();
        const db = getDatabase();
        //add email to authorizedEmails
        let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
        let earRef = ref(db, 'authorizedEmails/' + safeEmail);
        return set(earRef, true);
    }
    addPublicUser(user) {
        if (!user.id || !user.username) {
            console.error("addPublicUser failed, user.id or user.username undefined");
            return;
        }
        const db = getDatabase();
        let userRef = ref(db, 'publicUsers/' + user.id);
        let encUsr = this.encodeUsername(user.username);
        let data = { username: encUsr, name: user.name };
        return set(userRef, data);

    }
    removePublicUser(uid) {
        const db = getDatabase();
        let userRef = ref(db, 'publicUsers/' + uid);
        return set(userRef, null);
    }
    //#endregion
    //#region Account Management / Users
    signInAnon() {
        let auth = getAuth();
        return signInAnonymously(auth).then((user) => { console.log("signed in anon " + JSON.stringify(user)); });
    }
    safeEmail() {
        let auth = getAuth();
        if (!auth || !auth.currentUser || !auth.currentUser.email)
            return "";
        return auth.currentUser.email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    }
    //Sign in a user 
    async  SignInUser(email, password, isRemembered = false) {
    const signindata = { email: email, password: password };
    const db = getDatabase();

    let valid = signindata.email && signindata.password &&
        validator.isEmail(signindata.email) && !validator.isEmpty(signindata.password, { ignore_whitespace: true });

    if (!valid) {
        throw { code: "SignInDataNotValid", message: "you did not provide a valid email or password", signindata: signindata };
    }

    let auth = getAuth();
    let fbState;
    if (isRemembered) {
        fbState = browserLocalPersistence;
    }
    else {
        fbState = browserSessionPersistence;
    }

    await setPersistence(auth, fbState);

    console.log("signindata.email: " + signindata.email);
    const userCredential = await signInWithEmailAndPassword(auth, signindata.email, signindata.password);

    console.log("checking role");
    let R = ref(db, 'adminRoles/' + userCredential.user.uid);
    const snapshot = await get(R);

    if (snapshot.exists()) {
        let role = snapshot.val();
        if (role !== "master") {
            // logout user
            await signOut(auth);
            throw { code: "RoleNotMaster", message: "This user is not a master user" };
        }
    }
    else {
        // logout user
        await signOut(auth);
        throw { code: "RoleNotMaster", message: "This user is not a master user" };
    }

    if (!userCredential.user.emailVerified) {
        await signOut(auth);
        throw { code: "EmailNotVerifiedYet", message: "you have not verified your email yet" };
    }
    else {
        console.log("email valid:");
    }

    await this.createEmailAdminRelationshipIfNeeded();
    await this.createAdminRoleIfNeeded();
}
//
//Signup a new user
//
ValidateSignUpData(email, password, confirmPassword, firstname, lastname, username) {
    let result = { emailValid: false, emailAuthorized: false, passwordValid: false, confirmPasswordValid: false, firstNameValid: false, lastNameValid: false, usernameValid: false, usernameMsg: "" };
    //fist check if email is in authorizedEmails list
    let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    let emREf = ref(getDatabase(), 'authorizedEmails/' + safeEmail);
    //check if exists return bool
    return new Promise((resolve, reject) => {
        get(emREf).then((snapshot) => {
            let authorized = snapshot.exists();
            if (authorized) {
                result.emailAuthorized = true;
            }
            else {
                result.emailAuthorized = false;
            }
            result.emailValid = validator.isEmail(email);
            result.passwordValid = validator.isStrongPassword(password);
            result.confirmPasswordValid = validator.isStrongPassword(confirmPassword) && confirmPassword === password;
            result.firstNameValid = firstname.length > 0 && validator.isAlpha(firstname.replace(/\s+/g, ''));
            result.lastNameValid = lastname.length > 0 && validator.isAlpha(lastname.replace(/\s+/g, ''));
            return result;
        })
            .then((result) => {
                this.validateUsername(username, (suc, msg) => {
                    result.usernameValid = suc;
                    result.usernameMsg = msg;
                    resolve(result);
                });
            });
    });
}
SignUpUser(email, password, confirmPassword, firstname, lastname, username) {

    const signupdata = { email: email, password: password, confirmPassword: confirmPassword };

    //create new promise and test sign up data is valid
    return new Promise((resolve, reject) => {

        //fist check if email is in authorizedEmails list
        let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
        let emREf = ref(getDatabase(), 'authorizedEmails/' + safeEmail);
        //check if exists return bool

        let valid = signupdata.email && signupdata.password && signupdata.confirmPassword &&
            signupdata.confirmPassword === signupdata.password &&
            validator.isEmail(signupdata.email) && validator.isStrongPassword(signupdata.password);

        if (valid)
            resolve(signupdata);
        else {
            reject({
                code: "SignUpDataNotValid", message: `you did not provide a valid email or password (you must use a 
                            password with at least 9 charecters, with uppercase lowercase numbers and a symbol )`, signupdata: signupdata
            });
        }

    })
        //run create new user
        .then((signupdata) => {
            let auth = getAuth();
            return createUserWithEmailAndPassword(auth, signupdata.email, signupdata.password);
        })
        //update profile
        .then(() => {
            let auth = getAuth();
            return updateProfile(auth.currentUser, { displayName: username });
        })
        //create user data
        .then(() => {

            return this.CreateAdminData(firstname, lastname, username);
        })
        .then(() => {
            return this.createEmailAdminRelationshipIfNeeded();
        })

        .then(() => {
            return this.createAdminRoleIfNeeded();
        })
        //send the email verification and set variables
        .then(() => {
            let auth = getAuth();

            //send email and then return data with user and token 
            return sendEmailVerification(auth.currentUser);
        })

        ////force signout
        .then((data) => {
            let auth = getAuth();
            return signOut(auth);
        })

        .catch((error) => {

            //rethrow error to pass on to the next catch statment if needed
            throw error;
        });
}
//
//Resend email verification
//

ResendEmailVerification(email, password) {

    const signindata = { email: email, password: password };

    //create new promise and test sign up data is valid
    return new Promise((resolve, reject) => {

        let valid = signindata.email && signindata.password &&
            validator.isEmail(signindata.email) && !validator.isEmpty(signindata.password, { ignore_whitespace: true });

        if (valid)
            resolve(signindata);
        else
            reject({ code: "SignInDataNotValid", message: "you did not provide a valid email or password", signindata: signindata });

    })

        // .then((signindata) => {
        //     if (!this.authed === MainService.AUTHED) {
        //         return signindata;
        //     }
        //     else {
        //         throw { code: "EmailAlreadyVerified", message: "This email has already been verified" };
        //     }
        // })

        //run sign in
        .then((signindata) => {
            let auth = getAuth();
            return signInWithEmailAndPassword(auth, signindata.email, signindata.password);
        })

        //check if email verified and signout if not verified
        .then((userCredential) => {
            if (!userCredential.user.emailVerified) {
                let auth = getAuth();
                return sendEmailVerification(auth.currentUser)
            }
            else {
                throw { code: "EmailAlreadyVerified", message: "This email has already been verified" };
            }
        })

        .then(() => {
            let auth = getAuth();
            auth.signOut();
        })

        .catch((error) => {

            //rethrow error to pass on to the next catch statment if needed
            throw error;
        });
}
//
//Email Password Reset
//

SendPasswordResetEmail(email) {

    //create new promise and validate email
    return new Promise((resolve, reject) => {

        const valid = validator.isEmail(email);
        if (valid)
            resolve(email);
        else
            reject({ code: "EmailNotValid", message: "you did not provide a valid email", email: email });

    })

        //send email validation
        .then((email) => {
            var auth = getAuth();
            return sendPasswordResetEmail(auth, email);
        })

        //catch errors and then pass on
        .catch((error) => {


            throw error;
        });
}

//
//Sign Out
//

SignOut() {

    //Signout firebase function
    let auth = getAuth();
    return signOut(auth)

        .then(() => {
            // Sign-out successful.
            return true;
        })

        //
        .catch((error) => {
            throw error;
        });

}

//
//Create User Data in database 
//

CreateAdminData(firstname, lastname, username) {
    let auth = getAuth();
    const db = getDatabase();
    let userRef = ref(db, 'admins/' + auth.currentUser.uid)

    return set(userRef, {
        firstname: firstname,
        lastname: lastname,
        email: auth.currentUser.email,
    }).then(() => {
        this.setUserUsername(username, undefined, (suc) => {

        }
        );
    });
}
removeUserData(uid) {
    const db = getDatabase();
    let userRef = ref(db, 'admins/' + uid);
    return set(userRef, null);
}

createEmailAdminRelationshipIfNeeded() {
    let auth = getAuth();
    const db = getDatabase();
    let email = auth.currentUser.email;
    let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    let earRef = ref(db, 'emailAdminRelationships/' + safeEmail);
    return get(earRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
                return true;
            }
            else {
                return set(earRef, auth.currentUser.uid);
            }
        });
}
removeEmailAdminRelationship(email) {
    let auth = getAuth();
    const db = getDatabase();
    let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    let earRef = ref(db, 'emailAdminRelationships/' + safeEmail);
    return remove(earRef);
}

createAdminRoleIfNeeded() {
    let auth = getAuth();
    const db = getDatabase();
    let R = ref(db, 'adminRoles/' + auth.currentUser.uid);
    return get(R)
        .then((snapshot) => {
            if (snapshot.exists()) {
                return true;
            }
            else {
                return set(R, "collab");
            }
        });
}
removeAdminRole(uid) {
    let auth = getAuth();
    const db = getDatabase();
    let R = ref(db, 'adminRoles/' + uid);
    return remove(R);
}
deleteCurrentUserAccount() {
    const auth = getAuth();
    const db = getDatabase();
    let uid = auth.currentUser.uid;
    const email = auth.currentUser.email;

    //delete user
    return deleteUser(auth.currentUser)
        .then(() => {
            //call cloud function to delete user data
            const functions = getFunctions(undefined, cloudFunctionsRegion);
            const deleteUserData = httpsCallable(functions, 'deleteUserData');
            console.log("calling deleteUserData");
            return deleteUserData(uid);
        })
        .catch((error) => {
            //rethrow error to pass on to the next catch statment if needed
            throw error;
        });;

}
validateUsername(username, callback) {

    // Define the criteria for a valid username
    const minLength = 3; // Minimum length of username
    const maxLength = 15; // Maximum length of username
    const regex = /^[a-zA-Z0-9_.-]+$/; // Allow letters, numbers, underscores, dashes and dots

    // Check the length of the username
    if (username.length < minLength || username.length > maxLength) {
        callback(false, "username must be between " + minLength + " and " + maxLength + " characters");
        return;
    }
    if (!regex.test(username)) {
        callback(false, "username must only contain letters, numbers, underscores, dashes and dots");
        // Check if the username matches the regex pattern
        return;
    }

    //make query to see if username exists
    const db = getDatabase();
    const R = ref(db, "usernameAdminRelationships/" + this.encodeUsername(username));
    //check if exists return bool
    return get(R)
        .then((snapshot) => {
            let suc = !snapshot.exists();
            let msg = suc ? "" : "username already exists";
            callback(suc, msg);
        });
}
setUserData(key, data, callback) {
    let auth = getAuth();
    const db = getDatabase();
    let userRef = ref(db, 'admins/' + auth.currentUser.uid);
    let updateObj = {};

    updateObj[key] = data;
    return update(userRef, updateObj)
        .then(() => {
            if (callback)
                callback(true);
        })
        .catch(() => {
            if (callback)
                callback(false);
        });

}
setUserUsername(username, oldUsername, callback) {
    let auth = getAuth();
    const db = getDatabase();
    //add username with key uid to adminUsernameRelationships
    let userRef = ref(db, 'usernameAdminRelationships/' + this.encodeUsername(username));
    let un = this.encodeUsername(username);
    let oun = oldUsername && oldUsername !== "" ? this.encodeUsername(oldUsername) : undefined;
    return set(userRef, auth.currentUser.uid)
        .then(() => {
            //remove old username if exists
            if (oun) {
                let oldUserRef = ref(db, 'usernameAdminRelationships/' + oun);
                return set(oldUserRef, null);
            }
        })
        .then(() => {
            // create relationshop
            let userRef = ref(db, 'usernameAdminRelationships/' + un);
            return set(userRef, auth.currentUser.uid);
        })
        .then(() => {
            this.setUserData("username", un, callback);
        })
        .then(() => {
            if (callback)
                callback(true);
        })
        .catch(() => {
            if (callback)
                callback(false);
        });
}
encodeUsername(username) {
    //replace dots with %2E
    return username.trim().replace(new RegExp("\\.", "g"), "%2E");
}
decodeUsername(username) {
    return username.trim().replace(new RegExp("%2E", "g"), ".");
}
// getThisUserProfileImageThumb(){
//     let auth = getAuth();
//     console.log("getThisUserProfileImageThumb");
//     return this.getImageThumbnailUrl("userdata","profile","50x50",auth.currentUser.uid,);
// }
getThisUserProfileImageThumb(callback) {
    let auth = getAuth();
    let url = this.getImageThumbnailUrl("userdata", "profile", "50x50", auth.currentUser.uid,);
    this.imageExists(url, (exists) => {
        if (exists)
            callback(url);
        else
            callback("");
    });

}

getUserMetadata(uid) {
    const db = getDatabase();
    const R = ref(db, "admins/" + uid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
//! no permissions so remove
getAdminUidFromEmail(email) {
    let safeEmail = email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    const db = getDatabase();
    console.log("safeEmail: " + safeEmail);
    const R = ref(db, "emailAdminRelationships/" + safeEmail);
    return get(R)
        .then((snapshot) => {
            console.log("snapshot: " + JSON.stringify(snapshot));
            return snapshot.val();
        });
}









//#endregion
//#region General Functions
//
//General listner functionality
//

addDataListener(path, callback, returnData) {
    //Create Map for the path if it doesnt already exist 
    if (!this.dataListnerCallbacks.has(path)) {
        this.dataListnerCallbacks.set(path, new Map());
    }
    let callbackMap = this.dataListnerCallbacks.get(path);

    //if the callback is already registered, dont register it again 
    if (callbackMap.has(callback)) {
        console.log("addDataListener failed, " + path + " callback already registered");
        return;
    }


    //create the wraper func for callback 
    let callbackWrap = (snapshot) => {
        const val = snapshot.val();//(val returns null when no data there)
        callback(val, returnData);//fire callback 
    }

    //add to the path 
    callbackMap.set(callback, callbackWrap);

    //set up firebase listner
    const db = getDatabase();
    const R = ref(db, path);

    onValue(R, callbackWrap);
}

removeDataListener(path, callback) {
    //if the path does not exist return
    if (!this.dataListnerCallbacks.has(path)) {
        console.log("removeDataListener failed, " + path + " no such path")
        return;
    }

    //if no callbak at path return
    let callbackMap = this.dataListnerCallbacks.get(path);
    if (!callbackMap.has(callback)) {
        console.log("removeDataListener failed, " + path + " no callback")
        return;
    }

    //get the wraper func 
    let callbackWrap = callbackMap.get(callback);

    //remove from the map and remove whole map at the path if empty
    callbackMap.delete(callback);
    if (callbackMap.size === 0)
        this.dataListnerCallbacks.delete(path);


    //remove listner from firebase
    const db = getDatabase();
    const R = ref(db, path);

    off(R, "value", callbackWrap);
}

//#endregion
//#region Credits

addCredits(dbRoot, id, index, role, name) {
    //check if any arg is undefined
    if (dbRoot === undefined || id === undefined || index === undefined || role === undefined || name === undefined) {
        console.error("addCredits failed, one or more args undefined");
        return;
    }
    var entry = {
        roles: { 'English': role },
        name: name,
    }
    let refStr = dbRoot + "/" + id + "/creditsList/" + index;


    console.log("refStr: " + refStr);

    return set(ref(getDatabase(), refStr), entry);

}
removeCredits(dbRoot, id, index) {
    if (dbRoot === undefined || id === undefined || index === undefined) {
        console.error("removeCredits failed, one or more args undefined");
        return;
    }
    let refStr = dbRoot + "/" + id + "/creditsList/" + index;

    return set(ref(getDatabase(), refStr), null);
}
setCreditsProperty(dbRoot, id, index, key, val) {
    if (dbRoot === undefined || id === undefined || index === undefined || key === undefined || val === undefined) {
        console.error("setCreditsProperty failed, one or more args undefined");
        return;
    }
    let refStr = dbRoot + "/" + id + "/creditsList/" + index + "/" + key;
    // if (type === "exhibition")
    //     refStr = "exhibitionInfo/creditsList/" + index + "/" + key;
    // else if (type === "artwork")
    //     refStr = "artworkMetadatas/" + id + "/creditsList/" + index + "/" + key;

    return set(ref(getDatabase(), refStr), val);
}
moveCredits(dbRoot, id, index, newIndex) {
    if (dbRoot === undefined || id === undefined || index === undefined || newIndex === undefined) {
        console.error("moveCredits failed, one or more args undefined");
        return;
    }

    let refStr = dbRoot + "/" + id + "/creditsList";


    return get(ref(getDatabase(), refStr))
        .then((snapshot) => {
            let credits = snapshot.val();
            if (credits) {
                let temp = credits[index];
                credits[index] = credits[newIndex];
                credits[newIndex] = temp;
                return set(ref(getDatabase(), refStr), credits);
            }
        });
}
//#endregion
//#region Exhibition
setExhibitionTitle(eid, language, title) {
    return set(ref(getDatabase(), "exhibitionMetadatas/" + eid + "/titles/" + language), title);
}
setExhibitionDescription(language, description) {
    return set(ref(getDatabase(), "exhibitionInfo/descriptions/" + language), description);
}
setExhibitionCredits(credits) {
    return set(ref(getDatabase(), "exhibitionInfo/credits"), credits);
}
setExhibitionWebsite(website) {
    return set(ref(getDatabase(), "exhibitionInfo/website"), website);
}
setExhibitionTimezone(timezone) {
    return set(ref(getDatabase(), "exhibitionInfo/timezone"), timezone);
}
setAppVersion(version) {
    return set(ref(getDatabase(), "appConfig/appVersion"), version);
}
setIOSAppStoreURL(url) {
    return set(ref(getDatabase(), "appConfig/iOSAppStoreURL"), url);
}
setAndroidAppStoreURL(url) {
    return set(ref(getDatabase(), "appConfig/androidAppStoreURL"), url);
}
setExhibitionArea(eid, data) {
    return set(ref(getDatabase(), "exhibitionMetadatas/" + eid + "/area"), data);
}
setExhibtionLiveInApp(eid, liveInApp) {
    return set(ref(getDatabase(), "exhibitionMetadatas/" + eid + "/liveInApp"), liveInApp)
        .then(() => {
            let toggle = liveInApp ? true : null;
            return set(ref(getDatabase(), "liveExhibitions/" + eid), toggle);
        })
}
//#endregion    
//#region Organisations
createNewOrganisation() {
    let auth = getAuth();

    let safeEmail = auth.currentUser.email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    let organisationMetadata = {
        name: 'Example Organisation',
        descriptions: { 'English': 'This is a default description' },
        address: 'Stockholm, Sweden',
        website: 'www.example.com',
        administrativeArea: 3,
        instancesNum: 1
    };

    let admins = {};
    admins[safeEmail] = 'owner';

    const db = getDatabase();
    const newOrganisationMetadatasRef = push(ref(db, "organisationMetadatas"));
    const newOrganisationId = newOrganisationMetadatasRef.key;

    return set(ref(db, "organisationAdmins/" + newOrganisationId), admins) //must write to collaborators first for permissions to write to other stuff 
        .then(() => {
            return set(newOrganisationMetadatasRef, organisationMetadata).then(() => {
                this.setOrganisationAddress(newOrganisationId, organisationMetadata.address);
            });
        })
        .then(() => {
            return set(ref(db, "adminOrganisationRelationships/" + safeEmail + "/" + newOrganisationId), true);
        })

}
getOrganisationMetadata(organisationid) {
    const db = getDatabase();
    const R = ref(db, "organisationMetadatas/" + organisationid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
getDistributors(organisationid) {
    const db = getDatabase();
    const R = ref(db, "organisationAdmins/" + organisationid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
deleteOrganisation(organisationid) {
    const db = getDatabase();

    return this.getDistributors(organisationid)
        .then((organisationAdmins) => {

            let promiseList = [];

            //destroy any live object
            promiseList.push(set(ref(db, "liveOrganisations/" + organisationid), null));

            //loop over all collaborator and remove their relation to this artwork
            if (organisationAdmins) {
                for (const dist in organisationAdmins) {
                    promiseList.push(set(ref(db, "adminOrganisationRelationships/" + dist + "/" + organisationid), null));
                }
            }

            //destroy artwork metadata object
            promiseList.push(set(ref(db, "organisationMetadatas/" + organisationid), null));


            return Promise.all(promiseList);
        })
        .then(() => {
            //then get all the organisation loactions
            console.log("getting organisation locations")
            return get(ref(db, "organisationLocations/" + organisationid))
        })
        .then((organisationLocations) => {
            console.log("deleting organisation location relationships")
            //then itterate through and delete all locations relationships with the organisation id
            let promiseList = [];
            let locs = organisationLocations.val();
            if (locs) {
                for (const locationid in locs) {
                    console.log("locationid " + locationid);
                    let loc = locs[locationid];
                    if (loc && loc.locHash)
                        promiseList.push(set(ref(db, "locationOrganisationRelationships/" + loc.locHash + "/" + organisationid), null));
                }
            }
            return Promise.all(promiseList);
        })
        .then(() => {
            console.log("deleting organisation locations")
            //then destroy organisation locations
            return set(ref(db, "organisationLocations/" + organisationid), null);
        })
        .then(() => {
            console.log("getting artwork location relationships")
            //get all artwork locations placed by organisation
            return get(ref(db, "organisationArtworkLocationRelationships/" + organisationid))
        })
        .then((artworkLocations) => {
            console.log("deleting artwork location relationships")
            //destroy all artwork locations placed by organisation
            let promiseList = [];
            let artworks = artworkLocations.val();
            if (artworks) {
                for (const artworkid in artworks)
                    for (const locationid in artworks[artworkid]) {
                        let loc = artworks[locationid];
                        if (loc && loc.locHash)
                            promiseList.push(set(ref(db, "locationArtworkRelationships/" + loc.locHash + "/" + artworkid + "/" + locationid), null));
                    }
            }
            return Promise.all(promiseList);
        })
        .then(() => {
            //destroy organisation artwork location relationship
            return set(ref(db, "organisationArtworkLocationRelationships/" + organisationid), null);
        })
        .then(() => {
            //delete curators object 
            return set(ref(db, "organisationAdmins/" + organisationid), null);
        })
}
setOrganisationLiveInApp(organisationid, liveInApp) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/liveInApp"), liveInApp)
        .then(() => {
            let toggle = liveInApp ? true : null;
            return set(ref(getDatabase(), "liveOrganisations/" + organisationid), toggle);
        })
}
setOrganisationName(organisationid, name) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/name"), name);
}
setOrganisationAddress(organisationid, address, callback) {
    Geo.GetLocationFromAddress(address).then((resp) => {
        if (resp.results && resp.results.length > 0 && resp.results[0].geometry && resp.results[0].geometry.location) {

            const { lat, lng } = resp.results[0].geometry.location;
            this.setOrganisationLocation(organisationid, lat, lng);
            console.log("organisation location set to: " + lat + ", " + lng);
            return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/address"), address).then(() => {
                if (callback)
                    callback(true);
            });
        }
        else {
            console.error("failed to get location from address");
            if (callback)
                callback(false);
        }
    },
        (error) => {
            console.error(error);
            if (callback)
                callback(false);
        }
    );
}
    async getLocationFromSearch(search) {
    return Geo.GetLocationFromAddress(search).then((resp) => {
        const { lat, lng } = resp.results[0].geometry.location;
        let location = { lat, lng };
        return location;
    });
}
addOrganisationCurator(organisationid, curatorEmail) {
    const db = getDatabase();
    let safeEmail = curatorEmail.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    return set(ref(db, "organisationAdmins/" + organisationid + "/" + safeEmail), "editor")
        .then(() => {
            return set(ref(db, "adminOrganisationRelationships/" + safeEmail + "/" + organisationid), true);
        });
}
setOrganisationLocation(organisationid, lat, lng) {
    //Calculate the location hash, its the integer nautical distance traveled north and THEN the distance traveled east to get the the corner of the square of map. 
    //this needs some thinking about
    let cellSize = 1; //size in nautical miles
    let locHash = "LOCHASH_" + Math.floor(lat * 60 / cellSize) + "_" + Math.floor(lng * Math.cos(Math.PI / 180 * lat) * 60 / cellSize);

    //push the location object to the artworkLocations
    let locationObj = {
        lat: lat,
        lng: lng,
        locHash: locHash,
    };

    const db = getDatabase();
    //first remove previous location
    return get(ref(db, "organisationLocations/" + organisationid))
        .then(() => {
            //then get all the organisation loactions
            console.log("getting organisation locations")
            return get(ref(db, "organisationLocations/" + organisationid))
        })
        .then((organisationLocations) => {
            console.log("deleting organisation location relationships")
            //then itterate through and delete all locations relationships with the organisation id
            let promiseList = [];
            let locs = organisationLocations.val();
            if (locs) {
                for (const locationid in locs) {
                    console.log("locationid " + locationid);
                    let loc = locs[locationid];
                    if (loc && loc.locHash)
                        promiseList.push(set(ref(db, "locationOrganisationRelationships/" + loc.locHash + "/" + organisationid), null));
                }
            }
            return Promise.all(promiseList);
        })
        .then(() => {
            // remove organisation locations
            return set(ref(db, "organisationLocations/" + organisationid), null)
        })
        .then(() => {
            const organisationLocationsRef = ref(db, "organisationLocations/" + organisationid);
            const alRef = push(organisationLocationsRef);
            return set(alRef, locationObj)
                .then(() => {
                    //set the location organisation relationship (there is organisationid at this spatal coord hash )
                    return set(ref(db, "locationOrganisationRelationships/" + locHash + "/" + organisationid + "/" + alRef.key), locationObj);
                })
        });

}
setOrganisationDescription(organisationid, selectedLanguage, description) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/descriptions/" + selectedLanguage), description);
}
setOrganisationWebsite(organisationid, website) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/website"), website);
}
setOrganisationAdminArea(organisationid, area) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/administrativeArea"), area);
}
setOrganisationArtworkInstancesNum(organisationid, num) {
    return set(ref(getDatabase(), "organisationMetadatas/" + organisationid + "/instancesNum"), num);
}
getOrganisationMetadataProperty(organisationid, property) {
    const db = getDatabase();
    const R = ref(db, "organisationMetadatas/" + organisationid + "/" + property);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
getOrganisationProfileImageUrl(organisationid) {
    //get key from db
    const db = getDatabase();
    const R = ref(db, "organisationMetadatas/" + organisationid + "/profileImage");
    return get(R).then((key) => {
        //get url from storage
        return this.getProfileImage(organisationid, key.val());
    })
}
//#endregion
//#region Exhibitions
createNewExhibition() {
    let auth = getAuth();

    let safeEmail = auth.currentUser.email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    let exhibitionMetadata = {
        titles: { 'en': 'Example Exhibition' },
        descriptions: { 'en': 'This is a default description' },
        address: 'London, UK',
        website: 'www.example.com',
        timezone: 'Europe/London'
    };

    let admins = {};
    admins[safeEmail] = 'owner';

    const db = getDatabase();
    const newExhibitionMetadatasRef = push(ref(db, "exhibitionMetadatas"));
    const newExhibitionId = newExhibitionMetadatasRef.key;

    return set(ref(db, "exhibitionAdmins/" + newExhibitionId), admins) //must write to collaborators first for permissions to write to other stuff 
        .then(() => {
            return set(newExhibitionMetadatasRef, exhibitionMetadata).then(() => {
                this.setExhibitionAddress(newExhibitionId, exhibitionMetadata.address);
            });
        })
        .then(() => {
            return set(ref(db, "adminExhibitionRelationships/" + safeEmail + "/" + newExhibitionId), true);
        })

}
setExhibitionAddress(exhibitionid, address, callback) {
    Geo.GetLocationFromAddress(address).then((resp) => {
        if (resp.results && resp.results.length > 0 && resp.results[0].geometry && resp.results[0].geometry.location) {

            const { lat, lng } = resp.results[0].geometry.location;
            this.setExhibitionLocation(exhibitionid, lat, lng);
            console.log("exhibition location set to: " + lat + ", " + lng);
            return set(ref(getDatabase(), "exhibitionMetadatas/" + exhibitionid + "/address"), address).then(() => {
                if (callback)
                    callback(true);
            });
        }
        else {
            console.error("failed to get location from address");
            if (callback)
                callback(false);
        }
    },
        (error) => {
            console.error(error);
            if (callback)
                callback(false);
        }
    );
}
setExhibitionLocation(exhibitionId, lat, lng) {
    //Calculate the location hash, its the integer nautical distance traveled north and THEN the distance traveled east to get the the corner of the square of map. 
    //this needs some thinking about
    let cellSize = 1; //size in nautical miles
    let locHash = "LOCHASH_" + Math.floor(lat * 60 / cellSize) + "_" + Math.floor(lng * Math.cos(Math.PI / 180 * lat) * 60 / cellSize);

    //push the location object to the exhibitionLocations
    let locationObj = {
        lat: lat,
        lng: lng,
        locHash: locHash,
    };

    const db = getDatabase();
    //first remove previous location
    return this.deleteExhibitionLocations(exhibitionId)
        .then(() => {
            const exhibitionLocationsRef = ref(db, "exhibitionLocations/" + exhibitionId);
            const alRef = push(exhibitionLocationsRef);
            return set(alRef, locationObj)
                .then(() => {
                    //set the location exhibition relationship (there is exhibitionId at this spatal coord hash )
                    return set(ref(db, "locationExhibitionRelationships/" + locHash + "/" + exhibitionId + "/" + alRef.key), locationObj);
                }).then(() => {
                    //add geofire location
                    this.geoFireExhibitions.set(exhibitionId, [lat, lng]).then(function () {
                        console.log("Provided key has been added to GeoFireExhibition");
                    }, function (error) {
                        console.log("Error: " + error);
                    });

                });
        });

}
addArtworkToExhibition(exhibitionId, artworkId) {
    console.log("addArtworkToExhibition");
    const db = getDatabase();
    //add artwork id to list
    return set(ref(db, "exhibitionMetadatas/" + exhibitionId + "/artworks/" + artworkId), true)

}
getExhibitionMetadata(exhibitionid) {
    const db = getDatabase();
    const R = ref(db, "exhibitionMetadatas/" + exhibitionid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
removeArtworkFromExhibition(exhibitionId, artworkId) {
    const db = getDatabase();
    //add artwork id to list
    return set(ref(db, "exhibitionMetadatas/" + exhibitionId + "/artworks/" + artworkId), null)
}
setExhibitionMetadataProperty(exhibitionId, key, val) {
    return set(ref(getDatabase(), "exhibitionMetadatas/" + exhibitionId + "/" + key), val);
}
deleteExhibition(eid) {
    const db = getDatabase();

    return this.getExhibitionAdmins(eid)
        .then((exhibitionAdmins) => {

            let promiseList = [];

            //destroy any live object
            promiseList.push(set(ref(db, "liveExhibitions/" + eid), null));

            //loop over all collaborator and remove their relation to this artwork
            if (exhibitionAdmins) {
                for (const dist in exhibitionAdmins) {
                    promiseList.push(set(ref(db, "adminExhibitionRelationships/" + dist + "/" + eid), null));
                }
            }

            //destroy exhibition metadata object
            promiseList.push(set(ref(db, "exhibitionMetadatas/" + eid), null));

            return Promise.all(promiseList);
        })
        .then(() => {
            return this.deleteExhibitionLocations(eid);
        })
        .then(() => {
            console.log("getting exhibition location relationships")
            //get all exhibition locations placed by exhibition
            return get(ref(db, "exhibitionArtworkLocationRelationships/" + eid))
        })
        .then((artworkLocations) => {
            console.log("deleting exhibition location relationships")
            //destroy all exhibition locations placed by exhibition
            let promiseList = [];
            let artworks = artworkLocations.val();
            if (artworks) {
                for (const artworkid in artworks)
                    for (const locationid in artworks[artworkid]) {
                        let loc = artworks[locationid];
                        if (loc && loc.locHash)
                            promiseList.push(set(ref(db, "locationArtworkRelationships/" + loc.locHash + "/" + artworkid + "/" + locationid), null));
                    }
            }
            return Promise.all(promiseList);
        })
        .then(() => {
            //destroy exhibition exhibition location relationship
            return set(ref(db, "exhibitionArtworkLocationRelationships/" + eid), null);
        })
        .then(() => {
            //delete admins
            return set(ref(db, "exhibitionAdmins/" + eid), null);
        })
}
deleteExhibitionLocations(eid) {
    const db = getDatabase();

    //then get all the exhibition loactions
    console.log("getting exhibition locations")
    return get(ref(db, "exhibitionLocations/" + eid))
        .then((exhibitionLocations) => {
            console.log("deleting exhibition location relationships")
            //then itterate through and delete all locations relationships with the exhibition id
            let promiseList = [];
            let locs = exhibitionLocations.val();
            if (locs) {
                for (const locationid in locs) {
                    console.log("locationid " + locationid);
                    let loc = locs[locationid];
                    if (loc && loc.locHash) {
                        promiseList.push(set(ref(db, "locationExhibitionRelationships/" + loc.locHash + "/" + eid), null));
                    }
                }
            }
            return Promise.all(promiseList);
        })
        .then(() => {
            console.log("deleting exhibition location")
            //then destroy exhibition locations
            return set(ref(db, "exhibitionLocations/" + eid), null);
        })
        .then(() => {
            return this.geoFireExhibitions.remove(eid);
        });


}
getExhibitionAdmins(exhibitionid) {
    const db = getDatabase();
    const R = ref(db, "exhibitionAdmins/" + exhibitionid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
addExhibitionAdmin(exhibitionid, adminEmail) {
    const db = getDatabase();
    let safeEmail = adminEmail.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    return set(ref(db, "exhibitionAdmins/" + exhibitionid + "/" + safeEmail), "editor")
        .then(() => {
            return set(ref(db, "adminExhibitionRelationships/" + safeEmail + "/" + exhibitionid), true);
        });
}
deleteExhibitionAdmin(exhibitionid, adminEmail) {
    const db = getDatabase();
    let safeEmail = adminEmail.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    return set(ref(db, "exhibitionAdmins/" + exhibitionid + "/" + safeEmail), null)
        .then(() => {
            return set(ref(db, "adminExhibitionRelationships/" + safeEmail + "/" + exhibitionid), null);
        });
}



//#endregion
//#region Artworks
createNewArtwork() {
    let auth = getAuth();

    let safeEmail = auth.currentUser.email.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    var titles = { 'English': 'Default title' };
    var descriptions = { 'English': 'Default long description' };
    var shortDescriptions = { 'English': 'Short description should be 300 characters or less' };
    let artworkMetadata = {
        titles: titles,
        owner: auth.currentUser.uid,
        ownerName: auth.currentUser.displayName,
        shortDescriptions: descriptions,
        longDescriptions: shortDescriptions,
        perceivableDistance: 40,
        liveInApp: false,
        isTest: false,
        placementType: 'InFrontOfCamera',
        availableEverywhere: false,
        platforms: { 'iOS': true, 'Android': true, 'OVR': false, 'VisionOS': false }
    };

    let artworkCollaborators = {};
    artworkCollaborators[safeEmail] = 'owner';

    const db = getDatabase();
    const newArtworkMetadatasRef = push(ref(db, "artworkMetadatas"));
    const newartworkid = newArtworkMetadatasRef.key;

    return set(ref(db, "artworkCollaborators/" + newartworkid), artworkCollaborators) //must write to collaborators first for permissions to write to other stuff 
        .then(() => {
            return set(newArtworkMetadatasRef, artworkMetadata);
        })
        .then(() => {
            return set(ref(db, "adminArtworkRelationships/" + safeEmail + "/" + newartworkid), true);
        })
        .then(() => {
            //add experience to user
            let uid = auth.currentUser.uid;
            //experiences are comma seperated list of artwork ids
            return get(ref(db, "admins/" + uid + "/experiences")).then((snapshot) => {
                let experiences = snapshot.val();
                let newExperiences = experiences ? experiences + "," + newartworkid : newartworkid;
                return set(ref(db, "admins/" + uid + "/experiences"), newExperiences);
            });
            // return set(ref(db, "admins/" + uid +"/experiences/"+newartworkid), "owner");
        }).then(() => {
            let uid = auth.currentUser.uid;
            //add to unpublished artworks
            return get(ref(db, "admins/" + uid + "/unpublishedExperiences")).then((snapshot) => {
                let experiences = snapshot.val();
                let newExperiences = experiences ? experiences + "," + newartworkid : newartworkid;
                return set(ref(db, "admins/" + uid + "/unpublishedExperiences"), newExperiences);
            });
        });

}
getArtworkMetadata(artworkid) {
    const db = getDatabase();
    const R = ref(db, "artworkMetadatas/" + artworkid);
    return get(R)
        .then((snapshot) => {
            if (snapshot.exists())
                return snapshot.val();
            else {
                console.error("artwork metadata does not exist for id: " + artworkid);
                return null;
            }
        });
}
getArtworkLocationDataRules(artworkid) {
    const db = getDatabase();
    const R = ref(db, "locationDataRules/" + artworkid);
    // return if ref doesn't exist

    return get(R)
        .then((snapshot) => {
            if (snapshot.exists())
                return snapshot.val();
            else
                return null;
        });
}
getArtworkCollaborators(artworkid) {
    const db = getDatabase();
    const R = ref(db, "artworkCollaborators/" + artworkid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
setLiveArtwork(artworkid, live) {
    return set(ref(getDatabase(), "liveArtworks/" + artworkid), live === true ? true : null);
}
deleteArtwork(artworkid) {
    const db = getDatabase();

    //get all artwork collaborators
    return this.getArtworkCollaborators(artworkid)
        //then delete all artwork collaborator relationships and the metadata
        .then((artworkCollaborators) => {

            let promiseList = [];

            //destroy any live object
            promiseList.push(set(ref(db, "liveArtworks/" + artworkid), null));

            //loop over all collaborator and remove their relation to this artwork
            if (artworkCollaborators) {
                for (const collab in artworkCollaborators) {
                    promiseList.push(set(ref(db, "adminArtworkRelationships/" + collab + "/" + artworkid), null));
                }
            }

            //destroy artwork metadata object
            promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid), null));



            return Promise.all(promiseList);
        })
        //delete all the artwork organisation relationships
        .then(() => {
            console.log("destroy any live object");
            console.log("admin artwork relationships destroyed");
            console.log("artwork metadata destroyed");

            return get(ref(db, "organisationArtworkLocationRelationships/")).then((snapshot) => {
                let orgs = snapshot.val();
                //delete from organisation artwork relationships
                for (const orgid in orgs) {
                    let org = orgs[orgid];
                    for (const artworkid in org) {
                        let artwork = org[artworkid];
                        for (const locid in artwork) {
                            return this.deleteExhibitionArtworkLocationRelationship(artworkid, orgid, locid);
                        }
                    }
                }
            });
        })
        //then get all the artwork loactions
        .then(() => {
            console.log("org artwork relationships destroyed");

            return get(ref(db, "artworkLocations/" + artworkid))
        })
        //then itterate through and delete all locations relationships with the artwork id
        .then((artworkLocations) => {
            let promiseList = [];
            let locs = artworkLocations.val();
            if (locs) {
                for (const locationid in locs) {
                    console.log("locationid " + locationid);
                    let loc = locs[locationid];
                    if (loc && loc.locHash) {
                        promiseList.push(set(ref(db, "locationArtworkRelationships/" + loc.locHash + "/" + artworkid), null));
                        //remove geofire location
                        promiseList.push(this.geoFireContents.remove(locationid));
                    }
                }
            }
            return Promise.all(promiseList);
        })
        //then destroy artwork locations
        .then(() => {
            console.log("artwork location relationships destroyed");
            return set(ref(db, "artworkLocations/" + artworkid), null);
        })
        //delete all the artwork related realtime data
        .then(() => {
            console.log("artwork collaborators destroyed");
            return this.deleteArtworkRealtimeData(artworkid);
        })
        //delete collaborators object 
        .then(() => {
            console.log("artwork locations destroyed");
            return set(ref(db, "artworkCollaborators/" + artworkid), null);
        })
        //remove reference in admin profile
        .then(() => {
            let auth = getAuth();
            //experiences are comma seperated list of artwork ids
            let uid = auth.currentUser.uid;
            return get(ref(db, "admins/" + uid + "/experiences")).then((snapshot) => {
                let experiences = snapshot.val();
                if (experiences) {
                    let newExperiences = experiences.replace("," + artworkid, "");
                    return set(ref(db, "admins/" + uid + "/experiences"), newExperiences);
                }
            });
        })
        //remove frlom unpublished artworks
        .then(() => {
            let auth = getAuth();
            //experiences are comma seperated list of artwork ids
            let uid = auth.currentUser.uid;
            return get(ref(db, "admins/" + uid + "/unpublishedExperiences")).then((snapshot) => {
                let experiences = snapshot.val();
                if (experiences) {
                    let newExperiences = experiences.replace("," + artworkid, "");
                    return set(ref(db, "admins/" + uid + "/unpublishedExperiences"), newExperiences);
                }
            });
        })
        .catch((error) => {
            console.error(error);
        });
}
deleteArtworkRealtimeData(artworkid) {
    const db = getDatabase();
    return set(ref(db, "realtimeData/" + artworkid), null)
        //delete cloud functions
        .then(() => {
            return set(ref(db, "artworkCloudFunctions/" + artworkid), null);
        })
        //delete locationPairs
        .then(() => {
            return set(ref(db, "locationPairs/" + artworkid), null);
        })
        //delete userGroups
        .then(() => {
            return set(ref(db, "userGroups/" + artworkid), null);
        })
        //delete realtimeDataRules
        .then(() => {
            return set(ref(db, "realtimeDataRules/" + artworkid), null);
        }
            //delete locationDataRules
        ).then(() => {
            return set(ref(db, "locationDataRules/" + artworkid), null);
        });


}
deleteOrganisationAdmin(organisationid, safeEmail) {
    const db = getDatabase();
    return set(ref(db, "organisationAdmins/" + organisationid + "/" + safeEmail), null)
        .then(() => {
            return set(ref(db, "adminOrganisationRelationships/" + safeEmail + "/" + organisationid), null);
        });
}
copyArtworkRealtimeDataToTest(artworkid, testArtworkid) {
    const db = getDatabase();
    return get(ref(db, "realtimeData/" + artworkid))
        .then((snapshot) => {
            let data = snapshot.val();
            return set(ref(db, "realtimeData/" + testArtworkid), data);
        });
}

//
//set artwork values functions
//
setArtworkMetadataProperty(artworkid, key, val) {
    return set(ref(getDatabase(), "artworkMetadatas/" + artworkid + "/" + key), val);
}
addArtworkLocation(artworkid, lat, lng, geocodeLocation, callback) {

    //Calculate the location hash, its the integer nautical distance traveled north and THEN the distance traveled east to get the the corner of the square of map. 
    //this needs some thinking about
    let cellSize = 1; //size in nautical miles
    let locHash = "LOCHASH_" + Math.floor(lat * 60 / cellSize) + "_" + Math.floor(lng * Math.cos(Math.PI / 180 * lat) * 60 / cellSize);
    return Geo.GetAltitudeFromLocation(lat, lng).then((alt) => {
        //push the location object to the artworkLocations
        let locationObj = {
            lat: lat,
            lng: lng,
            alt: alt,
            locHash: locHash,
        };

        console.log("setting lat " + lat + " lng " + lng + "alt" + alt + "locHash " + locHash + " artworkid " + artworkid);

        const db = getDatabase();
        const artworkLocationsRef = ref(db, "artworkLocations/" + artworkid);
        const alRef = push(artworkLocationsRef);

        return set(alRef, locationObj)
            .then(() => {
                if (geocodeLocation === true) {

                    Geo.GetAddressFromLocation(lat, lng).then((resp) => {
                        if (resp.results && resp.results.length > 0) {
                            console.log("got address from location " + JSON.stringify(resp.results[0].formatted_address));
                            console.log("address components " + JSON.stringify(resp.results[0].address_components));
                            let address = Geo.GetAddressFromGeocodingResult(resp);

                            console.log("geocoded address: " + JSON.stringify(address));
                            return set(ref(db, "artworkLocations/" + artworkid + "/" + alRef.key + "/address"), address).then(() => {
                                if (callback)
                                    callback(alRef.key);
                                //set the location artwork relationship (there is artwork artworkid at this spatal coord hash )
                                return set(ref(db, "locationArtworkRelationships/" + locHash + "/" + artworkid + "/" + alRef.key), locationObj);
                            });
                        }
                        else {
                            console.error("failed to get location from address");
                            if (callback)
                                callback(alRef.key);
                            //set the location artwork relationship (there is artwork artworkid at this spatal coord hash )
                            return set(ref(db, "locationArtworkRelationships/" + locHash + "/" + artworkid + "/" + alRef.key), locationObj);
                        }


                    });
                }
                else {
                    if (callback)
                        callback(alRef.key);
                    //set the location artwork relationship (there is artwork artworkid at this spatal coord hash )
                    return set(ref(db, "locationArtworkRelationships/" + locHash + "/" + artworkid + "/" + alRef.key), locationObj);
                }
            }).then(() => {
                this.geoFireContents.set(alRef.key, [lat, lng]).then(function () {
                    console.log("Provided key has been added to GeoFire");
                }, function (error) {
                    console.log("Error: " + error);
                });
            });
    });

}
computeAltitudeForAllLocations() {
    //get all artowrk locations
    return get(ref(getDatabase(), "artworkLocations")).then((artworks) => {
        let artworkList = artworks.val();
        let promiseList = [];
        let locs = [];
        let ids = [];
        for (const artworkid in artworkList) {
            let artwork = artworkList[artworkid];
            for (const locationid in artwork) {
                let location = artwork[locationid];
                ids.push([artworkid, locationid]);
                locs.push([location.lat, location.lng]);
            }
        }
        Geo.GetAltitudesFromLocations(locs).then((alts) => {
            console.log("alts " + JSON.stringify(alts));
            for (let i = 0; i < alts.length; i++) {
                const alt = alts[i];
                const id = ids[i];
                promiseList.push(set(ref(getDatabase(), "artworkLocations/" + id[0] + "/" + id[1] + "/alt"), alt));
            }
        });
        return Promise.all(promiseList);
    });
}
addArtworkLocationOrganisation(organisationid, artworkid, lat, lng, geocodeLocation) {
    const db = getDatabase();
    console.log("adding artwork location organisation " + organisationid + " " + artworkid + " " + lat + " " + lng)
    //make a promise for a new location
    return this.addArtworkLocation(artworkid, lat, lng, geocodeLocation, (locid) => {
        console.log("adding artwork location organisation " + organisationid + " " + artworkid + " " + lat + " " + lng + " " + locid + "");
        return this.addOrganisationArtworkLocationRelationship(organisationid, artworkid, locid).then(() => {
            console.log("adding organisation reference to artwork location orgid" + organisationid + " artid" + artworkid + " locid" + locid + "")
            return set(ref(getDatabase(), "artworkLocations/" + artworkid + "/" + locid + "/organisation"), organisationid);
        });
    });
}
addOrganisationArtworkLocationRelationship(organisationid, artworkid, locationid) {
    return set(ref(getDatabase(), "organisationArtworkLocationRelationships/" + organisationid + "/" + artworkid + "/" + locationid), true);
}
addArtworkLocationExhibition(exhibitionid, artworkid, lat, lng, geocodeLocation) {
    const db = getDatabase();
    console.log("adding artwork location exhibition " + exhibitionid + " " + artworkid + " " + lat + " " + lng)
    //make a promise for a new location
    return this.addArtworkLocation(artworkid, lat, lng, geocodeLocation, (locid) => {
        console.log("adding artwork location exhibition " + exhibitionid + " " + artworkid + " " + lat + " " + lng + " " + locid + "");
        return this.addExhibitionArtworkLocationRelationship(exhibitionid, artworkid, locid).then(() => {
            console.log("adding exhibition reference to artwork location orgid" + exhibitionid + " artid" + artworkid + " locid" + locid + "")
            return set(ref(getDatabase(), "artworkLocations/" + artworkid + "/" + locid + "/exhibition"), exhibitionid);
        });
    });
}
addExhibitionArtworkLocationRelationship(exhibitionid, artworkid, locationid) {
    return set(ref(getDatabase(), "exhibitionArtworkLocationRelationships/" + exhibitionid + "/" + artworkid + "/" + locationid), true);
}
getArtworkLocation(artworkid, locationid) {
    const db = getDatabase();
    const R = ref(db, "artworkLocations/" + artworkid + "/" + locationid);
    return get(R)
        .then((snapshot) => {
            return snapshot.val();
        });
}
deleteExhibitionArtworkLocationRelationship(exhibitionid, artworkid, locationid) {
    return set(ref(getDatabase(), "exhibitionArtworkLocationRelationships/" + exhibitionid + "/" + artworkid + "/" + locationid), null);
}
deleteArtworkLocation(artworkid, locationid) {
    const db = getDatabase();
    let exhibitionId;

    return get(ref(db, "artworkLocations/" + artworkid + "/" + locationid))
        .then((snapshot) => {

            if (!snapshot.val() || !snapshot.val().locHash)
                return false;

            let locHash = snapshot.val().locHash;
            if (snapshot.val().exhibition)
                exhibitionId = snapshot.val().exhibition;


            //delete the locationArtworkRelationship for this location 
            return set(ref(db, "locationArtworkRelationships/" + locHash + "/" + artworkid + "/" + locationid), null);
        })
        .then(() => {

            if (exhibitionId) {
                console.log("deleting location " + locationid + " from org " + exhibitionId);
                return this.deleteExhibitionArtworkLocationRelationship(exhibitionId, artworkid, locationid);
            }
            else
                return true;
        })
        .then(() => {

            //delete this location
            console.log("deleting location " + locationid + " from artwork " + artworkid);
            return set(ref(db, "artworkLocations/" + artworkid + "/" + locationid), null);
        })
        .then(() => {
            //delete geofire reference
            return this.geoFireContents.remove(locationid).catch((error) => { console.error("error removing location from geofire " + error); });
        });

}
addOrganisationReferenceForAllArtowrkLocations() {
    const db = getDatabase();

    return get(ref(db, "organisationArtworkLocationRelationships")).then((snapshot) => {
        let orgs = snapshot.val();
        let promiseList = [];
        for (const orgid in orgs) {
            let org = orgs[orgid];
            for (const artworkid in org) {
                let artwork = org[artworkid];
                for (const locid in artwork) {
                    promiseList.push(set(ref(db, "artworkLocations/" + artworkid + "/" + locid + "/organisation"), orgid));
                }
            }
        }
        return Promise.all(promiseList);

    });

}
removeAllNonOrgLocations() {
    const db = getDatabase();

    return get(ref(db, "artworkLocations")).then((snapshot) => {
        let arts = snapshot.val();
        let promiseList = [];
        for (const artworkid in arts) {
            let artwork = arts[artworkid];
            for (const locid in artwork) {
                let location = artwork[locid];
                if (!location.organisation)
                    promiseList.push(this.deleteArtworkLocation(artworkid, locid));
            }
        }
        return Promise.all(promiseList);

    });
}
convertAllStringLocationsToNumbers() {
    const db = getDatabase();
    return get(ref(db, "artworkLocations")).then((snapshot) => {
        let arts = snapshot.val();
        let promiseList = [];
        for (const artworkid in arts) {
            let artwork = arts[artworkid];
            for (const locid in artwork) {
                let location = artwork[locid];
                if (typeof location.lat === "string") {
                    console.log("converting artwork location for artwork " + artworkid + " location " + locid + " lat " + location.lat + " to number");
                    promiseList.push(set(ref(db, "artworkLocations/" + artworkid + "/" + locid + "/lat"), parseFloat(location.lat)));
                }
                if (typeof location.lng === "string")
                    promiseList.push(set(ref(db, "artworkLocations/" + artworkid + "/" + locid + "/lng"), parseFloat(location.lng)));
            }
        }
        return Promise.all(promiseList);
    });
}
addArtworkCollaborator(artworkid, collabEmail) {
    const db = getDatabase();
    let safeEmail = collabEmail.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");

    return set(ref(db, "artworkCollaborators/" + artworkid + "/" + safeEmail), "editor")
        .then(() => {
            return set(ref(db, "adminArtworkRelationships/" + safeEmail + "/" + artworkid), true);
        });
}
removeArtworkCollaborator(artworkid, collabSafeEmail) {
    const db = getDatabase();

    return set(ref(db, "artworkCollaborators/" + artworkid + "/" + collabSafeEmail), null)
        .then(() => {
            return set(ref(db, "adminArtworkRelationships/" + collabSafeEmail + "/" + artworkid), null);
        });
}
setRealtimeDataRule(artworkid, propertyid, typeStr) {
    if (typeStr === null) {
        return set(ref(getDatabase(), "realtimeDataRules/" + artworkid + "/" + propertyid + "/type"), typeStr)
            .then(() => {
                //get all realtime data for this property
                return get(ref(getDatabase(), "realtimeData/" + artworkid))

            }).then((snapshot) => {
                //delete all realtime data for this property
                let promiseList = [];
                snapshot.forEach((locationSnap) => {
                    locationSnap.forEach((userSnap) => {
                        userSnap.forEach((propertySnap) => {
                            if (propertySnap.key === propertyid) {
                                promiseList.push(set(ref(getDatabase(), "realtimeData/" + artworkid + "/" + locationSnap.key + "/" + userSnap.key + "/" + propertySnap.key), null));
                                console.log("deleted realtime data for " + propertyid + " at " + locationSnap.key + "/" + userSnap.key);
                            }
                        });
                    });


                });
                return Promise.all(promiseList);
            });

    }
    else
        return set(ref(getDatabase(), "realtimeDataRules/" + artworkid + "/" + propertyid + "/type"), typeStr);
}
setGlobalRealtimeData(artworkid, propertyid, value) {
    return set(ref(getDatabase(), "realtimeData/" + artworkid + "/global/all/" + propertyid), value);
}
pushGlobalRealtimeDict(artworkid, propertyid, value) {
    let newKey = push(ref(getDatabase(), "realtimeData/" + artworkid + "/global/all/" + propertyid)).key;
    return set(ref(getDatabase(), "realtimeData/" + artworkid + "/global/all/" + propertyid + "/" + newKey), value);
}
setGlobalRealtimeList(artworkid, propertyid, key, value) {
    // log type
    console.log("type of value is " + typeof value + " key: " + key + " value: " + value);
    //check if value is json string
    if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
        value = JSON.parse(value);
        console.log("value is json " + JSON.stringify(value));
        // let promiseList = [];
        // //iterate through json and set each key
        // Object.keys(value).forEach(function(k) {
        //     console.log("key: " + k + " value: " + value[k]);
        //     promiseList.push(set(ref(getDatabase(), "realtimeData/" + artworkid + "/global/all/" + propertyid + "/" + key + "/" + k), value[k]));
        // });
        // return Promise.all(promiseList);
    }
    // else 
    return set(ref(getDatabase(), "realtimeData/" + artworkid + "/global/all/" + propertyid + "/" + key), value);
}
clearRealtimeData(artworkid) {
    return set(ref(getDatabase(), "realtimeData/" + artworkid), null);
}
setLocationDataRuleType(artworkid, propertyid, typeStr) {
    return set(ref(getDatabase(), "locationDataRules/" + artworkid + "/" + propertyid + "/type"), typeStr);
}
setLocationDataRuleTooltip(artworkid, propertyid, tooltip) {
    return set(ref(getDatabase(), "locationDataRules/" + artworkid + "/" + propertyid + "/tooltip"), tooltip);
}
setLocationDataRule(artworkid, propertyid, rule) {
    return set(ref(getDatabase(), "locationDataRules/" + artworkid + "/" + propertyid), rule);
}
setLocationData(artworkid, locationid, propertyid, value) {
    return set(ref(getDatabase(), "artworkLocations/" + artworkid + "/" + locationid + "/locationData/" + propertyid), value);
}
setArtworkLocationData(artworkid, locationid, property, value) {
    console.log(`setting ${artworkid} ${locationid} ${property} ${value}`);
    return set(ref(getDatabase(), "artworkLocations/" + artworkid + "/" + locationid + "/" + property), value);
}

setArtworkCloudFunction(artworkid, functionName, data) {
    return set(ref(getDatabase(), "artworkCloudFunctions/" + artworkid + "/" + functionName), data);
}
setArtworkCloudFunctionConfig(artworkid, functionName, property, config) {
    return set(ref(getDatabase(), "artworkCloudFunctions/" + artworkid + "/" + functionName + "/config/" + property), config);
}

triggerCloudFunction(artworkid, functionName, config, callback) {
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, functionName);
    console.log(`calling ${functionName} with ${artworkid}`);
    return func({ artworkid: artworkid, config: config }).then((result) => {
        console.log(JSON.stringify(result));
        callback("success");
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        callback("error");
    });
}
setArtworkCloudFunctionEnabled(artworkid, functionName, enabled) {
    return set(ref(getDatabase(), "artworkCloudFunctions/" + artworkid + "/" + functionName + "/enabled"), enabled);
}
changeExperienceOwner(expid, newOwnerEmail) {
    const db = getDatabase();
    // let safeEmail = newOwnerEmail.trim().toLowerCase().replace(new RegExp("\\.", "g"), "%2E");
    console.log("changing experience owner to " + newOwnerEmail);
    let oid;
    let user;
    //go through collaborators
    return get(ref(db, "artworkCollaborators/" + expid)).then((snapshot) => {
        let promiseList = [];
        snapshot.forEach((collab) => {
            let role = collab.val();
            if (collab.key !== newOwnerEmail)
                promiseList.push(set(ref(db, "artworkCollaborators/" + expid + "/" + collab.key), "editor"));
            else
                promiseList.push(set(ref(db, "artworkCollaborators/" + expid + "/" + collab.key), "owner"));
        });
        return Promise.all(promiseList);
    }).then(() => {
        //get owner id
        console.log("getting owner id");
        return get(ref(db, "emailAdminRelationships/" + newOwnerEmail)).then((snapshot) => {
            return snapshot.val();
        });
    }).then((ownerId) => {
        if (!ownerId) {
            console.error("owner id not found");
            return;
        }
        oid = ownerId;
        //set owner id  
        return set(ref(db, "artworkMetadatas/" + expid + "/owner"), ownerId);
    }).then(() => {
        //get user data
        return get(ref(db, "admins/" + oid));
    }).then((snapshot) => {
        user = snapshot.val();
        //set artworkMetadatas ownerUsername and ownerName 
        return set(ref(db, "artworkMetadatas/" + expid + "/ownerName"), user.firstname + " " + user.lastname);

    }).then(() => {
        //set admin artwork relationships
        return set(ref(db, "artworkMetadatas/" + expid + "/ownerUsername"), user.username);
    });
}

//#endregion
//#region Images
uploadImage(dataroot, type, id, image) {

    //upload file to google cloud storage
    const storage = getStorage();
    const storageRef = sref(storage, dataroot + '/' + id + '/images/' + type);
    console.log("uploading image to " + storageRef + " data size " + image.size + " bytes");
    return uploadBytes(storageRef, image)


}
getImageUrl(dataroot, type, id) {
    if (!dataroot || !type || !id)
        return Promise.reject("invalid params");
    //get thumbnail file from storage
    const storage = getStorage();
    const storageRef = sref(storage, dataroot + '/' + id + '/images/' + type);

    //check if image exists

    console.log("getting image url atref: " + storageRef);

    return getDownloadURL(storageRef)
        .then((url) => {
            console.log("got image url " + url);
            return url;
        }).catch(error => {
            if (error.code === 'storage/object-not-found') {
                return Promise.resolve(false);
            } else {
                return Promise.reject(error);
            }
        });

}
getImageThumbnailUrl(dataroot, type, size, id) {
    //get thumbnail file from storage
    const str = dataroot + '/' + id + '/images/thumbs/' + type + '_' + size;
    //replace slash with %2F
    const safesstr = str.replace(new RegExp("/", "g"), "%2F");
    return ("https://storage.googleapis.com/xref-client.appspot.com/" + safesstr);
}
deleteImage(dataroot, type, id) {
    //delete the bundle
    const storage = getStorage();
    const storageRef = sref(storage, dataroot + '/' + id + '/images/' + type);

    //delete if the ref exists

    return getDownloadURL(storageRef)
        .then(url => {
            //delete
            return deleteObject(storageRef)

        })
        .catch(error => {
            if (error.code === 'storage/object-not-found') {
                return Promise.resolve(false);
            } else {
                return Promise.reject(error);
            }
        });
}
//check if image at url exists
imageExists(url, callback) {
    var img = new Image();

    img.onload = function () {
        callback(true);
    };

    img.onerror = function () {
        callback(false);
    };

    img.src = url;
}

//#endregion
//#region Bundles
uploadBundle(id, type, bundleFile, platform, bundleType = '') {
    console.log("uploading " + type + " bundle " + id + " " + platform + " " + bundleType);
    //create bundleType string
    let bundleTypeStr = bundleType ? '_' + bundleType : '';

    //create uid for bundle
    let GUID = this.Uid() + uuidv4();
    let storageRoot;
    let dbRoot;
    switch (type) {
        case 'artwork':
            storageRoot = 'artworkdata';
            dbRoot = 'artworkMetadatas';
            break;
        case 'exhibition':
            storageRoot = 'exhibitiondata';
            dbRoot = 'exhibitionMetadatas';
            break;
        default:
            throw new Error("invalid type " + type);
    }

    //!Temporary solution -- check deleteBundle also

    let path = platform === 'VisionOS' ? storageRoot + '/' + id + '/bundle/' + platform + bundleTypeStr : storageRoot + '/' + id + '/bundle/' + platform + bundleTypeStr + '_' + GUID;

    console.log("uploading bundle to " + path);

    //upload file to google cloud storage
    const storage = getStorage();
    const storageRef = sref(storage, path);

    //if a bundle already exists, delete it
    return this.deleteBundle(id, type, platform, bundleType)//does nothing if no bundle present
        .then(() => {
            return uploadBytes(storageRef, bundleFile)
        })
        .then((snapshot) => {

            //then set bundlekey to guid in db 
            const db = getDatabase();
            return set(ref(db, dbRoot + "/" + id + "/" + "bundleKey_" + platform + bundleTypeStr), GUID);
        });

}
deleteBundle(id, type, platform, bundleType = '') {
    console.log("deleting " + type + " bundle " + id + " " + platform + " " + bundleType);
    const db = getDatabase();

    //create bundleType string
    let bundleTypeStr = bundleType ? '_' + bundleType : '';

    let storageRoot;
    let dbRoot;
    switch (type) {
        case 'artwork':
            storageRoot = 'artworkdata';
            dbRoot = 'artworkMetadatas';
            break;
        case 'exhibition':
            storageRoot = 'exhibitiondata';
            dbRoot = 'exhibitionMetadatas';
            break;
        default:
            throw new Error("invalid type " + type);
    }

    //check the db has bundle
    return get(ref(db, dbRoot + "/" + id + "/" + "bundleKey_" + platform + bundleTypeStr))
        .then((snapshot) => {
            if (snapshot.val()) {

                //delete the bundle
                const storage = getStorage();
                //! Temporary solution -- check uploadBundle also
                let path = platform === 'VisionOS' ? storageRoot + '/' + id + '/bundle/' + platform + bundleTypeStr : storageRoot + '/' + id + '/bundle/' + platform + bundleTypeStr + '_' + snapshot.val();
                const storageRef = sref(storage, path);

                return deleteObject(storageRef)
                    .then(() => {

                        //set the db value to false
                        return set(ref(db, dbRoot + "/" + id + "/" + "bundleKey_" + platform + bundleTypeStr), null);
                    });
            }
        });
}
getBundleMetadata(type, id, plafromAndType, key = '') {
    let root;
    switch (type) {
        case 'artwork':
            root = 'artworkdata';
            break;
        case 'exhibition':
            root = 'exhibitiondata';
            break;
        default:
            throw new Error("invalid type " + type);
    }

    let path = plafromAndType === 'VisionOS' ? root + '/' + id + '/bundle/' + plafromAndType : root + '/' + id + '/bundle/' + plafromAndType + '_' + key;

    //get bundle metadata from firebase storage
    const storage = getStorage();
    const storageRef = sref(storage, path);
    return getMetadata(storageRef);

}
//#endregion
//#region Search
searchUsers(query, queryBy) {
    //call function to search users
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, 'search-searchUsers');
    console.log("calling search-searchUsers with " + query + " " + queryBy);
    return func({ query: query, queryBy: queryBy }).then((result) => {
        // console.log(JSON.stringify(result));
        return result.data;
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    });
}
searchExperiences(query, queryBy) {
    //call function to search experiences
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, 'search-searchExperiences');
    console.log("calling search-searchExperiences with " + query + " " + queryBy);
    return func({ query: query, queryBy: queryBy }).then((result) => {
        // console.log("got experiences:" + result.length);
        return result.data;
    }
    ).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return null;
    });
}

//#endregion
//#region Master Control
addFeaturedExperience(exp) {
    const db = getDatabase();
    const id = exp.id;
    const data = { title: exp.title, author: exp.author }
    return set(ref(db, "featuredExperiences/" + id), data);
}
removeFeaturedExperience(expid) {
    const db = getDatabase();
    return set(ref(db, "featuredExperiences/" + expid), null);
}
    //#endregion
    //#region Util
    async getAltitude(lat, lng) {
    return await Geo.GetAltitudeFromLocation(lat, lng);
}

//#endregion
//#region DB Debug Functions
getAllMissingLocationArtworkRelationships() {
    //go through all the artwork locations and check that there is a location artwork relationship for each one
    const db = getDatabase();
    return get(ref(db, "artworkLocations")).then((snapshot) => {
        let artworks = snapshot.val();
        let promiseList = [];
        for (const artworkid in artworks) {
            let artwork = artworks[artworkid];
            for (const locationid in artwork) {
                let location = artwork[locationid];
                if (location.locHash) {
                    promiseList.push(get(ref(db, "locationArtworkRelationships/" + location.locHash + "/" + artworkid + "/" + locationid)).then((snapshot) => {
                        if (!snapshot.exists()) {
                            console.log("missing location artwork relationship for artwork " + artworkid + " location " + locationid + (location.organisation ? " organisation " + location.organisation : ""));
                            //add the relationship
                            var locData = {
                                lat: location.lat,
                                lng: location.lng,
                                alt: location.alt,
                                locHash: location.locHash
                            }
                            return set(ref(db, "locationArtworkRelationships/" + location.locHash + "/" + artworkid + "/" + locationid), locData);
                        }
                    }));
                }
            }
        }
        return Promise.all(promiseList);
    });
}
/*
CopyAJ(){
    //go through all artwork locations for artowrkid -NMDopAR3UhIUoEf_xvh and overwrite locationData
    const db = getDatabase();
    return get(ref(db, "artworkLocations/-NMDopAR3UhIUoEf_xvh")).then((snapshot) => {
        let locations = snapshot.val();
        let promiseList = [];
        snapshot.forEach((locSnap) => {
            if(Object.keys(AdamArtworkLocationsUpdated).includes(locSnap.key))
            {
                // console.log("found location " + locSnap.key);
                let locid = locSnap.key;
                let location = locSnap.val();
                if(!location.locationData || !location.locationData.organisationImageUrl)
                {
                    if(AdamArtworkLocationsUpdated[locid].locationData && AdamArtworkLocationsUpdated[locid].locationData.organisationImageUrl)
                    promiseList.push(set(ref(db, "artworkLocations/-NMDopAR3UhIUoEf_xvh/" + locid + "/locationData/organisationImageUrl"), AdamArtworkLocationsUpdated[locid].locationData.organisationImageUrl));
                    if(AdamArtworkLocationsUpdated[locid].locationData && AdamArtworkLocationsUpdated[locid].locationData.organisationName)
                    promiseList.push(set(ref(db, "artworkLocations/-NMDopAR3UhIUoEf_xvh/" + locid + "/locationData/organisationName"), AdamArtworkLocationsUpdated[locid].locationData.organisationName));

                    console.log("set location data for " + locid);
                }
            }
            
        });
        // return Promise.all(promiseList);
    });
}*/
countChlidren(path) {
    const db = getDatabase();
    return get(ref(db, path)).then((snapshot) => {
        let count = 0;
        snapshot.forEach((child) => {
            count++;
        });
        console.log("count: " + count);
    });
}
deleteArtworksBundleReferences() {
    const db = getDatabase();
    return get(ref(db, "artworkMetadatas")).then((snapshot) => {
        let artworks = snapshot.val();
        let promiseList = [];
        for (const artworkid in artworks) {
            let artwork = artworks[artworkid];
            if (artwork.bundleKey_Android) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_Android"), null));
            }
            if (artwork.bundleKey_Android_Metabundle) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_Android_Metabundle"), null));
            }
            if (artwork.bundleKey_IOS) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_IOS"), null));
            }
            if (artwork.bundleKey_IOS_Metabundle) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_IOS_Metabundle"), null));
            }
            if (artwork.bundleKey_Windows) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_Windows"), null));
            }
            if (artwork.bundleKey_Windows_Metabundle) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_Windows_Metabundle"), null));
            }
            if (artwork.bundleKey_MacOS) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_MacOS"), null));
            }
            if (artwork.bundleKey_MacOS_Metabundle) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/bundleKey_MacOS_Metabundle"), null));
            }
            if (artwork.thumbnail) {
                promiseList.push(set(ref(db, "artworkMetadatas/" + artworkid + "/thumbnail"), null));
            }
        }
        return Promise.all(promiseList);
    });

}
copyAllEmailsToAuthorized() {
    const db = getDatabase();
    //get all emailAdminRelationships and copy to authorizedEmails
    return set(ref(db, "authorizedEmails/max@untold%2Egarden"), true);



}
copyUsersToTypesense() {
    //call cloud function to copy users to typesense
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "search-copyUsersToSearchEngine");
    console.log(`calling copyUsersToSearchEngine`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}
createVisionOSReferences() {
    //call cloud function to copy users to typesense
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "admin-createTempVisionOSRefs");
    console.log(`calling createTempVisionOSRefs`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}
copyExperiencesToTypesense() {
    //call cloud function to copy experiences to typesense
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "search-copyExperiencesToSearchEngine");
    console.log(`calling copyExperiencesToSearchEngine`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}
addExperienceRefsToOwners() {
    // call cloud function to add experience refs to owners
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "users-addExperienceRefsToOwners");
    console.log(`calling addExperienceRefsToOwners`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}
geofireCleanup() {
    //call function called mapping-geofireCleanup
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "mapping-geofireCleanup");
    console.log(`calling mapping-geofireCleanup`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}

setAllExperienceOwners() {
    //call function users-setAllExperienceOwners
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "users-setAllExperienceOwners");
    console.log(`calling users-setAllExperienceOwners`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });
}

clearRemovedExperiences() {
    //call function users-clearRemovedExperiences
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "admin-clearRemovedExperiences");
    console.log(`calling users-clearRemovedExperiences`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });

}
    async validateDBCollaborators() {
    //get all artworkCollaborators from db
    const db = getDatabase();
    const snapshot = await get(ref(db, "artworkCollaborators"));
    const artworkCollaborators = snapshot.val();
    let promiseList = [];
    //for every key inb artworkCollaborators check if it exists in artowrkMetadatas
    for (const artworkid in artworkCollaborators) {
        promiseList.push(get(ref(db, "artworkMetadatas/" + artworkid)).then((snapshot) => {
            if (!snapshot.exists()) {
                console.log("artworkCollaborators key " + artworkid + " does not exist in artworkMetadatas");
            }
            else
                console.log("artworkCollaborators key " + artworkid + " exists in artworkMetadatas");
        }
        ));
    }
    await Promise.all(promiseList);
    console.log("done");
    return "done";

}

updateUserDisplayNames(){
    //call function admin-updateAllUserDisplayNames
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "admin-updateAllUserDisplayNames");
    console.log(`calling admin-updateAllUserDisplayNames`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });

}

setAllOwnerUsernames(){
    //call function admin-setAllOwnerUsernames
    const functions = getFunctions(undefined, cloudFunctionsRegion);
    const func = httpsCallable(functions, "admin-setAllOwnerUsernames");
    console.log(`calling admin-setAllOwnerUsernames`);
    return func({}).then((result) => {
        console.log(JSON.stringify(result));
        return "success";
    }).catch((error) => {
        console.log(`error: ${JSON.stringify(error)}`);
        return "error";
    });

}


    //#endregion

}

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}