import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const ArtworkLocations = () => {

    useEffect(() => {

        console.log('Artwork locations mounted');

        return () => {
            console.log('Artwork locations unmounted');
        }
    }, []);

    //Main
    return (
        <ThemeProvider theme={theme}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            px: '16px'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              px: '10px',
              height: '60px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
              width: '100%',
              maxWidth: '700px'
            }}>
              <Typography>
                <Link to={'/'} style={{ color: 'white' }}>Meadow Console</Link>
              </Typography>
            </Box>
      
            <Box sx={{
              maxWidth: '700px',
              width: '100%',
              flexGrow: 1,
              mx: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              pt: '32px'
            }}>
              <Typography sx={{ my: '16px' }} variant='h4'>Lista över platstyper för verken</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>De tio olika konstverken i Meadow kommer att placeras ut på olika platstyper i kommunen. Tanken är att skisserna och beskrivningarna av verken på den här sidan ska ge er en första inblick i hur konstverken kommer se ut och vilka teman de kommer utforska. Konstverken är fortfarande under utveckling och kan förändras i utseende innan det är dags för vernissage den 20 maj.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/james.webp" alt="James webb" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>James Webb</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> En bänk, sittplats eller en plats för kontemplation utanför eller mycket nära ett äldreboende.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Beskrivning av konstverket:</strong> Verket kommer till största delen att vara ljud. De sjungna och talade rösterna i ljudet kommer att öka beroende på antalet personer (publik som använder appen) som finns i närheten.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Aktuell beskrivning av upplevelsen: Publiken samlas på platsen för att lyssna på stycket. Målet är att ljudverket kommer påverka deras syn på platsen där de befinner sig.</Typography>
                        
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/song.webp" alt="SONG" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>SONG – Choterina Freer, Anna Kinbom och Rut Karin Zettergren i samarbete med Paola Torres Núñez del Prado och Rosalie Yu</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">I verket möter betraktaren fyra fantasiväsen som gör en kollektiv ritual i form av en kollektiv teckning. Det finns en berättarröst som berättar en spekulativ fiktiv skapelseberättelse, en blandning av berättelser från konstnärernas kulturer och väver in av element från olika tidsperioder skrivna med exquisite corpse teknik – ungefär som en vikgubbe. Upplevelsen slutar när karaktärens teckningar har bildat en korg och appanvändaren ombeds då gå in i cirkeln. Inne i cirkeln kan de se korgen som nu innehåller föremål från berättelsen.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Verket är ett samarbete mellan fem konstnärer och bygger på kunskap sprungen ur konstnärliga samarbetsmetoder och kollektiva strategier från intersektionell feminism. Ovan är en tidig skiss av konstverket där fantasiväsendena står i en cirkel utanför en tygaffär. I cirkeln mellan dem ser vi den kollektiva teckning som de skapat.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Karaktärerna publiken kommer att möta i upplevelsen är Vinterhaggan, dels forntida vintergudinna, dels duva; hon är inspirerad av den engelska folklorens bogeywomen och Cailleach från keltisk mytologi. Spindelpersonen, inspirerad av den grekiska myten om Arachne där en kvinna förvandlades till en spindel och av de vävande ödesgudinnorna Nornorna i nordisk mytologi. Karaktären påminner oss också om vävstolar som föregångare till datorer. Glitch Witch som bygger på idéer om att de häxorna som brändes i Europa var tidiga kvinnliga vetenskapsmän. Den tekniskt uppgraderade karaktären använder sin magi för att skapa trassel i digitala system för att frigöra teknokultur. 白娘子永鎭雷峰塔 är den magiska vita ormpersonen inspirerad av kinesisk mytologi.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Beskrivning av platser för AR-upplevelsen: </strong>Vi vill att verket ska visas på platser som är relaterade till ”kvinnligt” kodat hantverk, arbete och historia. Därför föreslår vi att den visas på platser med anknytning till tyg och textil, t.ex. utanför gemensamma tvättstugor, kemtvätt, tyg- eller garnaffärer eller textilfabriker/väverier. Andra möjliga platser kan vara en park, ett kolonilottsområde eller hos kvinnoägda företag som ex klädaffärer, nagelsalonger eller frisörsalonger. Eftersom verket kommer att bilda en cirkel och vara interaktivt tänker vi att verket även kan visas utanför butiker som i skissen.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/asa.webp" alt="Åsa Cederqvist" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Åsa Cederqvist</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Ett ingenmansland. En plats vid sidan av, alldeles vid samhällets utkant. Lite bortglömd, lite baksidan. En före detta ordnad plats, som nu åter blir förvildad och återtagen av naturen.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">En plats i utkanten av staden/det civiliserade samhället, kanske en igenvuxen, övergiven, spillra av en forna industri eller bruksmiljö? T.ex. en jättelik tom bakgård, en öppen plan yta, en nedlagd utomhus ishockeyrink, en igenvuxen grusplan…</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">En öppen yta bakom ett nedstängt köpcentrum, sportarena eller skrotupplag eller bakom storsågbrukets högar av spån. Eller ett tomrum mitt i staden. Gärna att en ser som en horisont eller har en annan slags bakgrund i djupet. Det kan vara fält, kullar, en dalsänka, havet, sjö eller annan fond. Platsen kan ligga på en höjd eller att det ligger inramat som i ett hörn eller bakgård. Men gärna öppet för himmel uppåt.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/adam.webp" alt="Adam James" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Adam James</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Den bästa platsen att ha en picknick på. En plan yta som är trevlig att sitta på, förslagsvis gräs. Platsen bör vara ungefär i samma storlek som platsen för ett kubbspel, cirka 12×12 meter.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Den bör inte vara för avlägsen men inte heller helt full med folk. Interaktionen med konstverket kommer att innebära att betraktaren rör på sig, leker och kanske lägger sig ner på marken, så platsen bör bjuda in till dessa aktiviteter.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/Untoldgarden.webp" alt="Untold Garden" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Untold Garden</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Det Gemensamma Oläsbara är lika delar socialt nätverk och offentlig konst, en virtuell kommunikationsplattform som endast kan besökas på alla Sveriges torg, och som visar alla meddelanden i ett stort flödande moln ovanför besökaren. Alla som besöker kan själva medverka i verket genom att skriva sina egna meddelanden och läsa andras, och på så vis påverka formen på verket. Verket kopplar samman modern kommunikationsteknologi med torgets historiska roll som en plats för politik och skvaller, och binder samman alla Sveriges torg till ett.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Ett torg. Inte nödvändigtvis det största, utan det torg som känns mest naturligt att stanna och prata på, att skvallra på, att tjuvlyssna på andra på, att diskutera politik på. I brist på torg så fungerar en plats där folk naturligt samlas för att prata, t.ex. utanför mataffären eller biblioteket, eller bredvid gatuköket.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/eric.webp" alt="Eric Magassa" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Eric Magassa</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Brunn (olika typer av brunnar så som gatubrunn, vattenbrunn som finns i bebyggelse)</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Beskrivning av verket: </strong>Verkets platstyp är en brunn. Brunnen är likt en portal till en annan värld under våra fötter. Genom att rikta mobilens kamera mot brunnen aktiveras en warp-effekt som förvränger miljön som omger besökaren till en typ av tunnel. En warp-effekt kan liknas vid en ändlös rörelse, en öppning likt ett svart hål. Besökares röst påverkar via verket sin omgivning genom att skapa en förskjutning i det familjära och vardagliga. Genom att ljuda, sjunga eller prata in i telefonen påverkar pitchen på besökarens röst Warp-effektens utseende/rörelse och mått. In i brunnen går det bra att önska sig något eller varför inte kasta ner en hemlighet (via ljud/text) som en gått och burit på. I retur får besökaren en affirmation likt en styrkekram på vägen.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/pastelae.webp" alt="Pastelae" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Pastelae</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Torg</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Torgen har haft en lång historia av att vara en viktig plats för det sociala och ekonomiska livet i glesbygden i norra Sverige, även i staden Piteå. Piteås torg är från 1600-talet och är ett av två torg från denna tid som finns kvar i Sverige.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Idag är torgen i Piteå fortfarande en viktig plats för det sociala och ekonomiska livet i staden. Det är ett levande och livligt ställe där människor kan träffas, handla och delta i olika kulturella händelser. Torgen är också en viktig plats för turister att besöka, eftersom det ger en inblick i stadens historia och kultur. Sammanfattningsvis har torgen alltid haft en viktig roll i Piteås historia och fortsätter att vara en viktig plats för det sociala och ekonomiska livet i staden.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Vi har valt torget som vår plats för kommande konstverket med arbetsnamn Berget, för att visa på hur det fortfarande är en knutpunkt och viktig plats i mindre städer. Inspirerade av ett tidigare uppdrag, då vi gjorde en snöskulptur på vårat äldsta torg, Rådhustorget, valde vi torget som plats då vi redan då märkte hur uppskattat det var och hur nära man kom publiken då det hela tiden strömmade förbi nyfikna åskådare medan vi jobbade fram motivet som bestod av fyra sammanflätade figurer som symboliserade sammanhållning. Många stannade till och beundrade motivet som dom tyckte var lätt att ta till sig och upplyftande att beskåda. Det var en fin upplevelse för oss som främst jobbar i skymundan, digitalt och med en obskyr process.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Berget består av ett centralt berg med en ren på toppen som blickar ut över torget där en renhjord strövar, där varje ren är genererad av en användare i appen runt om i landet. Temat sammanhållning går igen, med fler intrikata delar som kopplar verket till det nationella och globala.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/oscar.webp" alt="Oscar Häggström" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Oscar Häggström</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> En plats som är en populär plats att jogga på. Det kan rör sig om ett motionsspår men även en park, utmed vattnet osv.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Titel:</strong> Jogg your mind</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Strävan till att bli alltmer optimerad ökar. Att alltid prestera, men aldrig vara trött.  Är syftet att vi ska bli som robotar?  Vad händer med människan bakom när vi mer och mer låter oss reduceras till identifierbara och kommunicerbara tecken över en skärm. Att representera hela sig själv genom en bild.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Verket tar sin inspiration i frågor kring optimering och objektifiering som förekommer i träningskultur som “fitspo” och “fitspiration”.  Verket kommer att presenteras kring löprundor som exempelvis ett klassisk elljusspår.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Väl på plats kommer du kunna träffa och jogga tillsammans med en karaktär i appen. Hur länge du “tränar” tillsammans med karaktären kommer att dokumenteras och loggas samt göra det möjligt att se andras resultat och statistik. Jag vill med verket ta upp vårt förhållande till perfektion och hur den inneboende strävan används och premieras av olika koncerner. Hur påverkar den sociala jämförelsen oss och våra ideal i en dynamisk och digital värld?</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/ls.webp" alt="Lundahl & Seitl" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Lundahl & Seitl</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Vid fontäner eller gamla träd. Konstverket låter deltagarna påverka den inre meteorologin hos en digital superorganism befolkad av virtuella mikrober som efterliknar egenskaperna hos pseudomonas syringae, en ”regnbildande bakterie” som kan leva och överleva på höga höjder inom moln.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Den digitala superorganismen omfamnar alla geografiska platser där konstverket finns. Deltagare på olika platser kan interagera genom att försvara, attackera eller signalera med andra användare, vilket potentiellt aktiverar virtuellt regnfall. AR-tekniken som gör denna meteorologiska transformation möjlig är baserad på rörelse och ljud genererade av besatta mikrober som initierar virtuell nederbörd. Likt ett urbant cirkulationssystem är den digitala superorganismens kropp sammankopplad genom portaler fysiskt placerade vid en fontän eller ett gammalt träd hos konstföreningar över hela Sverige.</Typography>
                        <img style={{ marginTop: '32px' }}  src="guide-images/artworks/spop.webp" alt="Space Popular" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Space Popular</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Vi har i alla tider gemensamt fantiserat och drömt om möjligheten att förflytta oss virtuellt över tid, rum och världar något som populärkultur och litteratur utforskat på olika sätt. Portaler är magiska trösklar som trotsar fysikens lagar och kan omedelbart skicka en person över tid och rum. Space Popular har samlat på sig olika typer av beskrivningar av portaler från böcker, filmer, spel med mera. Mellan 2020-2022 sammanställde Space Popular ett stort arkiv av över 1000 fiktiva portaler som fungerar som en utgångspunkt för det nya verket.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Plats:</strong> Space Popular vill att besökaren möter deras konstverk som är en tyg-portal till en virtuell värld där en gångväg korsar en annan gångväg eller trottoar. Platsen där två vägar korsas är i sig fylld med olika myter och symbolik . Se bilder för förslag på vad vi menar.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">En sak att hålla i åtanke är att det är viktigt att besökaren kan stå tryggt i en korsning av trottoarer och eller gågator utan att vara i fara för olika typer av trafik när de upplever verket.</Typography>
                                                
                    </Box>
                </Box>
            
        </ThemeProvider >
    );
}

export default ArtworkLocations; 