import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import MainContext from "../MainContext";
import { IconButton, FormControl, InputLabel, Fab, ToggleButton, Tooltip, Checkbox, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { set } from "firebase/database";
import ImageUpload from "../components/ImageUpload";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import validator from 'validator';



const EditProfile = () => {
    const mainService = useContext(MainContext);
    const navigate = useNavigate();

    const [uid, setUid] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [biography, setBiography] = useState('');
    const [editedUsername, setEditedUsername] = useState('');
    const [isEditingUsername, setIsEditingUsername] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [usernameErrorMessage, setUsernameErrorMessage] = useState('');


    useEffect(() => {

        console.log('EditProfile mounted');
        console.log('uid: ' + mainService.Uid());
        setUid(mainService.Uid());

        mainService.addDataListener("admins/" + mainService.Uid(), handleAdminMetadataChanged);


        return () => {
            console.log('EditProfile unmounted');
            mainService.removeDataListener("admins/" + mainService.Uid(), handleAdminMetadataChanged);
        }
    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }
    const handleAdminMetadataChanged = (data) => {
        //debug json stringify
        console.log("admin metadata changed: " + JSON.stringify(data));
        setFirstName(data.firstname);
        setLastName(data.lastname);
        setUsername(mainService.decodeUsername(data.username));
        setBiography(data.biography);
    }
    const handleEditFirstName = (event) => {
        let val = event.target.value;
        setFirstName(val);

    }
    const handleFinishEditFirstName = (event) => {
        let val = event.target.value;
        if(!validator.isAlpha(val.replace(/\s+/g, '')))
        {
            setFirstName(val);
            setFirstNameError(true);
            return;
        }
        setFirstNameError(false);

        mainService.setUserData("firstname", val, (suc) => {
            // setAddressError(!suc);
        });
    }
    const handleEditLastName = (event) => {
        let val = event.target.value;
        setLastName(val);

    }
    const handleFinishEditLastName = (event) => {
        let val = event.target.value;
        if(!validator.isAlpha(val.replace(/\s+/g, '')))
        {
            setLastName(val);
            setLastNameError(true);
            return;
        }
        setLastNameError(false);

        mainService.setUserData("lastname", val, (suc) => {
            // setAddressError(!suc);
        });
    }
    const handleEditUsername = (event) => {
        let val = event.target.value;
        setIsEditingUsername(true);
        setEditedUsername(val);
    }
    const handleFinishEditUsername = (event) => {
        let val = event.target.value;
        mainService.validateUsername(editedUsername, (suc, msg) => {
            if (suc) {
                mainService.setUserUsername(val, username, (suc) => {
                    // setAddressError(!suc);
                });
                setUsernameError(false);
            }
            else {
                setUsernameError(true);
                setUsernameErrorMessage(msg);
            }
        });
    }
    const handleAccountDelete = (event) => {
        console.log("Account delete!!");
        mainService.deleteCurrentUserAccount()
            .then(() => {
                navigate('/');
            })
            .catch((err) => {
                if (err.code === 'auth/requires-recent-login') {
                    //deleting user requires recent login ask user to sign in again
                    startYNDialogAction(event, "Deleting your account requires you to sign in again. Do you want to sign in again?", () => {
                        mainService.SignOut().then(() => {
                        navigate('/signin');
                        });
                    });
                }
                console.log(err);
            })
    }
    const handleEditBiography = (event) => {
        let val = event.target.value;
        mainService.setUserData("biography", val, (suc) => {
            // setAddressError(!suc);
        });
    }


    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }


    //Render main info
    const renderMainInfo = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, flexShrink:1, mx: '16px', display: 'flex', flexDirection: 'column', pt: '32px' }}>
                    <Box sx={{ display: 'flex',  flexDirection: 'row'}}>
                    
                        <Box sx={{ display: 'flex',  m:'10px', flexDirection: 'column',  width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                            <TextField sx={{ m: '10px' }} label="Username" variant="outlined" value={isEditingUsername ? editedUsername : username} onChange={handleEditUsername} onBlur={handleFinishEditUsername} />
                            {usernameError && <Typography sx={{ m: '10px', color: 'red' }}>{usernameErrorMessage}</Typography>}
                            <TextField inputProps={{ maxLength: 35 }} sx={{ m: '10px' }} label="First Name" variant="outlined" value={firstName} onChange={handleEditFirstName} onBlur={handleFinishEditFirstName} />
                            {firstNameError && <Typography sx={{ m: '10px', color: 'red' }}>Please enter a valid first name</Typography>}
                            <TextField inputProps={{ maxLength: 35 }} sx={{ m: '10px' }} label="Last Name" variant="outlined" value={lastName}  onChange={handleEditLastName} onBlur={handleFinishEditLastName} />
                            {lastNameError && <Typography sx={{ m: '10px', color: 'red' }}>Please enter a valid last name</Typography>}
                            <TextField multiline inputProps={{ maxLength: 150}} sx={{ m: '10px' }} label="Biography" variant="outlined" value={biography}  onChange={handleEditBiography}/>
                        </Box>
                        <Box sx={{ display: 'flex', m:'10px', p:'10px', flexDirection: 'column', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Typography>Profile image</Typography>
                        <ImageUpload sx={{ display: 'block',  width:'100%' }} 
                        title={username ?? mainService.Uid()} 
                        key={"profile"}
                        type="profile"
                        width = {'200px'}
                        height = {'200px'}
                        borderRadius = {'50%'}
                        cropAspect = {1}
                        mainService={mainService}
                        id={mainService.Uid()}
                        storagePath="userdata" />
                        </Box>
 
                    </Box>
                <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete your account? This can't be undone!", handleAccountDelete);
                            }}>Delete Account</Button>
                </Box>
            </Box>
        )
    }



    //Main
    return (
        <ThemeProvider theme={theme}>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>

                <Box sx={{ display: 'flex', alignItems: 'center', px: '10px', height: '60px', minHeight: '60px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
                    <IconButton onClick={() => navigate('/', { tabValue: 1 })}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography sx={{ ml: '8px' }}>
                        Account Settings
                    </Typography>
                    <Box sx={{ flexGrow: 1 }}></Box>
                </Box>

                {renderMainInfo()}


                {renderYNDialog()}
            </Box>
            {/* )} */}
        </ThemeProvider>
    );
}

export default EditProfile; 