import React from 'react';

import { Box, Button, Dialog, Fab, Stack } from '@mui/material';

import CircularProgress from '@mui/material/CircularProgress';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import placeholder from '../assets/placeholder-image.jpg';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
    convertToPixelCrop,
} from 'react-image-crop'

import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import e from 'cors';
import { set } from 'firebase/database';

 



class ImageUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            busy: true,
            url: "",
            crop: {
                unit: '%',
                width: 30,
                aspect: this.props.cropAspect,
            },
            cropOpen: false,
            imageRef: React.createRef(),
            blobUrlRef: React.createRef(),
            completedCrop: null,
            imageSrc: null,
            previewCanvasRef: React.createRef()

        }
        this.onImageLoad = this.onImageLoad.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.cropCompleted = this.cropCompleted.bind(this);
        // this.onDownloadCropClick = this.onDownloadCropClick.bind(this);
        this.computePreview = this.computePreview.bind(this);


    }


    handleImageUpload = (file,isCropped) => {

        if (isCropped === false) {
            //get file - no crop required
            this.setState({ busy: true, url: URL.createObjectURL(file) });
            this.props.mainService.uploadImage(this.props.storagePath, this.props.type, this.props.id, file).then(() => {
                this.setState({ busy: false });
            });
            return;
        }

        //get blob from cropped image
        this.state.previewCanvasRef.current.toBlob((blob) => {


            this.setState({ url: URL.createObjectURL(blob) });

            this.setState({ busy: true });
            this.props.mainService.uploadImage(this.props.storagePath, this.props.type, this.props.id, blob).then(() => {
                this.ResetStates();

            });
        });
    }

    componentDidMount() {
        //on mount
        this.props.mainService.getImageUrl(this.props.storagePath, this.props.type, this.props.id).then((url) => {
            if (url) {
                this.setState({ url: url });

            }
            this.setState({ busy: false });

        }).catch((err) => {
            console.error(err);
            this.setState({ busy: false });
        });


    }




    // If you setState the crop in here you should return false.
    onImageLoad(e) {
        if (this.props.cropAspect!== null) {
            const { width, height } = e.currentTarget
            this.setState({ crop: (this.centerAspectCrop(width, height, this.props.cropAspect)) })
        }
    }

    imageSelected = (event) => {
        if (event.target.files.length === 0)
        {
            console.log("no file selected");
            return;
        }
        console.log("setting state");
        this.setState({ imageSrc: URL.createObjectURL(event.target.files[0]) });

        //set state cropOpen
        if (this.props.cropAspect) {
            console.log("use crop");
            this.setState({ cropOpen: true });
        }
        else {
            console.log("no crop");
            this.handleImageUpload(event.target.files[0],false);
        }
        event.target.value = '';
    }

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        crop.aspect = this.props.cropAspect;
        this.setState({ crop: crop });
    };

    cropCompleted = (crop, precentCrop) => {
        console.log("crop completed");
        this.makeClientCrop(crop);
        // console.log("crop completed");
        // console.log(crop);
        // console.log(precentCrop);
        // this.handleImageUpload(crop);
    }
    onCancelCrop = () => {
        this.ResetStates();
    }
    ResetStates = () => {
        this.setState({ busy: false,
            previewCanvasRef: React.createRef(),
            completedCrop: null,
            imageSrc: null,
            cropOpen: false,
            imageRef: React.createRef(),
            blobUrlRef: React.createRef(),
            crop: {
                unit: '%',
                width: 30,
                aspect: this.props.cropAspect,
            },
        });
    }

    async onDownloadCropClick() {
        const image = this.state.imageRef.current
        const previewCanvas = this.state.previewCanvasRef.current
        const completedCrop = this.state.completedCrop;
        const blobUrlRef = this.state.blobUrlRef;
        if(!image)
        {
            throw new Error('Image does not exist');
        }
        if(!previewCanvas || !completedCrop)
        {
            console.log("uploading image without crop");
            //get blob out of imageSrc
            let blob = await fetch(this.state.imageSrc).then(r => r.blob());

            this.handleImageUpload(blob,false);
            return;
        }
        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })
        let newblobRef = blobUrlRef;


        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)
        }
        newblobRef.current = URL.createObjectURL(blob)
        this.setState({ blobUrlRef: newblobRef });
    }


    handleDoneClicked = async () => {
        // await this.onDownloadCropClick()

        const image = this.state.imageRef.current
        const previewCanvas = this.state.previewCanvasRef.current
        const completedCrop = this.state.completedCrop;
        const blobUrlRef = this.state.blobUrlRef;
        if(!image)
        {
            throw new Error('Image does not exist');
        }
        if(!previewCanvas || !completedCrop)
        {
            console.log("uploading image without crop");
            //get blob out of imageSrc
            let blob = await fetch(this.state.imageSrc).then(r => r.blob());

            this.handleImageUpload(blob,false);
            this.setState({ cropOpen: false });

            return;
        }
        // This will size relative to the uploaded image
        // size. If you want to size according to what they
        // are looking at on screen, remove scaleX + scaleY
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )
        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            throw new Error('No 2d context')
        }

        ctx.drawImage(
            previewCanvas,
            0,
            0,
            previewCanvas.width,
            previewCanvas.height,
            0,
            0,
            offscreen.width,
            offscreen.height,
        )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })
        let newblobRef = blobUrlRef;


        if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current)
        }
        newblobRef.current = URL.createObjectURL(blob)
        this.setState({ blobUrlRef: newblobRef });

        console.log("timestamp2: " + Date.now());

        this.setState({ cropOpen: false });
        this.handleImageUpload(this.state.blobUrlRef.current,true);

    }

    renderCrop = () => {
        return (
            <Dialog open={this.state.cropOpen} onClose={() => { this.onCancelCrop() }}>
                <ReactCrop
                    crop={this.state.crop}
                    onChange={(_, percentCrop) => this.setState({ crop: percentCrop })}
                    onComplete={(c) => {this.setState({ completedCrop: c }); this.computePreview(); }}
                    
                    aspect={this.props.cropAspect}
                    // minWidth={400}
                    minHeight={200}
                    circularCrop={true}
                >
                    <img
                        ref={this.state.imageRef}
                        alt="Crop me"
                        src={this.state.imageSrc}
                        // style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={this.onImageLoad}
                    />
                </ReactCrop>
                <Box sx={{ my:'10px',  flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Button sx={{mx:'10px', flexGrow: 1}} variant="contained" onClick={this.onCancelCrop }>Cancel</Button>
                <Button sx={{mx:'10px', flexGrow: 1}} variant="contained" onClick={() => { this.handleDoneClicked() }}>Done</Button>
                </Box>
            </Dialog>
        );
    }
    computePreview = () => {
        if (
            this.state.completedCrop?.width &&
            this.state.completedCrop?.height &&
            this.state.imageRef.current &&
            this.state.previewCanvasRef.current
        ) {

            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
                this.state.imageRef.current,
                this.state.previewCanvasRef.current,
                this.state.completedCrop,
                1,
                0,
            )
        }
        else {
            console.log("no preview");
        }

    }
    centerAspectCrop(
        mediaWidth,
        mediaHeight,
        aspect,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }



    render() {
        // let src = this.props.src;
        let title = this.props.title;

        let width = this.props.width ?? '100%';
        let height = this.props.height ?? '100%';
        //set state
        let borderRadius = this.props.borderRadius ?? '0px';





        return (
            <Box sx={{ mt: '10px', mb: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                {this.state.cropOpen && this.state.completedCrop  ? (
                    <canvas
                        ref={this.state.previewCanvasRef}
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: width,
                            height: height,
                            borderRadius: borderRadius,
                            
                        }}
                    />
                ) : (

                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            height: height,
                            width: width,
                            borderRadius: borderRadius,
                        }}
                        alt={title + " thumbnail"}
                        src={this.state.url!== "" ? this.state.url : this.state.busy ? "" : placeholder}
                    />)}
                <Box sx={{ mt: '10px', mb: '10px', display: 'flex', flexDirection: 'column', }}>
                    {!this.state.busy ? (
                        <Box sx={{ mx: '10px', display: 'flex', alignItems: 'right' }} >
                            <label htmlFor={this.props.type}>
                                <input accept="image/*" id={this.props.type} name={this.props.type} type="file" style={{ display: 'none' }} onClick={() => { console.log("opening " + this.props.type + " image upload") }}
                                    onChange={this.imageSelected} />
                                <Fab color="primary" size="small" component="span" aria-label="add">
                                    <AddPhotoAlternateIcon />
                                </Fab>
                            </label>
                        </Box>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                            <CircularProgress />
                        </Box>
                    )}
                </Box>

                {this.renderCrop()}
                {!!this.state.completedCrop && (
                    <>
                        <div>

                        </div>
                        {/* <div>
                            <button onClick={this.onDownloadCropClick}>Download Crop</button>
                            <a
                                href="#hidden"
                                ref={hiddenAnchorRef}
                                download
                                style={{
                                    position: 'absolute',
                                    top: '-200vh',
                                    visibility: 'hidden',
                                }}
                            >
                                Hidden download
                            </a>
                        </div> */}
                    </>
                )}
            </Box>


        )
    }
}

export default ImageUpload;