import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IconButton, Box, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Stack, Item, Button, ThemeProvider, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

let mainService;
let dbRoot;
let id;

//TODO: make credits a numbered list with multilang support

// -> Credits
//      -> 0
//          -> roles
//              -> English : "role"
//          -> name

class Credits extends React.Component {
    constructor(props) {
        super(props);
        dbRoot = this.props.dbRoot;
        id = this.props.id;
        mainService = this.props.mainServiceRef;
        this.state = {
            editedRole: "",
            editedName: "",
            editingIndex: "",
            editingVal: "",
            credits: this.props.credits,
            selectedLanguage: "English",
            showUsernameSelection: false,
            usernameSelectionIndex: 0,
            selectionUsers: []
        }

    }

    // editRole = (val) => {
    //     this.setState({editedRole: val});
    // }
    setEditingIndex = (val) => {
        this.setState({ editingIndex: val });
    }

    handleEditRole = (index, val) => {
        let lang = this.props.language ?? this.state.selectedLanguage;
        mainService.setCreditsProperty(dbRoot, id, index, "roles/" + lang, val);
    }
    handleEditName = (name, index, force = false) => {
        //check if name starts with @
        if (!force && name.startsWith("@") && name.length > 2) {
            //perform search
            mainService.searchUsers(name.substring(1), "username").then((result) => {
                if (result.length > 0) {
                    console.log("Found users " + JSON.stringify(result));
                    this.setState({ showUsernameSelection: true });
                    this.setState({ usernameSelectionIndex: this.state.editingIndex });
                    this.setState({ selectionUsers: result })
                }
                else {
                    this.setState({ showUsernameSelection: false });
                }
            });


        }
        else
            this.setState({ showUsernameSelection: false });



        mainService.setCreditsProperty(dbRoot, id, index, "name", name);


    }

    handldeRemove = (index) => {
        mainService.removeCredits(dbRoot, id, index);
    }

    handleAddCredits = () => {
        console.log("Add Credits " + dbRoot + " " + this.props.id + " " + (this.props.credits ? this.props.credits.length : 0));
        mainService.addCredits(dbRoot, this.props.id, this.props.credits ? this.props.credits.length : 0, "role", "name");
    }

    handleMoveUp = (index) => {
        if ((index - 1) >= 0) {
            mainService.moveCredits(dbRoot, id, index, index - 1);

        }
    }
    handleMoveDown = (index) => {
        if ((index + 1) < this.props.credits.length) {
            mainService.moveCredits(dbRoot, id, index, index + 1);

        }
    }



    render() {
        return (
            <Box sx={{ position: 'relative', alignItems: 'stretch', flexShrink: 1, flexGrow: 1, display: 'flex' }} >
                <Tooltip title="Use @ to link to user">
                    <IconButton sx={{
                        position: 'absolute',
                        top: '0%', // Starts right below the search field
                        right: '0%',
                        width: 'auto', // Match the width of the search field
                        zIndex: 10
                    }}><InfoIcon /></IconButton>
                </Tooltip>

                <Box sx={{ display: "flex", flexDirection: 'column', m: '10px', alignItems: 'stretch', flexGrow: 1, flexShrink: 1, maxWidth: '900px', justifyItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: "flex", flexDirection: 'row', m: '10px', alignItems: 'stretch', flexGrow: 1, flexShrink: 1, justifyItems: 'center' }}>

                        <Typography sx={{ mr: '20px' }}>Credits</Typography>
                        <AddIcon onClick={() => this.handleAddCredits()} />
                        {!this.props.language && (
                            <FormControl sx={{ ml: '20px' }}>
                                <InputLabel id="select">Language </InputLabel>
                                <Select labelId="select" label="Language" value={this.state.selectedLanguage} onChange={(e) => this.setState({ selectedLanguage: e.target.value })}>
                                    <MenuItem value={"English"}>English</MenuItem>
                                    <MenuItem value={"Swedish"}>Swedish</MenuItem>
                                </Select>

                            </FormControl>)}

                    </Box>



                    <Box sx={{ display: "flex", flexDirection: 'column', m: '10px', alignItems: 'stretch',  width: '100%'}}>
                        {this.props.credits && this.props.credits.map((credit, index) => (
                            <Box key={index} sx={{ m: '10px', flexDirection: 'column'}} >
                                <Box sx={{ flexDirection: 'row',  width: '100%', alignItems: 'stretch', display:'flex'  }} >

                                    <TextField sx={{flexGrow: 1, flexShrink:1 }} id="role" label="Role" variant="outlined"
                                        onFocus={() => { this.setEditingIndex(index); }}
                                        onBlur={() => { this.setEditingIndex(undefined); }}
                                        value={credit.roles[this.props.language ?? this.state.selectedLanguage] ?? ""}
                                        // value={this.state.editedRole}
                                        onChange={(e) => this.handleEditRole(index, e.target.value)} />


                                    <TextField sx={{ ml: "10px", flexGrow: 1, flexShrink:1 }} id="name" label="Name" variant="outlined"
                                        onFocus={() => { this.setState({ editingVal: index }); this.setState({ editedName: credit.name }) }}
                                        onBlur={() => { this.setState({ editingVal: undefined }); this.setState({ editedName: "" }) }}
                                        value={credit.name ?? ""}
                                        onChange={(e) => this.handleEditName(e.target.value, index)} />

                                    <IconButton sx={{ ml: '15px' }} onClick={(e) => this.handleMoveUp(index)}>
                                        <ArrowDropUpIcon />
                                    </IconButton>
                                    <IconButton  >
                                        <ArrowDropDownIcon onClick={(e) => this.handleMoveDown(index)} />
                                    </IconButton>
                                    <IconButton sx={{ ml: '15px' }} onClick={(e) => { this.handldeRemove(index) }}>
                                        <DeleteForeverIcon />
                                    </IconButton>

                                </Box>
                                {this.state.showUsernameSelection && this.state.usernameSelectionIndex === index &&
                                    <Stack direction="row" spacing={2}>
                                        {this.state.showUsernameSelection && this.state.usernameSelectionIndex === index && this.state.selectionUsers.map((user, index) => (

                                            <Button variant="contained" sx={{ textTransform: 'none' }} onClick={() => {
                                                this.setState({ showUsernameSelection: false });
                                                this.setState({ editingVal: index });
                                                this.handleEditName("@" + user.username, index, true);
                                            }}>{"@" + user.username}</Button>

                                        ))}


                                    </Stack>}


                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        );
    }
}


export default Credits;