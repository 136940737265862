import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import MainContext from "../MainContext";
import { IconButton, FormControl, InputLabel, Fab, ToggleButton, Tooltip, Checkbox, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UserSearchField from "../components/UserSearchField";
import ExperienceSearchField from "../components/ExperienceSearchField";
import TopBar from "../components/TopBar";


const MasterControls = () => {
    const mainService = useContext(MainContext);
    const navigate = useNavigate();

    const [loadingMode, setLoadingMode] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [authEmails, setAuthEmails] = useState([]);
    const [featuredExperiences, setFeaturedExperiences] = useState([]);
    const [publicUsers, setPublicUsers] = useState({});
    const [newAuthEmail, setNewAuthEmail] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedExperience, setSelectedExperience] = useState(null);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    useEffect(() => {

        //return if not master user
        const uid = mainService.Uid();


        console.log('MasterControls mounted');

        mainService.GetAdminRole().then((role) => {
            if (role!== 'master') {
                console.log('not master. role: ' + role);
                navigate('/');
                return;
            }
            subscribeListeners();
            setLoadingMode(false);
            console.log('master');
        }).catch((error) => {
            console.log(error);
            navigate('/');
            return;
        });


        return () => {
            console.log('MasterControls unmounted');
            unsubscribeListeners();
        }


    }, []);

    const subscribeListeners = () => {
        mainService.addDataListener("authorizedEmails", handleAuthEmailsChanged);
        mainService.addDataListener("publicUsers", handlePublicUsersChanged);
        mainService.addDataListener("featuredExperiences", handleFeaturedExperiencesChanged);
    }

    const unsubscribeListeners = () => {
        mainService.removeDataListener("authorizedEmails", handleAuthEmailsChanged);
        mainService.removeDataListener("publicUsers", handlePublicUsersChanged);
        mainService.removeDataListener("featuredExperiences", handleFeaturedExperiencesChanged);
    }

    const handleAuthEmailsChanged = (data) => {
        console.log('authEmails changed');
        let newAuthEmails = [];
        for (let email in data) {
            let e = email.replaceAll('%2E', '.');
            newAuthEmails.push(e);
        }
        //sort alphabetically
        newAuthEmails = newAuthEmails.sort((a, b) => {
            return a.localeCompare(b);
        });
        setAuthEmails(newAuthEmails);
    }

    const handlePublicUsersChanged = (data) => {
        console.log('publicUsers changed');
        setPublicUsers(data);
    }

    const handleFeaturedExperiencesChanged = (data) => {
        console.log('featuredExperiences changed');
        setFeaturedExperiences(data);
    }

    const handleAddPublicUser = () => {
        if(!selectedUser)
        {
            alert("Please select a user");
            return;
        }
        mainService.addPublicUser(selectedUser);
    }

    const handleAddFeaturedExperiences = () => {
        if(!selectedExperience)
        {
            alert("Please select an experience");
            return;
        }
        mainService.addFeaturedExperience(selectedExperience);
    }
    
    const geofireCleanup = () => {
        setLoadingMode(true);
        mainService.geofireCleanup().then(() => {
            alert("Done");
            setLoadingMode(false);
        }
        ).catch((error) => {
            alert(error);
        });
    }



    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }


    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }


    //#region Util
    const handleComputeAltitudeForAllLocations = () => {
        console.log('computing altitude for all locations: ');
        setLoadingMode(true);
        mainService.computeAltitudeForAllLocations()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const copyAllEmailsToAuthorized = () => {
        console.log('copying all emails to authorized: ');
        setLoadingMode(true);
        mainService.copyAllEmailsToAuthorized();
    }

    const addExperienceRefsToOwners = () => {
        console.log('adding experience refs to owners: ');
        setLoadingMode(true);
        mainService.addExperienceRefsToOwners()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const copyUsersToTypesense = () => {
        console.log('copying users to typesense: ');
        setLoadingMode(true);
        mainService.copyUsersToTypesense()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const copyExperiencesToTypesense = () => {
        console.log('copying experiences to typesense: ');
        setLoadingMode(true);
        mainService.copyExperiencesToTypesense()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const clearRemovedExperiences = () => {
        console.log('clearing removed experiences: ');
        setLoadingMode(true);
        mainService.clearRemovedExperiences()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const validateDBCollaborators = () => {
        mainService.validateDBCollaborators();
    }
    const createVisionOSReferences = () => {
        console.log('creating vision os refrences ');
        setLoadingMode(true);
        mainService.createVisionOSReferences()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const updateUserDisplayNames = () => {
        console.log('updating user display names ');
        setLoadingMode(true);
        mainService.updateUserDisplayNames()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const setAllOwnerUsernames = () => {
        console.log('updating user display names ');
        setLoadingMode(true);
        mainService.setAllOwnerUsernames()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    
    

    const setAllExperienceOwners = () => {
        console.log('setting all experience owners: ');
        setLoadingMode(true);
        mainService.setAllExperienceOwners()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }


    const addOrganisationRefToArtworkLocations = () => {
        console.log('adding organisation ref to artwork locations ');
        setLoadingMode(true);
        mainService.addOrganisationReferenceForAllArtowrkLocations()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const removeAllNonOrgLocations = () => {
        console.log('removing all non org locations ');
        setLoadingMode(true);
        mainService.removeAllNonOrgLocations()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const copyWordCloud = () => {
        console.log('copying word cloud ');
        setLoadingMode(true);
        mainService.copyArtworkRealtimeDataToTest("-NNbuE41It8tzr5GAq0n", "-NOyegAjapScfOloGJB2")
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const countWCMessages = () => {
        mainService.countChlidren("realtimeData/-NNbuE41It8tzr5GAq0n/global/all/messages");
    }
    const CopyAJ = () => {
        console.log('copying AJ ');
        mainService.CopyAJ();
    }

    const convertAllStringLocationsToNumbers = () => {
        console.log('converting all string locations to numbers ');
        setLoadingMode(true);
        mainService.convertAllStringLocationsToNumbers()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const getAllMissingLocationArtworkRelationships = () => {
        console.log('getting all missing location artwork relationships ');
        setLoadingMode(true);
        mainService.getAllMissingLocationArtworkRelationships()
            .then(() => {
                setLoadingMode(false);
            }
            )
            .catch(() => {
                setLoadingMode(false);
            }
            );
    }

    const deleteAllBundleRefs = () => {
        console.log('deleting all bundle refs ');
        mainService.deleteArtworksBundleReferences();
    }
    //#endregion


    //Render main info
    const renderGeneral = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={handleComputeAltitudeForAllLocations}>Compute altitude fro all locations</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={addOrganisationRefToArtworkLocations}>Add Org ref to all locations</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={removeAllNonOrgLocations}>removeAllNonOrgLocations</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={deleteAllBundleRefs}>deleteAllBundleRefs</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={CopyAJ}>Copy AJ JSON</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={copyWordCloud}>CopyWordCloud</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={countWCMessages}>Count Messages</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={convertAllStringLocationsToNumbers}>Convert All String Locations</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={getAllMissingLocationArtworkRelationships}>Add missing location artwork relationships</Button> */}
                    {/* <Button variant="contained" sx={{ m: '10px' }} onClick={copyAllEmailsToAuthorized}>Copy emails</Button> */}
                    <Button variant="contained" sx={{ m: '10px' }} onClick={addExperienceRefsToOwners}>Add all experience refererences to owners</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={copyUsersToTypesense}>Copy users to typesense</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={copyExperiencesToTypesense}>Copy Experiences to typesense</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={setAllExperienceOwners}>Set all experience owners</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={geofireCleanup}>Clean GeoFire entries</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={clearRemovedExperiences}>Clear Removed Experiences</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={validateDBCollaborators}>Validate DB Collaborators</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={createVisionOSReferences}>Create VisionOS references</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={updateUserDisplayNames}>Update User Display Names</Button>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={setAllOwnerUsernames}>Set All Owner Usernames</Button>

                </Box>
            </Box>
        )
    }

    const renderAuthEmails = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Typography sx={{ mx: '16px' }}>Emails authorized to sign up</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                    <TextField sx={{ m: "10px", minWidth:'300px' }} label="email" value={newAuthEmail} onChange={(e) => setNewAuthEmail(e.target.value)} />
                    <Button variant="contained" sx={{ m: '10px' }} onClick={() => { mainService.addAuthEmail(newAuthEmail); setNewAuthEmail(""); }}>Add</Button>
                    </Box>

                    {authEmails.map((email) => 
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                                <Typography sx={{ mx: '16px' }}>{email}</Typography>
                                <DeleteForeverIcon onClick={(e) => {
                                    startYNDialogAction(e, "Remove " + email + " from authorized emails?", (e) => {
                                        mainService.removeAuthEmail(email);
                                    })
                                }} />
                            </Box>
                    )}
                        
                    
                </Box>
            </Box>
        )
    }

    const renderPublicUsers = () => {


        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Typography sx={{ mx: '16px' }}>Public User Profiles</Typography>
                    <Box sx={{ display: 'flex', maxHeight:"100px", flexDirection: 'row',  borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                    <UserSearchField sx={{display:'block'}} mainService={mainService} onUserSelected={setSelectedUser}/>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={handleAddPublicUser}>Add</Button>
                    </Box>
                    {publicUsers && Object.keys(publicUsers).map((userId) => 
                            <Box key={userId} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                                <Typography sx={{ mx: '16px' }}>{publicUsers[userId].username} ({publicUsers[userId].name})</Typography>
                                <DeleteForeverIcon onClick={(e) => {
                                    startYNDialogAction(e, "Remove " + publicUsers[userId].username + " from public users?", (e) => {
                                        mainService.removePublicUser(userId);
                                    })
                                }} />
                            </Box>
                    )}
                </Box>
            </Box>
        )
    }

    //tab where featured experiences can be added
    const renderFeaturedExperiences = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Typography sx={{ mx: '16px' }}>Featured Experiences</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                    <ExperienceSearchField sx={{display:'block'}} mainService={mainService} onExperienceSelected={setSelectedExperience}/>
                    <Button variant="contained" sx={{ m: '10px' }} onClick={handleAddFeaturedExperiences}>Add</Button>
                    </Box>

                    {featuredExperiences && Object.keys(featuredExperiences).map((expid) =>
                        <Box key={expid} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid rgba(255, 255, 255, 0.5)', py: '8px' }}>
                            <Typography sx={{ mx: '16px' }}>{featuredExperiences[expid].title} ( {featuredExperiences[expid].author} )</Typography>
                            <DeleteForeverIcon onClick={(e) => {
                                startYNDialogAction(e, "Remove " + featuredExperiences[expid].title + " from featured experiences?", (e) => {
                                    mainService.removeFeaturedExperience(expid);
                                })
                            }} />
                        </Box>
                    )}
                        
                    
                </Box>
            </Box>
        )

    }







    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                <TopBar title={'Master Controls'} backNavigate={'/'}/>

                    {/* <Box sx={{ display: 'flex', alignItems: 'center', px: '10px', height: '60px', minHeight: '60px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
                        <IconButton onClick={() => navigate('/', { tabValue: 1 })}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ ml: '8px' }}>
                            Master Controls
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </Box> */}

                    <Box sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                            <Tab label="General" />
                            <Tab label="Auth Emails" />
                            <Tab label="Public Users" />
                            <Tab label="Featured Experiences" />

                        </Tabs>
                    </Box>


                    {tabValue === 0 ?
                        renderGeneral()
                        : tabValue === 1 ?
                            renderAuthEmails()
                            : tabValue === 2 ?
                                renderPublicUsers()
                                : tabValue === 3 ?
                                    renderFeaturedExperiences()
                                : (null)}
                    {renderYNDialog()}


                    {renderYNDialog()}
                </Box>
            )}
        </ThemeProvider>
    );
}


export default MasterControls; 