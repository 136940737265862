import React, {useState, useEffect, useContext } from "react";
import { useFetcher, useNavigate } from "react-router-dom";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import theme from '../theme';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function SignUp() {
    //Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 

	//State
	const [signUpButtonDisabled, setSignUpButtonDisabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    //Callbacks
    const handleSubmit = (event) => {
        event.preventDefault();
        setSignUpButtonDisabled(true); 

        const data = new FormData(event.currentTarget);
        let obj = {
            email: data.get('email'),
            password: data.get('password'),
            firstName: data.get('firstName'),
            lastName: data.get('lastName'),
            username: data.get('username'),
            agree: data.get('agree') === 'agree',
        };

        console.log(obj); 
        if(!obj.agree){
            setSignUpButtonDisabled(false); 
            setErrorMessage("Failed to sign up: You must agree to the terms");
            return;
        }
        mainService.ValidateSignUpData(obj.email, obj.password, obj.password, obj.firstName, obj.lastName, obj.username)
        .then((result)=>{
            console.log("res " + JSON.stringify(result));
            if(!result.emailAuthorized)
            {
                setSignUpButtonDisabled(false); 
                setErrorMessage("Failed to sign up: Email is not authorized");
                return;
            }

            let allTrue = result.emailValid && result.passwordValid && result.firstNameValid && result.lastNameValid && result.usernameValid;

            let emailmsg = "Email is not valid.";
            let passwordmsg = "Password is not valid.";
            let firstNamemsg = "First name is not valid.";
            let lastNamemsg = "Last name is not valid.";
            let usernamemsg = result.usernameMsg;
            if(!allTrue)
            {
                let msg = "";
                if(!result.emailValid)
                    msg += emailmsg + " ";
                if(!result.passwordValid)
                    msg += passwordmsg + " ";
                if(!result.firstNameValid)
                    msg += firstNamemsg + " ";
                if(!result.lastNameValid)
                    msg += lastNamemsg + " ";
                if(!result.usernameValid)
                    msg += usernamemsg + " ";
                setSignUpButtonDisabled(false);
                setErrorMessage("Failed to sign up: " + msg);
                return;
            }
            
            mainService.SignUpUser(obj.email, obj.password, obj.password, obj.firstName, obj.lastName, obj.username)
            .then(()=>{
                navigate('/verifyemail'); 
                setSignUpButtonDisabled(false); 
            })
            .catch((error)=>{
                console.log("error message:" + error.message); 
                setErrorMessage("Failed to sign up: " + error.message);
                setSignUpButtonDisabled(false); 
            }); 
        });
        
    };

    useEffect(() => {
        console.log('SignUp mounted');
        mainService.signInAnon();
        return () => {
            console.log('SignUp unmounted');
        }
    });

    return (
    <ThemeProvider theme={theme}>
        <Typography sx={{m:'64px'}} align='center' variant='h4'>Meadow Console</Typography>
        <Container component="main" maxWidth="xs">
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign up
                </Typography>
                <Typography sx={{mt:'32px', color:'rgb(255, 50, 50)'}}>{errorMessage}</Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="username"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="family-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' sx={{my:'10px'}}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character. For example Password1! (do not use this).</Typography>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox value="agree" color="primary" />}
                                label="I agree to the terms and conditions."
                                name="agree"
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={signUpButtonDisabled}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/signin" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    </ThemeProvider>
    );
}