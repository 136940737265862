import React, { useContext } from "react";


import { IconButton, Box, TextField, Typography, FormControl, InputLabel, Select, MenuItem, Stack, Item, Button, ThemeProvider } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import { ListItemButton, List } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';

// for other devs who might not know keyCodes
var ESCAPE_KEY = 27;

class UserSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showList: false,
            searchValue: '',
            searchResults: [],
            selectedUser: null
        }
    }
    // componentWillMount deprecated in React 16.3
    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }


    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }


    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case ESCAPE_KEY:
                this.setState({ showList: false });
                break;
            default:
                break;
        }
    }

    handleSelect = (id) => {
        let u = this.state.searchResults.find((element) => {
            return element.id === id;
        });
        this.setState({
            showList: false,
            selectedUser: u,
            searchValue: u.username

        });

        if (this.props.onUserSelected) {
            this.props.onUserSelected(u);
        }


        if (this.props.open)
            this.props.onSelected(id);
    }
    handleSearchFieldChange = (e) => {
        let val = e.target.value;
        this.setState({ searchValue: val });
        if (this.props.onUserSelected) {
            this.props.onUserSelected(null);
        }
        if (val.length > 1) {
            this.props.mainService.searchUsers(val, "username,name").then((result) => {
                if (result) {
                    let newResults = [];
                    for (let i = 0; i < result.length; i++) {
                        if (!result[i].username || result[i].username === "not_set")
                            continue;

                        let data = {
                            id: result[i]._id,
                            name: result[i].name,
                            username: this.props.mainService.decodeUsername(result[i].username)
                        }
                        newResults.push(data);
                    }
                    console.log("newResults: " + JSON.stringify(newResults));
                    this.setState({ searchResults: newResults });
                    if (newResults.length > 0)
                        this.setState({ showList: true });
                    else
                        this.setState({ showList: false });
                }
            });
        }
        else {
            this.setState({ searchResults: [] });
        }
    }


    render() {
                // Container for the search field
                const searchFieldContainerStyle = {
                    position: 'relative', // This is important for absolute positioning of children
                    alignItems: 'stretch',
                    flexShrink: 1, 
                    flexGrow: 1,
                    display: 'flex',
                };
        
                // Container for the search results
                const resultsContainerStyle = {
                    position: 'absolute',
                    top: '100%', // Starts right below the search field
                    width: '100%', // Match the width of the search field
                    zIndex: 10, 
                    maxHeight: '300px', 
                    overflowY: 'auto',
                    bgcolor: 'background.paper'
                };
                
        return (
            <Box sx={searchFieldContainerStyle}>
                <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                    value={this.state.searchValue} onChange={this.handleSearchFieldChange} />
                {this.state.showList === true && this.state.searchResults && this.state.searchResults.length > 0 && (
                    <Box sx={resultsContainerStyle}>
                    <List >
                        {this.state.searchResults.map((result) => (
                            <ListItemButton key={result.id} onClick={() => { this.handleSelect(result.id) }}>
                                <ListItemText primary={result.username} secondary={result.name} />
                            </ListItemButton>
                        ))}
                    </List>
                    </Box>

                )}
            </Box>
        )
    }
}

export default UserSearchField;