import React, { useEffect } from 'react';

import { IconButton, Box,Button, Tooltip, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircularProgress from '@mui/material/CircularProgress';



class BundleUpload extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            bundle: null,
            bundleMetadatas: this.props.bundleMetadatas,
                   
        }
    }


    render() {
        let bundleMetadatas = this.props.bundleMetadatas;
        let bundleTypeFinal = this.props.bundleType ? this.props.bundleType : '';
        let platform = this.props.platform;
        let hasBundle = this.props.hasBundle;
        let timeCreated, updated, size;


        if (bundleMetadatas[platform + (this.props.bundleType ? '_' + bundleTypeFinal : '')]) {
            let tc = new Date(bundleMetadatas[platform + (this.props.bundleType ? '_' + bundleTypeFinal : '')].timeCreated);
            timeCreated = tc.getDate() + '/' + tc.getMonth() + ' ' + tc.getHours() + ':' + tc.getMinutes();
            let u = new Date(bundleMetadatas[platform + (this.props.bundleType ? '_' + bundleTypeFinal : '')].updated);
            updated = u.getDate() + '/' + u.getMonth() + ' ' + u.getHours() + ':' + u.getMinutes();

            size = bundleMetadatas[platform + (this.props.bundleType ? '_' + bundleTypeFinal : '')].size;

        }
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', flexShrink:1, flexGrow:1, justifyContent:'center', my:'3px'}}>
                {!this.props.isUploading ? (
                    <Box sx={{ mx: '10px', display: 'flex', alignItems: 'center' }} >
                        <label htmlFor={"contained-button-file" + platform + bundleTypeFinal}>
                            <input accept="*" id={"contained-button-file" + platform + bundleTypeFinal} type="file" style={{ display: 'none' }} onChange={(e) =>{
                                e.platform = platform; e.bundleType = bundleTypeFinal;
                                this.props.onUploadClick(e);
                                
                                }}         
                                onClick={(event)=> { 
                                    event.target.value = null
                               }}/>
                            <Button variant="outlined" component="span">
                                Upload {bundleTypeFinal === "Metabundle" ? "Map Marker" : bundleTypeFinal} {platform} Bundle
                            </Button>
                        </label>
                        {hasBundle.has(platform) && !bundleTypeFinal || hasBundle.has(platform + '_' + bundleTypeFinal) && bundleTypeFinal ? (
                            <React.Fragment>
                                <Typography sx={{ mx: '16px' }}>Bundle file uploaded</Typography>
                                <Tooltip title={"timeCreated: " + timeCreated + "; updated: " + updated + "; size: " + size / 1000000 + 'Mb'}>
                                    {/* <Tooltip title={JSON.stringify(bundleMetadatas[platform + (props.bundleType ? '_' + bundleTypeFinal :'')])   }> */}
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                                <IconButton sx={{mb:'10px'}} onClick={(e) => { e.platform = platform; e.bundleType = bundleTypeFinal; 
                                    this.props.onDeleteClick(e);
                                    // startYNDialogAction(e, "Are you sure you want to delete the bundle?", this.handleBundleDelete); 
                                    }}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            </React.Fragment>
                        ) : (null)}
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        )
    }
}

export default BundleUpload;