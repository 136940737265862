import React, { useEffect } from 'react';

import Box from '@mui/material/Box';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, FormControl, InputLabel, Fab, ToggleButton, Tooltip, Checkbox, TextField, ListItemButton } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

class SearchDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            searchResults: []
        }
    }

    handleSearch = async () => {
        console.log("Search for: " + this.state.searchValue);
    }
    handleSelect = (id) => {
        if (this.props.open)
            this.props.onSelected(id);
    }


    render() {
        return (
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={this.props.open}
                onClose={() => { this.props.onClose() }}

            >
                <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                    value={this.state.searchValue} onChange={(e) => this.setState({ searchValue: e.target.value })} onKeyDown={(e) => { if (e.key === 'Enter') this.handleSearch() }}
                    InputProps={{ endAdornment: <IconButton onClick={() => this.handleSearch()}><SearchIcon /></IconButton> }} />
                <List sx={{ width: '100%', maxWidth: '900px', bgcolor: 'background.paper' }}>
                    {this.props.searchResults.map((result) => (
                        <ListItemButton key={result.id} onClick={() => { this.handleSelect(result.id) }}>
                            <ListItemText primary={result.titles ? result.titles["English"] :result.name ?? ""} secondary={result.artist} />
                        </ListItemButton>
                    ))}
                </List>
            </Dialog>


        )
    }

}

export default SearchDialog;