import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const Guide = () => {

    useEffect(() => {

        console.log('App guide mounted');

        return () => {
            console.log('App guide unmounted');
        }
    }, []);

    //Main
    return (
        <ThemeProvider theme={theme}>

<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            px: '16px'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              px: '10px',
              height: '60px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
              width: '100%',
              maxWidth: '700px'
            }}>
              <Typography>
                <Link to={'/'} style={{ color: 'white' }}>Meadow Console</Link>
              </Typography>
            </Box>
      
            <Box sx={{
              maxWidth: '700px',
              width: '100%',
              flexGrow: 1,
              mx: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              pt: '32px'
            }}>
                    <Box sx={{ maxWidth: '700px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                        <Typography sx={{ my: '16px' }} variant='h4'>Guide till appen Meadow</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Hej! Detta är en guide till hur ni testar appen Meadow. Appen Meadow är den app som utställningen upplevs igenom, och som besökarna använder för att se konsten.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Appen är inte färdig, men en första version finns publicerad på AppStore och Google Play. Ni hittar den genom att söka på "Meadow".</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Appen är lösenordsskyddad iom att utställningen inte är öppen än.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Lösenordet är "member".</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant='body'><a href='https://apps.apple.com/us/app/v%C3%A4vda-rum/id1633434387' style={{ color: 'white' }} target="_blank">Länk till appen för iPhone.</a></Typography>
                        <Typography sx={{ my: '16px' }} variant='body'><a href='https://play.google.com/store/apps/details?id=com.untoldgarden.wovenplaces' style={{ color: 'white' }} target="_blank">Länk till appen för Android.</a></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">
                            <strong>Viktigt att tänka på:</strong>
                        </Typography>
                        <ul>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Denna version av appen är inte klar, utan är till för att ni ska kunna testa placeringarna. Det kan uppstå problem och märkligheter.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Ni får gärna rapportera problem till oss på support@untold.garden. Beskriv problemet, vad som hände innan det uppstod, samt vilken modell er telefon har.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Ifall det är något som inte fungerar, starta om appen. Se till att ni stänger av appen ordentligt innan ni startar den igen.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Verken är inte klara, utan är prototyper i olika stadie av färdighetsgrad. De färdigställda verken kommer att vara mycket mer interaktiva och intressanta.</Typography>
                            </li>
                        </ul>
                        <Typography sx={{ my: '16px' }} variant="body">Appen som ligger publikt uppdaterar vi bara ca en gång varranan vecka. Om ni vill testa de allra senaste funktionerna kan ni istället ladda ner vår test-app, som uppdateras dagligen.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Här följer instruktioner om hur ni laddar ner test-appen:</Typography>
                        <Typography sx={{ my: '16px' }} variant='h4' id="section-1">Android</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'><a href='https://play.google.com/store/apps/details?id=com.untoldgarden.wovenplaces' style={{ color: 'white' }} target="_blank">Klicka här för att ladda ner test-appen till Android.</a></Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Du kan även läsa av QR-koden nedan med din telefon.</Typography>
                        <img src="guide-images/qr.webp" alt="QR-kod" width="50%" height="50%" />
                        <Typography sx={{ my: '16px' }} variant='body'>Tryck på Install, och sen på Open när den är klar.</Typography>
                        <img src="guide-images/android.webp" alt="Google Play" width="50%" height="50%" />
                        <Typography sx={{ my: '16px' }} variant='body'>Godkänn tillgång till positionsdata och kamera när appen frågar.</Typography>

                        <Typography sx={{ my: '16px' }} variant='h4' id="section-1">iPhone</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>För iPhone använder vi oss av TestFlight, vilket är Apples app för att testa appar innan de är färdigställda.</Typography>
                        <Link to={'/'} style={{ color: 'white' }}>Klicka här för att ladda ner TestFlight.</Link>
                        <img src="guide-images/testflight.webp" alt="Testflight"/>
                        <Typography sx={{ my: '16px' }} variant='body'><a href='https://testflight.apple.com/join/yHCAalgk' style={{ color: 'white' }} target="_blank">När du väl har TestFlight installerat, så tryck på denna länken för att ladda ner Meadow.</a></Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Du kan även läsa av QR-koden nedan med din telefon.</Typography>
                        <img src="guide-images/qr.webp" alt="QR-kod" width="50%" height="50%" />
                        <Typography sx={{ my: '16px' }} variant='body'>Tryck på Tacka Ja, och sen på installera.</Typography>
                        <img src="guide-images/testflight2.webp" alt="Testflight"/>
                        <Typography sx={{ my: '16px' }} variant='body'>Tryck sen på "Öppna", sen "Nästa", och sen "Börja testa", för att öppna appen.</Typography>
                        <img src="guide-images/testflight3.webp" alt="Google Play" />
                        <Typography sx={{ my: '16px' }} variant='body'>Godkänn tillgång till positionsdata och kamera när appen frågar.</Typography>

                        <Typography sx={{ my: '16px' }} variant='h4' id="section-1">Inne i Meadow</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>När du väll har laddat ner test-appen och öppnar den så ser du en karta över ditt närområde.</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Konstverken visas som symboler på kartan. Vissa verk har olika symboler, och vissa representeras av en röd nål.</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>När du kommer nära ett verk så laddas det ner, och öppnas automatiskt.</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>När verket har öppnats trycker du på "Start the experience" för att gå in i verket och se det i augmented reality.</Typography>
                        <img src="guide-images/vavdarum.webp" alt="Google Play" />

                        <Typography sx={{ my: '16px' }} variant="body">
                            <strong>Buggar att vara medveten om:</strong>
                        </Typography>
                        <ul>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">När man lämnar ett verk så blir det ibland problem att gå in i ett nytt verk. Starta om appen isåfall!</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Eric Magassas verk crashar på vissa äldre telefoner.</Typography>
                            </li>
                        </ul>
                        <Typography sx={{ my: '16px' }} variant='body'>Som sagt så är detta en test-app, som inte är färdig. Appen kommer att se helt annorlunda ut och fungera mycket bättre när den är färdig.</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Visa inte test-appen för er publik! Det är en bättre upplevelse om de får se verken när de är färdiga.</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Kontakta Jakob eller Linn ifall ni har några frågor.</Typography>

                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default Guide; 