import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const Guide = () => {

    useEffect(() => {

        console.log('Guide mounted');

        return () => {
            console.log('Guide unmounted');
        }
    }, []);

    //Main
    return (
        <ThemeProvider theme={theme}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            px: '16px'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              px: '10px',
              height: '60px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
              width: '100%',
              maxWidth: '700px'
            }}>
              <Typography>
                <Link to={'/'} style={{ color: 'white' }}>Meadow Console</Link>
              </Typography>
            </Box>
      
            <Box sx={{
              maxWidth: '700px',
              width: '100%',
              flexGrow: 1,
              mx: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              pt: '32px'
            }}>
              <Typography sx={{ my: '16px' }} variant='h4'>Guide till appen Meadow</Typography>
                        <Typography sx={{ my: '16px' }} variant='body'>Hej! Denna sidan visar en guide för adminportalen. Om ni vill hitta en guide för hur ni testar själva appen Meadow, alltså den där utställningen finns och som besökarna ser, så klicka på länken nedan:</Typography>
                        <Link to={'/appguide'} style={{ color: 'white' }}>Tryck här för att komma till guiden för appen Meadow.</Link>
                        <Typography sx={{ my: '16px' }} variant='body'>Om du vill läsa mer om verken och plaststyperna så tryck på länken nedan:</Typography>
                        <Link to={'/artworklocations'} style={{ color: 'white' }}>Tryck här för att komma till en lista över verken och platstyperna.</Link>
                        <Typography sx={{ my: '16px' }} variant='h4'>Guide till adminportalen</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Hej! Detta är en guide till hur ni använder Meadows adminportal. Portalen är utställningens “baksida”, backstage ungefär, som vi har utvecklat för att ni enkelt ska kunna lägga in information om er konstförening, samt det viktigaste: placera ut konstverken i ert område.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Besökarna till utställningen ser aldrig adminportalen, det är endast ni som ser den. Däremot visas viss information som ni lägger upp i adminportalen för besökarna till utställningen, till exempel texten om er förening.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">
                            <strong>Viktigt att tänka på:</strong>
                        </Typography>
                        <ul>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Adminportalen är alltså INTE Meadow. Besökarna till utställningen upplever den genom en app i sina mobiltelefoner, som heter Meadow. I denna guiden refererar vi till den appen som “Meadow”. Det är endast ni i föreningarna som ser adminportalen. </Typography>
                                <Link to={'/appguide'} style={{ color: 'white' }}>Tryck här för att komma till guiden för appen Meadow, alltså det som besökarna ser.</Link>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Verken i Meadow placeras med några meters felmarginal, så var så noga som möjligt när ni placerar ut verken på kartan. Det är inte alltid helt lätt att se på kartans satelitbild, men gör så gott det går.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">All information ni lägger in i plattformen sparas automatiskt, och kan uppdateras av er när ni vill.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Lägg gärna in en text om varför ni valde just den placeringen för verken. Detta förklaras i <a href="#section-46" style={{ color: 'white' }}>punkt 4.6.</a></Typography>
                            </li>
                        </ul>
                        <Typography sx={{ my: '16px' }} variant='h4' id="section-1">1. Logga in på Adminportalen</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Gå till <u><a href="https://vavdarum.web.app" style={{ color: 'white' }} target="_blank">https://vavdarum.web.app</a></u> eller <Link to={'/'} style={{ color: 'white' }} target="_blank">tryck här.</Link></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Log-in fönstret ser ut såhär: </Typography>
                        <img src="guide-images/login.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Om ni redan har skapat ett konto, skriv in er mail och lösenord och tryck SIGN IN.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><i>Tips: Ni kan klicka i “Remember me” för att inte behöva logga in igen nästa gång.</i></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Om ni redan har skapat konto kan ni gå direkt till punkt 1.3.</Typography>
                        <Typography sx={{ my: '16px' }} variant='h5'>1.2 Skapa Konto</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>1.2.1 Tryck på “Sign up” nere till höger.</strong></Typography>
                        <img src="guide-images/login-createaccount.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>1.2.2 Skriv in din information:</strong></Typography>
                        <ul>
                            <li style={{ marginBottom: '16px' }}><Typography sx={{ my: '16px' }} component="body">Förnamn</Typography></li>
                            <li style={{ marginBottom: '16px' }}><Typography sx={{ my: '16px' }} component="body">Efternamn</Typography></li>
                            <li style={{ marginBottom: '16px' }}><Typography sx={{ my: '16px' }} component="body">E-postadress</Typography></li>
                            <li style={{ marginBottom: '16px' }}><Typography sx={{ my: '16px' }} component="body">Lösenord</Typography></li>
                        </ul>
                        <Typography sx={{ my: '16px' }} variant="body">Klicka i “I agree to the terms and conditions” och tryck “Sign up.”</Typography>
                        <img src="guide-images/createaccount.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong>1.2.3 Godkänn din mail</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Du kommer nu få ett mail till den e-post du har angivit i föregående steg. Avsändare på mailet är noreply@wovenspaces.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Klicka på länken i mailet för att godkänna din mail och skapa ditt konto.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong style={{ color: 'red' }}>OBS! </strong><strong>Detta mailet hamnar ofta i skräpposten. KOLLA I SKRÄPPOSTEN!</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong>1.2.4 Logga in.</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Logga nu in med din mail och lösenord som i steg 1.</Typography>
                        <Typography sx={{ my: '16px' }} variant='h5'>1.3 Välj din förening</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Nu är du inloggad! Det första du ser är en lista med de föreningar du är tillagd i. För de flesta så är det bara en förening. Klicka på den lilla pennan till höger om föreningsnamnet för att ändra information om er förening och placera ut verk.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Vi har lagt till era föreningar med den mail som står som kontaktperson. Använd gärna samma mail för att skapa konto, så kan ni sedan lägga till de andra i föreningen <a href="#section-3" style={{ color: 'white' }}>(se punkt 3.)</a></Typography>
                        <img src="guide-images/chooseorg.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Ifall ni inte hittar er förening i listan så kontaktar ni Jakob Skote.</Typography>

                        <Typography sx={{ my: '16px' }} variant='h4'>2. Lägg in information om er förening</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Nu är det dags att lägga in informationen om er förening.</Typography>
                        <img src="guide-images/orginfo.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">2.1 Skriv in rätt namn på er förening. För flera av er kommer ert eget namn stå i detta fält, ändra i så fall till föreningens namn. </Typography>
                        <Typography sx={{ my: '16px' }} variant="body">2.2 Lägg in adressen till föreningen. Denna adress syns på kartan i Meadow så att besökarna vet var ni finns. Om ni inte har en adress kan ni bara skriva er stad eller by.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong style={{ color: 'red' }}>OBS! </strong>Fältet visar ett felmeddelande ifall adressen inte hittas. Ibland kan det behövas trixas lite med postnummer och så innan det fungerar.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">2.3 Lägg in er hemsida. Detta är frivilligt.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">2.4 Lägg in en kort text om er förening. Denna text visas för besökarna i Meadow, så att de vet vilka ni är. </Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Tryck på rullgardinsmenyn för att byta språk att lägga in text för:</Typography>
                        <img src="guide-images/orginfo-dropdown.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Lägg gärna in en text på både engelska och svenska, men detta är också frivilligt. Det är dock roligare för till exempel turister om det även finns en engelsk text.</Typography>
                        <img src="guide-images/orginfo-swe.webp" alt="Log-in fönster" />

                        <Typography sx={{ my: '16px' }} variant='h4' id="section-3">3. Bjud in dina kollegor</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Om du vill att dina kollegor på föreningen också har tillgång till er sida i adminportalen så kan du enkelt lägga till dem under fliken ADMINS. </Typography>
                        <img src="guide-images/addadmin.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">3.1 Skriv in deras mail i fältet “Email” och tryck på “ADD ADMIN.”</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">3.2 Tryck på YES i rutan som dyker upp. </Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong style={{ color: 'red' }}>OBS! </strong><strong>Dina kollegor behöver fortfarande själva skapa ett konto på plattformen. Be dem följa <a href="#section-1" style={{ color: 'white' }}>steg 1 i denna guide.</a> Sen hittar de automatiskt er konstförening när de har loggat in.</strong></Typography>

                        <Typography sx={{ my: '16px' }} variant='h4'>4. Lägg in verk</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Nu är det dags att placera ut konstverken i ert område! Gå in på fliken MAP.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Placeringen av er konstförening visas med en virvel på kartan. Runt virveln ser ni en stor röd ring, detta är det område ni kan placera ut verk inom. Ni kan ändra storlek på detta område i reglaget "Administrative Area" under fliken ORGANISATION INFO.</Typography>
                        <img src="guide-images/artworkmap.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">4.1 Välj vilket verk du vill lägga in i rullgardinsmenyn “Artwork to place” till vänster. </Typography>
                        <img src="guide-images/artworklist.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">4.2 Du kan skriva in en adress i sökfältet “search” för att söka på den platsen. Eller navigera kartan genom att klicka och dra eller scrolla för att zooma. </Typography>
                        <img src="guide-images/search.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">4.3 När du hittat rätt plats för verket så dubbelklickar du på kartan för att placera ut det där. Tryck på YES i rutan som dyker upp.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Var gärna inzoomad så att du ser att det hamnar rätt, verken placeras ut i appen med någon meters felmarginal. Ibland är satelitbilden inte helt tydlig, placera då så noga ni kan.</Typography>
                        <img src="guide-images/addartwork.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Efter att du har placerat ut verket så ser du en röd indikator på kartan där du har placerat det.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Tips: Det går även att placera verk med latitud och longitud. Detta kan vara användbart för platser som är svåra att se på kartan, t.ex. inne i en skog. Då går du till platsen fysiskt och hittar latitud och longitud i t.ex. en kart-app eller kompass-app. Sen skriver du in dessa i fälten för Latitude och Longitude till höger ovanför kartan. Då visas en orange cirkel på den platsen, och du kan trycka på PLACE för att placera verket där.</Typography>
                        <img src="guide-images/placedartwork.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">4.4 Byt sen verk i listan “Artwork to place” och placera ut nästa verk. Du ser alla verk du har placerat ut på kartan. </Typography>
                        <img src="guide-images/twoartworks.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Om du trycker på en av de röda indikatorerna så ser du information om vilket verk det är, samt information som latitud, longitud och altitud (du behöver inte göra något med denna information.)</Typography>
                        <img src="guide-images/locationinfo.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">4.5 För att radera en position, klicka på den röda indikatorn för verket och sen på DELETE LOCATION. För att flytta ett verk behöver du först radera positionen och sen placera det på nytt.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body" id="section-46">4.6 Om du klickar på den röda indikatorn och sen på EDIT LOCATION DATA, så kan du lägga in en kort beskrivning om varför ni valde just denna platsen. Denna text syns efter informationen om verket i Meadow.</Typography>
                        <img src="guide-images/locationtext.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body">Även här kan ni lägga in en text på både engelska och svenska. Ni väljer språk att lägga in text för i rullgardinsmenyn.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><strong style={{ color: 'red' }}>OBS! Denna information raderas när ni flyttar verket till en ny plats. Se till att spara den någonstans så att ni kan lägga in den igen efter att ni har flyttat verket.</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">4.7 Under EDIT LOCATION DATA kan ni även lägga in plats-specifik information som verket kan använda sig av. Inte alla verk behöver detta, utan endast ett fåtal. Detta fält finns bara för de konstverk som frågar efter extra information.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ett exempel är Lundahl & Seitl’s verk, där ni kan fylla i radien på fontänen eller trädet där ni har placerat verket (i meter.) Detta kommer sedan påverka hur verket ser ut i utställningen. Ni lägger in informationen där det står “fountain radius”.</Typography>
                        <img src="guide-images/locationspecificinfo.webp" alt="Log-in fönster" />
                        <Typography sx={{ my: '16px' }} variant="body"><strong style={{ color: 'red' }}>OBS! </strong><strong>Alla verk har inte plats-</strong><strong>specifik</strong><strong> information. Detta fält syns bara för de verk som behöver den informationen. </strong></Typography>
                        
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Det var allt för </strong><strong>adminportalen</strong><strong>. Om ni har några frågor hör av er till Jakob Skote eller Linn Hübinette. </strong></Typography>

                    </Box>
                </Box>
            
        </ThemeProvider >
    );
}

export default Guide; 