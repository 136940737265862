import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';

import MainContext from "../MainContext";
import { IconButton, FormControl, InputLabel, Fab, ToggleButton, Tooltip, Checkbox, TextField } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';


const AppConfig = () => {
    const mainService = useContext(MainContext);
    const navigate = useNavigate();

    const [appVersion, setAppVersion] = useState('');
    const [iosAppStoreURL, setIosAppStoreURL] = useState('');
    const [androidAppStoreURL, setAndroidAppStoreURL] = useState('');
    const [loadingMode, setLoadingMode] = useState(true);


    useEffect(() => {

        console.log('AppConfig mounted');

        mainService.GetAdminRole().then((role) => {
            if (role!== 'master') {
                navigate('/');
                return;
            }
            console.log('master');
            mainService.addDataListener("appConfig", handleAppConfigChanged);

            setLoadingMode(false);
        }).catch((error) => {
            console.log(error);
            navigate('/');
            return;
        });


        return () => {
            console.log('AppConfig unmounted');
            mainService.removeDataListener("appConfig", handleAppConfigChanged);

        }
    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }

    const handleAppConfigChanged = (exhibitionInfo) => {

        if (exhibitionInfo.appVersion)
            setAppVersion(exhibitionInfo.appVersion);
        setAndroidAppStoreURL(exhibitionInfo.androidAppStoreURL);
        setIosAppStoreURL(exhibitionInfo.iOSAppStoreURL);

    }

    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleEditAppVersion = (event) => {
        let val = event.target.value.trim();
        mainService.setAppVersion(val);
    }
    const handleEditIOSAppStoreURL = (event) => {
        let val = event.target.value.trim();
        mainService.setIOSAppStoreURL(val);
    }
    const handleEditAndroidAppStoreURL = (event) => {
        let val = event.target.value.trim();
        mainService.setAndroidAppStoreURL(val);
    }


    //Render main info
    const renderMainInfo = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <TextField sx={{ m: "10px" }} label="App Version" value={appVersion} onChange={handleEditAppVersion} />
                    <TextField sx={{ m: "10px" }} label="iOS App Store URL" value={iosAppStoreURL} onChange={handleEditIOSAppStoreURL} />
                    <TextField sx={{ m: "10px" }} label="Android App Store URL" value={androidAppStoreURL} onChange={handleEditAndroidAppStoreURL} />
                </Box>
            </Box>
        )
    }



    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', px: '10px', height: '60px', minHeight: '60px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
                        <IconButton onClick={() => navigate('/', { tabValue: 1 })}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ ml: '8px' }}>
                            App Config
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </Box>

                    {renderMainInfo()}


                    {renderYNDialog()}
                </Box>
            )}
        </ThemeProvider>
    );
}

export default AppConfig; 