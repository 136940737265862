import Geocode from "react-geocode";
const elevationApi = require('google-elevation-api');
const egm96 = require('egm96-universal')





// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDEm52eOwIQMbwhLVAXD0egy3nj5CVLmTE");

export default class Geo {
    static async GetLocationFromAddress(address) {
        return Geocode.fromAddress(address);
    }
    static async GetAddressFromLocation(lat, lng) {
        return Geocode.fromLatLng(lat, lng);
    }
    static GetAddressFromGeocodingResult(resp) {
        let city, state, country;
        for(let k = 0; k < resp.results.length; k++) {
        for (let i = 0; i < resp.results[k].address_components.length; i++) {
            for (let j = 0; j < resp.results[k].address_components[i].types.length; j++) {
                switch (resp.results[k].address_components[i].types[j]) {
                    case "locality":
                    case "postal_town":
                        if(!city)
                        city = resp.results[k].address_components[i].long_name;
                        break;
                    case "administrative_area_level_1":
                        if(!state)
                        state = resp.results[k].address_components[i].long_name;
                        break;
                    case "country":
                        if(!country)
                        country = resp.results[k].address_components[i].long_name;
                        break;
                }
            }
        }
    }
        let address = {
            formattedAddress: resp.results[0].formatted_address,
            city: city,
            state: state,
            country: country

        }
        return address;
    }
    static async GetAltitudeFromLocation(lat, lng) {
        //create https request
        let elevation  = new Promise((res, rej) =>{
            elevationApi({
                key: "AIzaSyDX6CyeAA0SXClHMpt1MxmxtmPSisJSiBI",
                locations: [
                    [lat, lng]
                ]
            }, function(err, locations) {
                if(err) {
                    console.log(err);
                    return;
                }
               res(locations[0].elevation)
            });
        });
        let alt = await elevation;
        let transformedAlt = egm96.egm96ToEllipsoid(lat,lng,alt);
        console.log("lat " +lat + " lng " +lng + " alt " +transformedAlt);
        return transformedAlt;
    }
    static async GetAltitudesFromLocations(array) {
        //create https request
        console.log("array " +JSON.stringify(array));
        let elevations  = new Promise((res, rej) =>{
            elevationApi({
                key: "AIzaSyDX6CyeAA0SXClHMpt1MxmxtmPSisJSiBI",
                locations: array
            }, function(err, locations) {
                if(err) {
                    console.log(err);
                    return;
                }
                let elevations = [];
                locations.forEach(location => {
                    elevations.push(location.elevation);
                });

                console.log("elevations " +JSON.stringify(elevations));
             
               res(elevations)
            });
        });
        let alts = await elevations;
        let ealts = [];
        for(let i = 0; i < alts.length; i++) {
            ealts.push(egm96.egm96ToEllipsoid(array[i][0],array[i][1],alts[i]));
        }
        console.log("ealts " +JSON.stringify(ealts));
        return ealts;
    }
}
