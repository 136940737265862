import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import validator from 'validator';

import MainService from "../MainService";
import MainContext from "../MainContext";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
// import Button from "@mui/material-next/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { Slider, IconButton, ToggleButton, TextField, Tabs,Fab, Tab, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, CircleMarker, Circle, Rectangle } from 'react-leaflet'
import L from 'leaflet';
import { async } from "@firebase/util";
import { set } from "firebase/database";

import artMarker from '../assets/artwork-marker.svg';
import orgMarker from '../assets/rkf_logo.png';
import ImageUpload from "../components/ImageUpload";

const markerIcon = new L.Icon({
    iconUrl: artMarker,
    iconRetinaUrl: artMarker,
    iconAnchor: [25, 50],
    iconSize: new L.Point(50, 50)
});

const orgMarkerIcon = new L.Icon({
    iconUrl: orgMarker,
    iconRetinaUrl: orgMarker,
    iconAnchor: [15, 15],
    iconSize: new L.Point(30, 30)
});

const storagePath = 'organisationdata'


const EditOrganisation = () => {
    //use 
    const mainService = useContext(MainContext);
    const navigate = useNavigate();
    const mapRef = useRef();
    //states
    const [loadingMode, setLoadingMode] = useState(false);
    const [uploadingContent, setUploadingContent] = useState(false);
    const [adminRole, setAdminRole] = useState('collab');
    const [searchParams] = useSearchParams();

    const [tabValue, setTabValue] = useState(0);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [descriptions, setDescriptions] = useState({});
    const [website, setWebsite] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [administrativeArea, setOrganisationAdminArea] = useState(1);
    const [instancesNum, setOrganisationInstancesNum] = useState(0);
    const [organisationLocation, setOrganisationLocation] = useState([51.505, -0.09]);
    const [showAllArtworks, setShowAllArtworks] = useState(true);

    const [orgArtworks, setOrgArtworks] = useState([]);
    const [liveArtworks, setLiveArtworks] = useState([]);
    const [locationDataRules, setlocationDataRules] = useState([]);

    const [liveInApp, setLiveInApp] = useState(false);

    const [artworkLocations, setArtworkLocations] = useState([]);
    const [artworkLocationDatas, setArtworkLocationDatas] = useState([]);
    const [artworkOrganisationLocations, setArtworkOrganisationLocations] = useState();

    const [curators, setCurators] = useState([]);
    const [curatorEmail, setCuratorEmail] = useState("");
    const [selectedArtworkId, setSelectedArtworkId] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [addressError, setAddressError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);

    const [coverImageURL, setCoverImageURL] = useState('');


    let organisationid = searchParams.get('organisationid');

    //callbacks
    const handleAdminRoleChanged = (role) => {
        setAdminRole(role);
    }

    const handleOrganisationMetadataChanged = (metadata) => {

        if (!metadata) {
            navigate('/', { state: "organisation" });
            return;
        }

        setName(metadata.name);
        setAddress(metadata.address);
        if (metadata.descriptions) {
            setDescriptions(metadata.descriptions);
        }
        else if (metadata.description) {
            let desc = descriptions;
            desc["English"] = metadata.description;
            setDescriptions(desc);
            //old description format. set to english

            // setDescription(metadata.description);
        }
        setWebsite(metadata.website);
        // setOrganisationLocation(metadata.location);
        setOrganisationAdminArea(parseInt(metadata.administrativeArea));
        setOrganisationInstancesNum(parseInt(metadata.instancesNum));

        
        // if (metadata.profileImage && thumbnailKey !== metadata.profileImage) {
        //     setThumbnailKey(metadata.profileImage);
        // }


        setLiveInApp(metadata.liveInApp ? true : false);
    }
    const handleOrganisationLocationChanged = (location) => {
        if (location) {
            for (const locid in location) {
                setOrganisationLocation(location[locid]);
            }
        }


    }
    const handleCuratorsChanged = (curators) => {

        if (!curators) {
            navigate('/', { state: "organisation" });
            return;
        }

        let curats = [];
        if (curators) {
            for (const cur in curators) {
                console.log("cur: " + cur);
                console.log("curators[cur]: " + curators[cur]);
                curats.push({ safeEmail: cur, role: curators[cur] });
            }
        }
        setCurators(curats);
    }
    const handleLiveArtworksChanged = (artworks) => {
        let artworkids = [];
        for (const artworkid in artworks) {
            artworkids.push(artworkid);
        }


        let newMyArtworks = [];
        let myNewLocationDataRules = [];
        let promiseList = [];
        artworkids.forEach((artworkid) => {
            promiseList.push(
                mainService.getArtworkMetadata(artworkid)
                    .then((data) => {
                        data.id = artworkid;
                        if (data.isTest === false)
                            newMyArtworks.push(data);
                    })
            );
            promiseList.push(
                mainService.getArtworkLocationDataRules(artworkid)
                    .then((data) => {
                        if (data) {
                            // go through all keys and add artworkid
                            for (const key in data) {
                                let prop = data[key];
                                prop.propid = key;
                                prop.artid = artworkid;
                                myNewLocationDataRules.push(prop);
                            }
                            console.log("rule: " + JSON.stringify(data));
                        }
                    })
            )
        });
        Promise.all(promiseList)
            .then(() => {
                if (newMyArtworks.length > 0 && selectedArtworkId === "")
                    setSelectedArtworkId(newMyArtworks[0].id);
                newMyArtworks.forEach(artwork => {

                });
                //sort alphabetically by title
                newMyArtworks.sort((a, b) => (a.titles["English"] > b.titles["English"]) ? 1 : -1);
                setLiveArtworks(newMyArtworks);
                setlocationDataRules(myNewLocationDataRules);
            });
    }
    const handleArtworkLocationDataChanged = (data, returnData) => {
        if (!data) {
            mainService.removeDataListener("artworkLocations/" + returnData.artid + "/" + returnData.locid, handleArtworkLocationDataChanged);
            return;
        }
        setArtworkLocationDatas((oldarray) => {
            let arr = [...oldarray];

            let locData = arr.find(loc => loc.artid === returnData.artid && loc.locid === returnData.locid);
            //get index of locData
            let index = arr.indexOf(locData);
            if (!locData) {
                data.artid = returnData.artid;
                data.locid = returnData.locid;
                arr.push(data);
            }
            else {
                data.artid = returnData.artid;
                data.locid = returnData.locid;
                arr[index] = data;
            }
            // console.log("handleArtworkLocationDataChanged: " + JSON.stringify(arr));


            return arr;
        }
        );
        console.log("new data: " + JSON.stringify(artworkLocationDatas));
    }
    const handleOrgArtLocationsRelationshipsChanged = (relationships) => {
        let artLocs = [];
        for (const artworkid in relationships) {
            for (const locid in relationships[artworkid]) {
                let data = {};
                data.locid = locid;
                data.artid = artworkid;
                artLocs.push(data);
            }
        }


        //compare artLocs to artworkLocations and remove listeners for removed relationships
        if (artworkLocations) {
            let removed = artworkLocations.filter(loc => !artLocs.some(artLoc => artLoc.artid === loc.artid && artLoc.locid === loc.locid));
            removed.forEach(loc => {
                mainService.removeDataListener("artworkLocations/" + loc.artid + "/" + loc.locid, handleArtworkLocationDataChanged);
            });
        }



        let newLocations = [];
        let promiseList = [];
        artLocs.forEach((loc) => {
            promiseList.push(
                mainService.getArtworkLocation(loc.artid, loc.locid)
                    .then((data) => {
                        if (!data) {
                            console.error("data is null: " + loc.artid + " " + loc.locid + " deleting relationship");
                            mainService.deleteOrganisationArtworkLocationRelationship(organisationid, loc.artid, loc.locid);
                        }
                        else {
                            data.artid = loc.artid;
                            data.locid = loc.locid;
                            newLocations.push(data);
                        }
                    })
            );
        });
        Promise.all(promiseList)
            .then(() => {
                setArtworkLocations(newLocations);
                //add listeners for all new locations
                let added = newLocations.filter(loc => !artworkLocations.some(artLoc => artLoc.artid === loc.artid && artLoc.locid === loc.locid));
                added.forEach(loc => {
                    console.log("adding listener for artwork location: " + loc.artid + " " + loc.locid);
                    mainService.addDataListener("artworkLocations/" + loc.artid + "/" + loc.locid, handleArtworkLocationDataChanged, { artid: loc.artid, locid: loc.locid });
                });
                //change selected artwork if all instances have been placed for the current selected artwork
                if (selectedArtworkId) {
                    let count = newLocations.filter(loc => loc.artid === selectedArtworkId).length;
                    console.log("count for selected artwork " + count);
                    // if (count >= instancesNum) {
                    //     let nextArtwork = liveArtworks.find(artwork => artwork.id !== selectedArtworkId && newLocations.filter(loc => loc.artid === artwork.id).length < instancesNum);
                    //     console.log("next artwork: " + JSON.stringify(nextArtwork));
                    //     if (nextArtwork)
                    //         setSelectedArtworkId(nextArtwork.id);
                    // }
                }

            });
    }
    const handleOrganisationDelete = (event) => {
        console.log("Organisation delete!!");
        mainService.deleteOrganisation(organisationid)
            .then(() => {
                navigate('/', { state: "organisation" });
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const handleEditName = (event) => {
        let val = event.target.value;
        mainService.setOrganisationName(organisationid, val);
    }
    const handleEditAddress = (event) => {
        let val = event.target.value;
        setAddress(val);
    }
    const handleFinishEditAddress = (event) => {
        let val = event.target.value;
        mainService.setOrganisationAddress(organisationid, val, (suc) => {
            setAddressError(!suc);
        });
    }
    const handleEditDescription = (event) => {
        let val = event.target.value;
        mainService.setOrganisationDescription(organisationid, selectedLanguage, val);
    }
    const handleEditWebsite = (event) => {
        let val = event.target.value;

        setWebsiteError(!validator.isURL(val));

        mainService.setOrganisationWebsite(organisationid, val);
    }

    const handleEditAdministrativeArea = (event) => {
        let val = event.target.value;
        mainService.setOrganisationAdminArea(organisationid, val);
    }
    const handleEditInstancesNum = (event) => {
        let val = event.target.value;
        mainService.setOrganisationArtworkInstancesNum(organisationid, val);
    }
    const handleEditLiveInApp = (event) => {
        let val = event.target.checked;
        mainService.setOrganisationLiveInApp(organisationid, val);
    }
    const handleAddLocation = (e) => {
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        let geocodeLocation = getArtworkMetadata(selectedArtworkId).geocodeLocations ?? false;
        console.log("Adding location: " + lat + " " + lng + " geocode: " + geocodeLocation);
        mainService.addArtworkLocationOrganisation(organisationid, selectedArtworkId, lat, lng, geocodeLocation);
    }
    const handleDeleteLocation = (data) => {
        mainService.deleteArtworkLocation(data.artid, data.locid);
    }
    const handleOnLocationDataEdit = (artworkid, locationid, propertyid, value) => {
        mainService.setLocationData(artworkid, locationid, propertyid, value);
    }
    const handleAddAdmin = () => {
        console.log("Adding admin");
        mainService.addOrganisationCurator(organisationid, curatorEmail);
        setCuratorEmail("");
    }
    const handleDeleteCurator = (e) => {
        console.log("Deleting admin");
        mainService.deleteOrganisationAdmin(organisationid, e.curatorEmail);
    }
    const handleSearch = async () => {
        let loc = await mainService.getLocationFromSearch(searchValue);
        handleSetView(loc.lat, loc.lng);
    }
    const handleSetView = (lat, lng) => {
        mapRef.current.setView([lat, lng], 18);
    }
    const handleEditLocDataButton = (artworkid, locationid) => {
        setSelectedLocationId(locationid);
        setSelectedArtworkId(artworkid);
        setLocationDataDialogOpen(true);
    }
    const handleOrganisationNoteEdit = (text) => {
        console.log("handleOrganisationNoteEdit " + text);
        let currData = artworkLocationDatas.find((loc) => loc.locid === selectedLocationId);
        let data = currData["organisationNote"] ?? {};
        data[selectedLanguage] = text;
        mainService.setArtworkLocationData(selectedArtworkId, selectedLocationId, "organisationNote", data);
    }



    //Effects
    useEffect(() => {

        //on mount
        console.log('EditOrganisation mounted');
        let uid = mainService.Uid();
        let safeEmail = mainService.safeEmail();

        mainService.addDataListener("adminRoles/" + uid, handleAdminRoleChanged);
        mainService.addDataListener("liveArtworks", handleLiveArtworksChanged);

        if (organisationid) {
            mainService.addDataListener("organisationMetadatas/" + organisationid, handleOrganisationMetadataChanged);
            mainService.addDataListener("organisationArtworkLocationRelationships/" + organisationid, handleOrgArtLocationsRelationshipsChanged);
            mainService.addDataListener("organisationAdmins/" + organisationid, handleCuratorsChanged);
            mainService.addDataListener("organisationLocations/" + organisationid, handleOrganisationLocationChanged);

        }
        else {
            navigate('/', { state: "organisation" });
        }

        //on unmount
        return () => {
            console.log('EditOrganisation unmounted');
            mainService.removeDataListener("adminRoles/" + uid, handleAdminRoleChanged);
            mainService.removeDataListener("liveArtworks", handleLiveArtworksChanged);

            if (organisationid) {
                mainService.removeDataListener("organisationMetadatas/" + organisationid, handleOrganisationMetadataChanged);
                mainService.removeDataListener("organisationArtworkLocationRelationships/" + organisationid, handleOrgArtLocationsRelationshipsChanged);
                mainService.removeDataListener("organisationAdmins/" + organisationid, handleCuratorsChanged);
                mainService.removeDataListener("organisationLocations/" + organisationid, handleOrganisationLocationChanged);
                if (artworkLocations && artworkLocations.length > 0)
                    artworkLocations.forEach((artworkLocation) => {
                        mainService.removeDataListener("artworkLocations/" + artworkLocation.artworkid + "/" + artworkLocation.locationid, handleArtworkLocationDataChanged);
                    });
            }
        }

    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [locationDataDialogOpen, setLocationDataDialogOpen] = React.useState(false);
    const [selectedLocationId, setSelectedLocationId] = React.useState("");
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }

    const renderLocationDataDialog = () => {

        let rules = locationDataRules.filter((rule) => rule.artid === selectedArtworkId);
        let locationData = artworkLocationDatas.find((loc) => loc.locid === selectedLocationId);

        return (
            <Dialog
                open={locationDataDialogOpen}
                onClose={() => { setLocationDataDialogOpen(false) }}
                fullWidth={true}
            >
                <DialogTitle >
                    {getArtworkMetadata(selectedArtworkId).title}
                </DialogTitle>
                <DialogContent>
                    <Typography >Enter location-specific infromation</Typography>
                    <Box sx={{ flexGrow: 1, mx: '8px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                        {rules && locationData && rules.map(rule => (
                            <Box sx={{ display: 'flex', my: '5px', alignItems: 'center', padding: '10px', backgroundColor: "#262626", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                                <Typography key={rule.propid + "_tooltip"} sx={{ flexGrow: 1 }}>{rule.tooltip}</Typography>
                                <TextField key={rule.propid} sx={{ maxWidth: '170px', borderRadius: "5px", border: '1px solid rgba(255,255,255,0.2)', my: '8px' }}
                                    label={rule.propid} variant="outlined" value={locationData["locationData"] ? (locationData["locationData"][rule.propid] ?? "") : ""} onChange={(e) => handleOnLocationDataEdit(selectedArtworkId, selectedLocationId, rule.propid, e.target.value)}></TextField>
                            </Box>
                        ))}
                        <FormControl sx={{ display: 'flex', flexWrap: 'wrap', my: '10px' }}>
                            <InputLabel id="select">Language</InputLabel>
                            <Select labelId="select" label="Language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Swedish"}>Swedish</MenuItem>
                            </Select>

                        </FormControl>
                        {locationData &&
                            <TextField label="Why did you choose this location?" variant="outlined" multiline sx={{ flexGrow: 1, my: '10px' }}
                                value={locationData["organisationNote"] ? locationData["organisationNote"][selectedLanguage] ?? "" : ""}
                                onChange={(e) => handleOrganisationNoteEdit(e.target.value)} />}

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setLocationDataDialogOpen(false); }}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }


    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }
    //Setup Prompt box
    const [promptOpen, setPromptOpen] = React.useState(false);
    const [promptText, setPromptText] = React.useState("");
    const startPromptAction = (event, text) => {
        setPromptOpen(true);
        setPromptText(text);
        let e = event;

    }
    const renderPrompt = () => {
        return (
            <Dialog
                open={promptOpen}
                onClose={() => { setPromptOpen(false) }}
            >
                <DialogTitle >
                    {promptText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setPromptOpen(false); }} autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }


    //Render main info
    const renderMainInfo = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                <Box sx={{ display: 'flex', flexGrow: 2, flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
                        <TextField sx={{ m: '10px'}} label="Name" variant="outlined" value={name} onChange={handleEditName} />
                        <TextField sx={{ m: '10px'}} label="Address" variant="outlined" value={address} onChange={handleEditAddress} onBlur={handleFinishEditAddress} />
                        {addressError && <Typography sx={{ m: '10px', }} variant="subtitle1" color={'red'}>Address not recognised. Please enter a valid address.</Typography>}
                                <TextField sx={{ m: '10px', }} label="Website" variant="outlined" value={website} onChange={handleEditWebsite} />
                                {websiteError && <Typography sx={{ m: '10px', }} variant="subtitle1" color={'red'}>Website is not an URL. Please enter correct website address (eg. www.example.com)</Typography>}
                    </Box>
                    <ImageUpload sx={{ display: 'block',flexGrow: 1,  maxLength:'100%' }} 
                        title={name} 
                        type="profile"
                        mainService={mainService}
                        id={organisationid}
                        width = {'100px'}
                        height = {'100px'}
                        storagePath="organisationdata" />

                    </Box>

                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Typography sx={{ m: '10px', }} variant="h7">Description</Typography>
                        <FormControl sx={{ display: 'flex', flexWrap: 'wrap', m: '10px' }}>
                            <InputLabel id="select">Language</InputLabel>
                            <Select labelId="select" label="Language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Swedish"}>Swedish</MenuItem>
                            </Select>

                        </FormControl>
                        <TextField multiline maxRows={4} inputProps={{ maxLength: 300 }} sx={{ m: '10px', }} label="Description (max. 300 characters)" variant="outlined" value={descriptions[selectedLanguage] ?? ""} onChange={handleEditDescription} />
                    </Box>

                    <Typography sx={{ m: '10px' }} variant="h7">Administrative Area</Typography>
                    <Box sx={{ mx: '30px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <Slider sx={{ display: 'fex', flexGrow: 1, alignSelf: 'center' }}
                            value={administrativeArea}
                            valueLabelDisplay="auto"
                            onChange={handleEditAdministrativeArea}
                            step={1}
                            marks
                            min={1}
                            max={20} >
                        </Slider>
                        <Typography sx={{ ml: '30px', mr: '10px', mb: '15px', padding: '5px', textAlign: 'center', flex: 1, minWidth: "70px", backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }} variant="h7">{administrativeArea} km</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '32px', mt: '30px' }}>
                        <Switch sx={{ mr: '10px'}} checked={liveInApp} onChange={(e) => handleEditLiveInApp(e)} />
                        <Typography>Organisation has a physical venue</Typography>
                    </Box>
                    {adminRole === 'master' ? (
                        <React.Fragment>

                            <Box sx={{ mt: '50px', mb: '20px', borderTop: '1px solid rgba(255, 255, 255, 0.5)' }}>
                                Master User Settings
                            </Box>
                            <TextField sx={{ m: '10px', }} label="Instances Number" variant="outlined" value={instancesNum} onChange={handleEditInstancesNum} />


                            <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete this artwork? This can't be undone!", handleOrganisationDelete);
                            }}>Delete Organisation</Button>

                        </React.Fragment>
                    ) : (null)}



                </Box>
            </Box>
        )
    }

    //Render Map 
    const MapController = () => {
        const map = useMapEvents({
            dblclick(e) {
                if (map.distance(e.latlng, organisationLocation) <= administrativeArea * 1000) {
                    // if (artworkLocations.filter(l => l.artid === selectedArtworkId).length < instancesNum)
                        startYNDialogAction(e, "Add artwork here?", handleAddLocation);
                    // else
                    //     startPromptAction(e, "Maximum number of instances for this artwork reached. If you wish to change the location of an artwork, please delete its marker first.");

                }
                else
                    startPromptAction(e, "You are not allowed to place an artwork outside of your administrative area");
            },
        });

        const handleLocate = () => {
            map.locate();
            // map.flyTo(userLocation, map.getZoom());
        }

        return (

            <Box sx={{ position: 'absolute', right: '10px', top: '10px', zIndex: 500, alignContent: 'center', justifyContent: 'center' }}>

                <ToggleButton value={showAllArtworks} onChange={(e) => setShowAllArtworks(!showAllArtworks)}>
                    <AutoAwesomeMotionIcon opacity={showAllArtworks ? 1 : 0.7} sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></AutoAwesomeMotionIcon>
                </ToggleButton>
                <IconButton onClick={() => handleLocate()}>
                    <MyLocationIcon sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></MyLocationIcon>
                </IconButton>
            </Box>
        );
    }
    const handleSelectedArtworkChange = (event, artworkid) => {
        setSelectedArtworkId(artworkid);
    };
    const renderMap = () => {

        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '1200px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#19202b", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Typography sx={{ m: '15px', }} variant="h6">Här lägger du in verk i ditt område.</Typography>
                        <Typography sx={{ mx: '30px' }}>1. Välj vilket verk du vill lägga in i rullgardinsmenyn “Artwork to place” till vänster. <Link to={'/artworklocations'} style={{ color: 'white' }}>Lista på verk och platstyper hittar du här.</Link></Typography>
                        <Typography sx={{ mx: '30px' }}>2. Dubbelklicka på kartan för att placera ett verk.</Typography>
                        <Typography sx={{ mx: '30px' }}>3. För att byta position på ett verk, klicka på den röda pilen och sen på “Delete location.”</Typography>
                        <Typography sx={{ mx: '30px', mb: '20px' }}>4. Klicka på den röda pilen och sen “Edit location data” för att lägga in information om er placering av verket som visas för användarna.</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' }}>
                        <FormControl sx={{ minWidth: '200px', maxWidth: '400px', mx: '16px', my: '8px', flexGrow: 1 }}>
                            <InputLabel id="select">Artwork to place</InputLabel>
                            {/* <Select labelId="select" label="Artwork to place" value={selectedArtworkId && artworkLocations.filter(l => l.artid === selectedArtworkId).length < instancesNum ? selectedArtworkId : ""} onChange={(e) => handleSelectedArtworkChange(e, e.target.value)}> */}
                            <Select labelId="select" label="Artwork to place" value={selectedArtworkId} onChange={(e) => handleSelectedArtworkChange(e, e.target.value)}>
                                {liveArtworks.map((artwork, index) =>
                                    //only add artworks that are not already placed
                                    // artworkLocations.filter(l => l.artid === artwork.id).length < instancesNum &&
                                    <MenuItem key={index} value={artwork.id}>{artwork.artist}</MenuItem>

                                )}
                            </Select>
                        </FormControl>


                        <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                            value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                            InputProps={{ endAdornment: <IconButton onClick={() => handleSearch()}><SearchIcon /></IconButton> }} />
                        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', mx: '16px', my: '8px' }}>
                            <TextField sx={{ mx: '10px' }} type="number" label="Latitude" variant="outlined" value={lat} onChange={(e) => setLat(parseFloat(e.target.value))} />
                            <TextField sx={{ mx: '10px' }} type="number" label="Longitude" variant="outlined" value={lng} onChange={(e) => setLng(parseFloat(e.target.value))} />
                            <IconButton onClick={() => handleSetView(lat, lng)}><SearchIcon /></IconButton>
                        </Box>
                        <Button sx={{ mx: '10px' }} variant="contained" onClick={(e) => handleManualPlace({ latlng: { lat: lat, lng: lng } })}>Place</Button>
                    </Box>


                    <MapContainer
                        ref={mapRef}
                        center={organisationLocation}
                        zoom={16}
                        scrollWheelZoom={true}
                        maxZoom={26}
                        doubleClickZoom={false}
                        inertia={true}
                        preferCanvas={true}
                        style={{ flexGrow: 1, margin: '10px', overflow: 'hidden', borderRadius: '8px', minHeight: '600px' }}
                    >
                        <TileLayer
                            maxZoom={26}
                            maxNativeZoom={19}
                            url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                            subdomains={['mt1', 'mt2', 'mt3']}
                        />
                        <MapController />

                        <Marker position={organisationLocation} icon={orgMarkerIcon} />
                        <Circle center={organisationLocation} radius={administrativeArea * 1000} pathOptions={{ color: 'red', fill: false }} />
                        {lat && lng && <CircleMarker center={[lat, lng]} radius={10} pathOptions={{ color: 'orange' }} />}
                        <ArtworkLocationMarkers />


                    </MapContainer>

                </Box>
            </Box>
        )
    }
    const handleManualPlace = (e) => {
        if (mapRef.current.distance(e.latlng, organisationLocation) <= administrativeArea * 1000) {
            // if (artworkLocations.filter(l => l.artid === selectedArtworkId).length < instancesNum)
                startYNDialogAction(e, "Add artwork here?", handleAddLocation);
            // else
            //     startPromptAction(e, "Maximum number of instances for this artwork reached. If you wish to change the location of an artwork, please delete its marker first.");

        }
        else
            startPromptAction(e, "You are not allowed to place an artwork outside of your administrative area");
    }



    const ArtworkLocationMarkers = () => {
        let artworkLocationsToDisplay = artworkLocations.filter((loc) => (loc.artid === selectedArtworkId || showAllArtworks));
        return (artworkLocationsToDisplay.map((loc) =>
            <Marker key={loc.locid} position={[loc.lat, loc.lng]} opacity={loc.artid === selectedArtworkId ? 1 : 0.8} title={getArtworkMetadata(loc.artid).titles ? getArtworkMetadata(loc.artid).titles["English"] : getArtworkMetadata(loc.artid).title} icon={markerIcon}>
                <Popup>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="subtitle2">{getArtworkMetadata(loc.artid).titles ? getArtworkMetadata(loc.artid).titles["English"] : getArtworkMetadata(loc.artid).title}</Typography>
                        <Typography gutterBottom variant="subtitle">by {getArtworkMetadata(loc.artid).artist}</Typography>
                        <Typography gutterBottom variant="subtitle">{"lat: " + loc.lat}</Typography>
                        <Typography gutterBottom variant="subtitle">{"lng: " + loc.lng}</Typography>
                        <Typography gutterBottom variant="subtitle">{"alt: " + loc.alt ?? "null"}</Typography>
                        <Button sx={{ mt: "15px" }} variant='contained' onClick={(e) => {
                            handleEditLocDataButton(loc.artid, loc.locid);
                        }}>
                            Edit Location Data
                        </Button>
                        {liveArtworks.some((artwork) => artwork.id === loc.artid) &&
                            <Button sx={{ mt: "10px" }} color='warning' variant='contained' onClick={(e) => {
                                startYNDialogAction({ artid: loc.artid, locid: loc.locid }, "Are you sure you want to remove this location?", handleDeleteLocation);
                            }}>
                                Delete Location
                            </Button>
                        }
                    </Box>
                </Popup>
            </Marker>)
        )
    }

    const getArtworkMetadata = (artworkid) => {
        if (!liveArtworks || !liveArtworks.some((artwork) => artwork.id === artworkid))
            return { title: "Test artwork location - contact support!" };
        return liveArtworks.find((artwork) => artwork.id === artworkid);
    }

    //Render Collaborators 
    const renderAdmins = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#19202b", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>

                        <Typography sx={{ m: '15px', }} variant="h6">Här kan du lägga till fler användare i din organisation.</Typography>
                        <Typography sx={{ mx: '30px' }}>1. VSkriv in deras mail i fältet “Email” och tryck på “ADD ADMIN.”</Typography>
                        <Typography sx={{ mx: '30px' }}>2. De behöver själva skapa konto på plattformen.</Typography>
                        <Typography sx={{ mx: '30px', mb: '20px' }}>3. Efter att de har skapat konto kommer de se er organisation under “Organisations”.</Typography>
                    </Box>

                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '32px' }}>
                            <TextField sx={{ m: '10px', flexGrow: 1, minWidth: '150px' }} label="Email" variant="outlined"
                                value={curatorEmail} onChange={(e) => setCuratorEmail(e.target.value)} />

                            <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Add " + curatorEmail + " as a admin?", handleAddAdmin);
                            }}>Add Admin</Button>

                        </Box>
                    </React.Fragment>

                    {curators.map((curator) => (
                        <Box key={curator.safeEmail} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ mr: '8px' }}>{curator.safeEmail.replaceAll('%2E', '.')}</Typography>
                            <Box sx={{ flexGrow: 1 }} />

                            {curator.safeEmail!== mainService.safeEmail() && adminRole === 'master' ? (
                                <IconButton onClick={(e) => {
                                    e.curatorEmail = curator.safeEmail;
                                    startYNDialogAction(e, "Are you sure you want to remove this collaborator?", handleDeleteCurator);
                                }}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            ) : (null)}

                        </Box>
                    ))}

                </Box>
            </Box>
        )
    }



    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>

                    <Box sx={{ display: 'flex', alignItems: 'center', px: '10px', height: '60px', minHeight: '60px', borderBottom: '1px solid rgba(255, 255, 255, 0.5)' }}>
                        <IconButton onClick={() => navigate('/', { state: "organisation" })}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography sx={{ ml: '8px' }}>
                            Edit Organisation
                        </Typography>
                        <Box sx={{ flexGrow: 1 }}></Box>
                    </Box>

                    <Box sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                            <Tab label="Organisation Info" />
                            {/* <Tab label="Map" /> */}
                            <Tab label="Admins" />
                        </Tabs>
                    </Box>

                    {tabValue === 0 ?
                        renderMainInfo()
                        // : tabValue === 1 ?
                        //     renderMap()
                            : tabValue === 1?
                                renderAdmins()
                                : (null)}
                    {renderLocationDataDialog()}
                    {renderYNDialog()}
                    {renderPrompt()}
                </Box>
            )}
        </ThemeProvider>
    );
}

export default EditOrganisation; 