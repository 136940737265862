import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, createSearchParams, useLocation } from "react-router-dom";

import MainService from "../MainService";
import MainContext from "../MainContext";
import TopBar from "../components/TopBar";

import { useTheme, ThemeProvider, createTheme } from '@mui/system';
import theme from '../theme';

// import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, InputLabel, Select, FormControl, Stack, Switch, Icon } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Timezones } from "../Timezones";
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, CircleMarker } from 'react-leaflet'
import { set } from "firebase/database";
import Credits from "../components/Credits";


import L from 'leaflet';
import artMarker from '../assets/artwork-marker.svg';
import logo from '../assets/logo192.png';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const markerIcon = new L.Icon({
    iconUrl: artMarker,
    iconRetinaUrl: artMarker,
    iconAnchor: [25, 50],
    iconSize: new L.Point(50, 50)
});




require('dayjs/locale/en-gb');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat)
dayjs.tz.setDefault("Europe/London");
dayjs.locale('en-gb');


const Home = () => {


    const mainService = useContext(MainContext);
    const navigate = useNavigate();
    const mapRef = useRef();
    let location = useLocation();

    const [searchValue, setSearchValue] = useState('');


    //states
    const [anchorEl, setAnchorEl] = React.useState(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [profileImgUrl, setProfileImgUrl] = useState("");
    const [signOutButtonDisabled, setSignOutButtonDisabled] = useState(false);
    const [adminRole, setAdminRole] = useState(null);
    const [loadingMode, setLoadingMode] = useState(true);
    const [myArtworks, setMyArtworks] = useState([]);
    const [allArtworks, setAllArtworks] = useState([]);
    const [myOrganisations, setMyOrganisations] = useState([]);
    const [myExhibitions, setMyExhibitions] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [exhibitionCredits, setExhibitionCredits] = useState([]);
    const [exhibitionDescription, setExhibitionDescription] = useState({});
    const [exhibitionTitle, setExhibitionTitle] = useState({});
    const [websiteLanguage, setWebsiteLanguage] = useState('en');
    const [artworkLocations, setArtworkLocations] = useState([]);
    const [userLocation, setUserLocation] = useState([51.505, -0.09]);
    const [sortOrganisationsAlphabetically, setSortOrganisationsAlphabetically] = useState(false);
    const [sortExhibitionsAlphabetically, setSortExhibitionsAlphabetically] = useState(false);
    const [showAllArtowrks, setShowAllArtowrks] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [masterMode, setMasterMode] = useState(false);

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [alt, setAlt] = useState(0);


    //callbacks
    const handleSignOut = () => {
        mainService.SignOut()
            .then(() => {
                setSignOutButtonDisabled(true);
            })
            .catch(() => {
                setSignOutButtonDisabled(false)
            })
    }

    const handleAdminRoleChanged = (role) => {
        console.log('admin Role changed: ' + role);
        let safeEmail = mainService.safeEmail();
        if (role === 'master') {

            mainService.addDataListener("artworkMetadatas", handleArtworksChanged);
            mainService.addDataListener("organisationMetadatas", handleOrganisationsChanged);
            mainService.addDataListener("exhibitionMetadatas", handleExhibitionsChanged);
            console.log('added artworkMetadatas listener');
            setLoadingMode(false);
        }
        else {
            mainService.SignOut();
            navigate('/'); 
            // mainService.addDataListener("adminOrganisationRelationships/" + safeEmail, handleOrganisationRelationshipsChanged);
        }


        setAdminRole(role);
    }

    const handleCreateArtwork = () => {
        console.log('creating artwork: ');
        setLoadingMode(true);
        mainService.createNewArtwork()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }



    const handleCreateOrganisation = () => {
        console.log('creating organisation: ');
        setLoadingMode(true);
        mainService.createNewOrganisation()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }
    const handleCreateExhibition = () => {
        console.log('creating exhibition: ');
        setLoadingMode(true);
        mainService.createNewExhibition()
            .then(() => {
                setLoadingMode(false);
            })
            .catch(() => {
                setLoadingMode(false);
            });
    }

    const handleArtworkLocationsChanged = (artworkLocations) => {
        let locs = [];

        if (artworkLocations) {
            for (const artworkid in artworkLocations) {
                for (const locid in artworkLocations[artworkid]) {
                    let loc = artworkLocations[artworkid][locid];
                    loc.locid = locid;
                    loc.artworkid = artworkid;
                    if (loc.lat && loc.lng)
                        locs.push(loc);
                }
            }
        }

        console.log('artwork locations changed: ' + locs.length);

        setArtworkLocations(locs);
    }
    const handleDeleteLocation = (e) => {
        console.log('deleting location: ' + e.locid);
        mainService.deleteArtworkLocation(e.artid, e.locid);
    }

    const handleArtworksChanged = (artworks) => {
        console.log('artworks changed: ' + Object.keys(artworks).length);
        let newMyArtworks = [];
        let promiseList = [];
        for (const artworkid in artworks) {
            if (!artworkid || artworkid === "undefined") {
                console.error("Null artwork in db")
                continue;
            }
            let data = artworks[artworkid];
            if (!data.titles || data.titles["English"] === null) {
                console.error("Outdated data structure for: " + artworkid)
                continue;
            }
            data.id = artworkid;
            newMyArtworks.push(data);
        };

        //sort alphabetically
        newMyArtworks = newMyArtworks.sort((a, b) => (a.titles["en"] ? a.titles["en"] : 
        ( a.titles["English"] ? a.titles["English"] :  "no-title") >  b.titles["en"] ? b.titles["en"] : 
        ( b.titles["English"] ? b.titles["English"]:  "no-title") ) ? 1 : -1);



        setAllArtworks(newMyArtworks);

    }
    const handleOrganisationsChanged = (organisations) => {
        let newMyOrganisations = [];
        let promiseList = [];
        for (const organisationid in organisations) {
            let data = organisations[organisationid];
            data.id = organisationid;
            newMyOrganisations.push(data);
        };
        setMyOrganisations(newMyOrganisations);
    }
    const handleExhibitionsChanged = (exhibitions) => {
        let newMyExhibitions = [];
        for (const eid in exhibitions) {
            let data = exhibitions[eid];
            data.id = eid;
            newMyExhibitions.push(data);
        };
        console.log('exhibitions changed: ' + newMyExhibitions.length);
        setMyExhibitions(newMyExhibitions);
    }


    const handleArtworkRelationshipsChanged = (relationships) => {
        console.log('artwork relationships changed');
        let artworkids = [];
        for (const artworkid in relationships) {
            artworkids.push(artworkid);
        }

        let newMyArtworks = [];
        let promiseList = [];
        artworkids.forEach((artworkid) => {
            if (!artworkid || artworkid === "undefined") {
                console.error("Null artwork in db")
            }
            else {
                promiseList.push(
                    mainService.getArtworkMetadata(artworkid)
                        .then((data) => {
                            if (data) {
                                if (!data.titles || data.titles["English"] === null) {
                                    console.error("Outdated data structure for: " + artworkid)
                                }
                                else {


                                    data.id = artworkid;
                                    newMyArtworks.push(data);
                                }
                            }
                            else {
                                console.error('artwork metadata not found: ' + artworkid);
                            }
                        })
                );
            }
        });

        Promise.all(promiseList)
            .then(() => {
                //sort alphabetically 
                newMyArtworks = newMyArtworks.sort((a, b) => (a.titles["en"] ? a.titles["en"] : 
                    ( a.titles["English"] ? a.titles["English"] :  "no-title") >  b.titles["en"] ? b.titles["en"] : 
                    ( b.titles["English"] ? b.titles["English"]:  "no-title") ) ? 1 : -1);
                setMyArtworks(newMyArtworks);
                // if (myArtworks.length === 0)
                //     setTabValue(1);
            });
    }
    const handleOrganisationRelationshipsChanged = (relationships) => {
        let organisationids = [];
        for (const organisationid in relationships) {
            organisationids.push(organisationid);
        }

        let newMyOrganisations = [];
        let promiseList = [];
        organisationids.forEach((organisationid) => {
            promiseList.push(
                mainService.getOrganisationMetadata(organisationid)
                    .then((data) => {
                        data.id = organisationid;
                        newMyOrganisations.push(data);
                    })
            );
        });


        Promise.all(promiseList)
            .then(() => {
                setMyOrganisations(newMyOrganisations);
            });
    }
    const handleExhibitionRelationshipsChanged = (relationships) => {
        console.log('exhibition relationships changed');
        let exhibitionIds = [];
        for (const exhibitionId in relationships) {
            exhibitionIds.push(exhibitionId);
        }

        let newMyExhibitions = [];
        let promiseList = [];
        exhibitionIds.forEach((exhibitionId) => {
            promiseList.push(
                mainService.getExhibitionMetadata(exhibitionId)
                    .then((data) => {
                        data.id = exhibitionId;
                        newMyExhibitions.push(data);
                    })
            );
        });


        Promise.all(promiseList)
            .then(() => {
                console.log('private exhibitions changed: ' + newMyExhibitions.length);
                setMyExhibitions(newMyExhibitions);
            });
    }


    const handleArtworkEditClicked = (artworkid) => {
        navigate({ pathname: '/editexperience', search: `?${createSearchParams({ id: artworkid })}` });
    }
    const handleOrganisationEditClicked = (organisationid) => {
        navigate({ pathname: '/editorganisation', search: `?${createSearchParams({ organisationid: organisationid })}` });
    }
    const handleExhibitionEditClicked = (exhibitionid) => {
        navigate({ pathname: '/editexhibition', search: `?${createSearchParams({ eid: exhibitionid })}` });
    }
    // const handleAccountEditClicked = (userid) => {
    //     console.log('account settings clicked');
    //     navigate({ pathname: '/settings', search: `?${createSearchParams({ userid: userid })}` });
    // }
    const handleAccountMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleAccountMenuClose = () => {
        setAnchorEl(null);
    };
    const handleGetAltitudeForm = async (e) => {
        e.preventDefault();

        let alt = await mainService.getAltitude(lat, lng)

        setAlt(alt);

    }

    const renderArtworks = () => {

        let artwrks = showAllArtowrks ? [...allArtworks] : [...myArtworks];

        let tests = artwrks.filter((artwork) => artwork.isTest === true);
        let nonTests = artwrks.filter((artwork) => artwork.isTest === false);


        return (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>

            <Box sx={{ mt: '16px', alignSelf: 'stretch', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {adminRole === 'master' ? (
                    <React.Fragment>
                        <Typography variant="subtitle1" >Show all</Typography>
                        <Switch sx={{ mr: '30px' }} checked={showAllArtowrks} onChange={(e) => setShowAllArtowrks(e.target.checked)} />
                    </React.Fragment>
                ) : (null)}
                <Button variant="contained" sx={{ m: '10px' }} onClick={handleCreateArtwork}>Create New Experience</Button>
            </Box>



            <Box sx={{ mt: '16px', flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px' }}>
                    {/* <Box sx={{ display: 'flex', p:'15px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}> */}

                    {nonTests.length > 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', my: '16px' }}>
                            <Typography style={{ flexGrow: 1 }} variant='h6'>Experiences</Typography>
                        </Box>
                    ) : null}
                    {nonTests.map((artwork) =>
                        <Box key={artwork.id} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ width: '30%' }}>{artwork.titles["English"] ?? ""}</Typography>
                            {/* <Box sx={{ flexGrow: 1 }} /> */}
                            <Typography sx={{ justifyContent: 'left' }} variant='caption'>{(artwork.ownerUsername ?? "no-username" ) + " (" + artwork.ownerName + ")"}</Typography>
                            <Box sx={{ flexGrow: 2 }} />
                            <IconButton onClick={() => handleArtworkEditClicked(artwork.id)}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    )}
                    {/* </Box> */}
                    {/* <Box sx={{ display: 'flex', mt:'30px', p:'15px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}> */}

                    {tests.length > 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '30px', mb: '15px' }}>
                            <Typography style={{ flexGrow: 1 }} variant='h6'>Tests</Typography>
                        </Box>
                    ) : null}
                    {tests.map((artwork) =>
                        <Box key={artwork.id} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ width: '30%' }}>{artwork.titles ? artwork.titles["English"] ? artwork.titles["English"] : artwork.title : artwork.title}</Typography>
                            {/* <Box sx={{ flexGrow: 1 }} /> */}
                            <Typography sx={{ justifyContent: 'left' }} variant='caption'>{(artwork.ownerUsername ?? "no-username" ) + " (" + artwork.ownerName + ")"}</Typography>
                            <Box sx={{ flexGrow: 2 }} />
                            <IconButton onClick={() => handleArtworkEditClicked(artwork.id)}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                {/* </Box> */}
            </Box>


            {adminRole === 'master' ? (
                <React.Fragment>

                    <Box sx={{ flexGrow: 1, minHeight: '800px', alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '1200px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                            <Typography variant="h7"> All locations</Typography>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' }}>
                                <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                                    value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                                    InputProps={{ endAdornment: <IconButton onClick={() => handleSearch()}><SearchIcon /></IconButton> }} />
                            </Box>
                            <MapContainer ref={mapRef}
                                center={userLocation}
                                zoom={13}
                                scrollWheelZoom={true}
                                maxZoom={26}
                                doubleClickZoom={false}
                                inertia={true}
                                preferCanvas={true}
                                style={{ flexGrow: 1, margin: '10px', overflow: 'hidden', borderRadius: '8px' }}
                            >
                                <TileLayer
                                    maxZoom={26}
                                    maxNativeZoom={19}
                                    url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                    subdomains={['mt1', 'mt2', 'mt3']}
                                />
                                <MapController />

                                <CircleMarker center={userLocation} radius={10} />


                                {artworkLocations.map((loc) =>
                                    <Marker key={loc.locid} position={[loc.lat, loc.lng]} icon={markerIcon}>
                                        <Popup>
                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography gutterBottom variant="subtitle2">{getArtworkTitle(loc.artworkid)}</Typography>
                                                {/* <Typography gutterBottom variant="subtitle2">{""}</Typography> */}
                                                <Typography gutterBottom variant="subtitle">{"artid: " + loc.artworkid}</Typography>
                                                <Typography gutterBottom variant="subtitle">{"locid: " + loc.locid}</Typography>
                                                <Typography gutterBottom variant="subtitle">{"lat: " + loc.lat}</Typography>
                                                <Typography gutterBottom variant="subtitle">{"lng: " + loc.lng}</Typography>
                                                <Typography gutterBottom variant="subtitle">{"alt: " + (loc.alt ?? "null")}</Typography>
                                                <Typography gutterBottom variant="subtitle">{"locHash: " + loc.locHash}</Typography>
                                                {loc.organisation ? (<Typography gutterBottom variant="subtitle">{"orgid: " + loc.organisation}</Typography>) : null}
                                                {loc.exhibition ? (<Typography gutterBottom variant="subtitle">{"exhibition: " + loc.exhibition}</Typography>) : null}
                                                <Button variant='contained' onClick={(e) => {
                                                    e.artid = loc.artworkid;
                                                    e.locid = loc.locid;
                                                    startYNDialogAction(e, "Are you sure you want to remove this location?", handleDeleteLocation);
                                                }}>
                                                    Delete Location
                                                </Button>
                                            </Box>
                                        </Popup>
                                    </Marker>
                                )}


                            </MapContainer>
                            <Box >
                                <React.Fragment>
                                    <h2>Get Altitude</h2>
                                    <form onSubmit={handleGetAltitudeForm}>
                                        <Stack spacing={2} direction="row" sx={{ marginBottom: 4 }}>
                                            <TextField
                                                type="number"
                                                variant='outlined'
                                                color='secondary'
                                                label="Latitude"
                                                onChange={e => setLat(e.target.value)}
                                                value={lat}
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                type="number"
                                                variant='outlined'
                                                color='secondary'
                                                label="Longitude"
                                                onChange={e => setLng(e.target.value)}
                                                value={lng}
                                                fullWidth
                                                required
                                            />
                                            <TextField
                                                type="number"
                                                variant='outlined'
                                                color='secondary'
                                                label="Altitude"
                                                value={alt}
                                                fullWidth
                                                required
                                            />
                                            <Button fullWidth variant="outlined" color="secondary" type="submit">Get Altitude</Button>
                                        </Stack>
                                    </form>
                                </React.Fragment>
                            </Box>

                        </Box>
                    </Box>
                </React.Fragment>
            ) : (null)}
        </Box>


        )
    }
    const handleSearch = async () => {
        console.log("Searching for: " + searchValue);
        let loc = await mainService.getLocationFromSearch(searchValue);
        handleSetView(loc.lat, loc.lng);
    }

    const handleSetView = (lat, long) => {
        mapRef.current.setView([lat, long], 18);
    }

    const getArtworkTitle = (artworkid) => {
        if (myArtworks.some(a => a.id === artworkid)) {
            let art = myArtworks.find(a => a.id === artworkid);
            let title = art.titles ? art.titles["English"] : art.title;
            return title;
        }
        else
            return "Artwork not found"

    }
    const renderOrganisations = () => {
        // sort organisations by name
        let orgs = sortOrganisationsAlphabetically ? [...myOrganisations].sort((a, b) => (a.name > b.name) ? 1 : -1) : [...myOrganisations].reverse();
        return (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
            <Box sx={{ mt: '16px', alignSelf: 'stretch', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="subtitle1" >Sort alphabetically</Typography>
                <Switch sx={{ mr: '30px' }} checked={sortOrganisationsAlphabetically} onChange={(e) => setSortOrganisationsAlphabetically(e.target.checked)} />
                {adminRole === 'master' ? (
                    <Button variant="contained" sx={{ m: '10px' }} onClick={handleCreateOrganisation}>Create New organisation</Button>
                ) : (null)}
            </Box>


            <Box sx={{ mt: '16px', flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px' }}>
                    {orgs.map((organisation) =>
                        <Box key={organisation.id} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography noWrap sx={{ mr: '8px', width: '40%' }}>{organisation.name}</Typography>
                            <Typography noWrap sx={{ mr: '8px' }} variant='caption'>{organisation.address}</Typography>
                            <Box sx={{ flexGrow: 2 }} />
                            <IconButton onClick={() => handleOrganisationEditClicked(organisation.id)}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>


        </Box>)
    }
    const renderExhibitions = () => {
        // sort exhibitions by name
        let exhibitions = sortExhibitionsAlphabetically ? [...myExhibitions].sort((a, b) => (a.name > b.name) ? 1 : -1) : [...myExhibitions].reverse();
        return (<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
            <Box sx={{ mt: '16px', alignSelf: 'stretch', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="subtitle1" >Sort alphabetically</Typography>
                <Switch sx={{ mr: '30px' }} checked={sortExhibitionsAlphabetically} onChange={(e) => setSortExhibitionsAlphabetically(e.target.checked)} />
                {adminRole === 'master' ? (
                    <Button variant="contained" sx={{ m: '10px' }} onClick={handleCreateExhibition}>Create New Exhibition</Button>
                ) : (null)}
            </Box>


            <Box sx={{ mt: '16px', flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px' }}>
                    {exhibitions.map((exhibition) =>
                        <Box key={exhibition.id} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography noWrap sx={{ mr: '8px', width: '40%' }}>{exhibition.titles ? exhibition.titles["en"] : ""}</Typography>
                            <Typography noWrap sx={{ mr: '8px' }} variant='caption'>{exhibition.address}</Typography>
                            <Box sx={{ flexGrow: 2 }} />
                            <IconButton onClick={() => handleExhibitionEditClicked(exhibition.id)}>
                                <EditIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </Box>


        </Box>)
    }


    //Effects
    useEffect(() => {

        //on mount
        console.log('Home mounted');
        if (location.state === "organisation")
            setTabValue(1);

        const uid = mainService.Uid();
        let safeEmail = mainService.safeEmail();
        console.log('safeEmail: ' + safeEmail);

        mainService.addDataListener("adminRoles/" + uid, handleAdminRoleChanged);
        // mainService.addDataListener("adminOrganisationRelationships/" + safeEmail, handleOrganisationRelationshipsChanged);
        mainService.addDataListener("adminArtworkRelationships/" + safeEmail, handleArtworkRelationshipsChanged);
        console.log('adding adminExhibitionRelationships listener    ' + "  adminExhibitionRelationships/" + safeEmail);
        mainService.addDataListener("adminExhibitionRelationships/" + safeEmail, handleExhibitionRelationshipsChanged);


        mainService.addDataListener("artworkLocations", handleArtworkLocationsChanged);
        mainService.getThisUserProfileImageThumb((url) => {
            if (url && url!== "") {
                setProfileImgUrl(url);
            }
        });

        //on unmount
        return () => {
            console.log('Home unmounted');
            mainService.removeDataListener("adminRoles/" + uid, handleAdminRoleChanged);
            // mainService.removeDataListener("adminOrganisationRelationships/" + safeEmail, handleOrganisationRelationshipsChanged);
            mainService.removeDataListener("artworkLocations", handleArtworkLocationsChanged);

            if (adminRole === 'master') {
                mainService.removeDataListener("artworkMetadatas", handleArtworksChanged);
                mainService.removeDataListener("organisationMetadatas", handleOrganisationsChanged);
                mainService.removeDataListener("exhibitionMetadatas", handleExhibitionsChanged);
            }
            else {
                // mainService.removeDataListener("adminOrganisationRelationships/" + safeEmail, handleOrganisationRelationshipsChanged);
            }
            mainService.removeDataListener("adminExhibitionRelationships/" + safeEmail, handleExhibitionRelationshipsChanged);
            mainService.removeDataListener("adminArtworkRelationships/" + safeEmail, handleArtworkRelationshipsChanged);


        }

    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }

    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const MapController = () => {

        const map = useMapEvents({
            dblclick(e) {
                // startYNDialogAction(e, "Add artwork here?", handleAddLocation);
            },
        });

        const handleLocate = () => {
            map.locate();
            map.flyTo(userLocation, 18);
        }


        return (
            <IconButton onClick={() => handleLocate()} sx={{ position: 'absolute', right: '10px', top: '20px', zIndex: 500, }}>
                <MyLocationIcon sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></MyLocationIcon>
            </IconButton>
        );
    }



    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                    <TopBar/>
                    

                    <Box sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                            <Tab label="Experiences" />
                            {/* {adminRole === 'master' || myArtworks.length > 0 ? (<Tab label="Artworks" />) : (null)} */}
                            <Tab label="Organisations" />
                            <Tab label="Exhibitions" />
                            {/* {adminRole === 'master' ? (<Tab label="Exhibition Info" />) : (null)} */}

                        </Tabs>
                    </Box>


                    {tabValue === 0 ?
                        renderArtworks()
                        : tabValue === 1 ?
                            renderOrganisations()
                            : tabValue === 2 ?
                                renderExhibitions()
                                // : tabValue === 3 ?
                                // renderExhibitionInfo()
                                : (null)}
                    {renderYNDialog()}



                </Box>
            )}
        </ThemeProvider>
    );
}

export default Home;

