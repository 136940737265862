import './App.css';
import * as React from 'react';

import { HashRouter as Router } from 'react-router-dom';

import { ThemeProvider, createTheme } from  '@mui/system';
import theme from './theme';

import MyRoutes from './MyRoutes';

import MainContext from "./MainContext"; 
import MainService from "./MainService"; 

import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {
	//TODO: Add light mode switch
	// const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    // const colorMode = React.useMemo(
    //   () => ({
    //     toggleColorMode: () => {
    //       setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    //     },
    //   }),
    //   [],
    // );
  
    // const theme = React.useMemo(
    //   () =>
    //     createTheme({
    //       palette: {
    //         mode,
    //       },
    //     }),
    //   [mode],
    // );

	return (
		
	<MainContext.Provider value= {new MainService()}>
		{/* <ColorModeContext.Provider value={colorMode}> */}
		<ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex',  backgroundColor:'#ff7070', color:'black', p:1 }}>
                Console is deprecated. Please use the new &nbsp;<a href="https://app.meadow.space"> Web App</a>.
                </Box>
			<CssBaseline />
			<MyRoutes />
		</ThemeProvider>
		{/* </ColorModeContext.Provider> */}
	</MainContext.Provider>
	);
}

export default App;


