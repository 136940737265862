import React, {useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import theme from '../theme';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';


export default function SignIn() {

	//Use
	const mainService = useContext(MainContext);
	const navigate = useNavigate(); 

	//State
	const [signInButtonDisabled, setSignInButtonDisabled] = useState(false);
	const [authState, setAuthState] = useState(mainService.GetAuthed());
	const [errorMessage, setErrorMessage] = useState("");

	const [EmailNotVerifiedYet, setEmailNotVerifiedYet] = useState(false);
	const [currFormData, setCurrFormData] = useState({});

	//Callbacks
    const handleSubmit = (event) => {
		console.log('submit'); 

        event.preventDefault();

		setSignInButtonDisabled(true); 

		const data = new FormData(event.currentTarget);
        let obj = {
            email: data.get('email'),
            password: data.get('password'),
			remember: data.get('remember') === 'remember',
        };
		setCurrFormData(obj);

		mainService.SignInUser(obj.email, obj.password, obj.remember)
		.then(()=>{
			console.log('signed in');
			navigate('/'); 
			setSignInButtonDisabled(false); 
		})
		.catch((error) => {
			console.log("error message:" + error.message); 
			setErrorMessage("Failed to sign in: " + error.message);
			setSignInButtonDisabled(false); 
			setEmailNotVerifiedYet(error.code === 'EmailNotVerifiedYet');
		}); 
    };

	const handleResendVerificationEmail = () => {
		if(!currFormData || !currFormData.email || !currFormData.password)
		{
			setErrorMessage("Failed to resend email: No email or password");
			return;
		}
		setSignInButtonDisabled(true); 
		setErrorMessage("");


		mainService.ResendEmailVerification(currFormData.email, currFormData.password).then(()=>{
            navigate('/verifyemail'); 
			setEmailNotVerifiedYet(false);
			setSignInButtonDisabled(false);
		}).catch((error)=>{
			console.log("error message:" + error.message);
			setErrorMessage("Failed to resend email: " + error.message);
			setSignInButtonDisabled(false);
		});
	}


	const handleAuthStateChanged=()=>{
		setAuthState( mainService.GetAuthed() );
		if(mainService.authed === MainService.AUTHED){
			navigate('/'); 
			setSignInButtonDisabled(false); 
		}
	}

	//Effects
	useEffect(() => {

		//on mount
		console.log('Signup mounted'); 
		mainService.AddAuthChangeCallback(handleAuthStateChanged); 

		//on unmount
		return ()=>{
			console.log('Signup unmounted');
			mainService.RemoveAuthChangeCallback(handleAuthStateChanged); 
		}

	}, []);

	//Render
  	return (
	<ThemeProvider theme={theme}>
					<Button sx={{position: 'fixed',top: '20px',right: '20px'}} onClick={() => navigate({ pathname: '/guide' })} >Guide</Button>
					<Button sx={{position: 'fixed',top: '20px',right: '80px'}} onClick={() => navigate({ pathname: '/faq' })} >FAQ</Button>

	{authState === MainService.NOTAUTHED ? (
		<React.Fragment>
			<Typography sx={{m:'64px'}} align='center' variant='h4'>Meadow Console</Typography>
			<Container component="main" maxWidth="xs">
		
			<Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
				Sign in
				</Typography>
				<Typography sx={{mt:'32px', color:'rgb(255, 50, 50)'}}>{errorMessage}</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
					/>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
						name="remember"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
						disabled={signInButtonDisabled}
					>
					Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Link href="resetpassword" variant="body2">
							Reset password?
							</Link>
						</Grid>

						<Grid item>
							<Link href="/signup" variant="body2">
							{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item xs sx={{mt: '20px'}}>
						{EmailNotVerifiedYet && <Link onClick={() => handleResendVerificationEmail()}>Resend verification email</Link>}
						</Grid>
					</Grid>
				</Box>
			</Box>
			</Container>
		</React.Fragment>
	):(
		<Box sx={{marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
			<CircularProgress />
		</Box>
	)}
	</ThemeProvider>
	
	);
}