import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import MainService from "../MainService";
import MainContext from "../MainContext";
import TopBar from "../components/TopBar";

import { minWidth, ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IconButton, FormControl, InputLabel, Fab, ToggleButton, Tooltip, Checkbox } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Switch from '@mui/material/Switch';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CodeIcon from '@mui/icons-material/Code';
import InfoIcon from '@mui/icons-material/Info';
import { MuiColorInput } from 'mui-color-input'

import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import SearchIcon from '@mui/icons-material/Search';

import Credits from "../components/Credits";
import BundleUpload from "../components/BundleUpload";

import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, CircleMarker } from 'react-leaflet'
import { set } from "firebase/database";
import { grey } from "@mui/material/colors";
import { dark } from "@mui/material/styles/createPalette";
import L from 'leaflet';
import artMarker from '../assets/artwork-marker.svg';
import ImageUpload from "../components/ImageUpload";

const markerIcon = new L.Icon({
    iconUrl: artMarker,
    iconRetinaUrl: artMarker,
    iconAnchor: [25, 50],
    iconSize: new L.Point(50, 50)
});

const EditExperience = (props) => {
    //use 
    const mainService = useContext(MainContext);
    const navigate = useNavigate();
    const mapRef = useRef();

    //states
    const [loadingMode, setLoadingMode] = useState(false);
    const [uploadingContent, setUploadingContent] = useState(false);
    const [triggeredFunction, setTriggeredFunction] = useState(false);
    const [adminRole, setAdminRole] = useState('collab');
    const [searchParams] = useSearchParams();

    const [tabValue, setTabValue] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [selectedControlData, setSelectedControlData] = useState('');
    const [controlData, setControlData] = useState();
    const [credits, setCredits] = useState([]);
    const [bundlePlatform, setBundlePlatform] = useState('MacOS');
    const [hasBundle, setHasBundle] = useState(new Set());
    const [bundleMetadatas, setBundleMetadatas] = useState({});
    const [thumbnailURL, setThumbnailURL] = useState('');
    const [thumbnailKey, setThumbnailKey] = useState('');
    const [userLocation, setUserLocation] = useState([51.505, -0.09]);
    const [dictVal, setDictVal] = useState();
    const [editingDictKey, setEditingDictKey] = useState('');
    const [editingDictKeyVal, setEditingDictKeyVal] = useState('');
    const [dictKey, setDictKey] = useState('');
    const [editingCloudFunctionArg, setEditingCloudFunctionArg] = useState('');
    const [editingCloudFunctionName, setEditingCloudFunctionName] = useState('');
    const [editingCloudCursorPosition, setEditingCloudCursorPosition] = useState(0);
    //#region Artwork Metadata
    const [title, setTitle] = useState({});
    const [author, setAuthor] = useState('');
    const [overrideAuthor, setOverrideAuthor] = useState('');
    const [shortDescription, setShortDescription] = useState({});
    const [longDescription, setLongDescription] = useState({});
    const [perceivableDistance, setPerceivableDistance] = useState('');
    const [videoURL, setVideoUrl] = useState('');
    const [placementType, setPlacementType] = useState('Geospatial');
    const [vps, setVPS] = useState('GoogleVPS');
    const [isTest, setIsTest] = useState(false);
    const [accentColor, setAccentColor] = useState('#000000');
    const [liveInApp, setLiveInApp] = useState(false);
    const [availableEverywhere, setAvailableEverywhere] = useState(false);
    const [geocodeLocations, setGeocodeLocations] = useState(false);
    const [experienceTime, setExperienceTime] = useState("");
    const [headphonesRecommended, setHeadphonesRecommended] = useState(false);
    const [ownerInfo, setOwnerInfo] = useState({});
    const [iOS, setiOS] = useState(false);
    const [android, setAndroid] = useState(false);
    const [visionOS, setVisionOS] = useState(false);
    const [ovr, setOVR] = useState(false);

    //#endregion

    // const [cloudFunctionConfigs, setCloudFunctionConfigs] = useState([]);

    const [artworkLocations, setArtworkLocations] = useState([]);

    const [collaborators, setCollaborators] = useState([]);
    const [collabEmailEdit, setCollabEmailEdit] = useState("");

    const [realtimeDataRules, setRealtimeDataRules] = useState([]);
    const [locationDataRules, setlocationDataRules] = useState([]);
    const [cloudFunctions, setCloudFunctions] = useState([]);

    const [newDataRulePropId, setNewDataRulePropId] = useState("");
    const [newCloudFunction, setNewCloudFunction] = useState("createArtworkLocationPairs");


    let id = searchParams.get('id');

    //callbacks
    const handleAdminRoleChanged = (role) => {
        setAdminRole(role);
    }

    const handleArtworkMetadataChanged = (metadata) => {

        if (!metadata) {
            navigate('/');
            return;
        }


        if (metadata.titles)
            setTitle(metadata.titles);
        else {
            //old description format. set to english
            let t = { ...title };
            t["English"] = metadata.title;
            setTitle(t);
        }
        if (metadata.shortDescriptions)
            setShortDescription(metadata.shortDescriptions);
        else {
            //old description format. set to english
            let t = { ...shortDescription };
            t["English"] = metadata.shortDescription;
            setShortDescription(t);
        }
        if (metadata.longDescriptions)
            setLongDescription(metadata.longDescriptions);
        else {
            let t = { ...longDescription };
            t["English"] = metadata.longDescription;
            //make correct for react
            setLongDescription(t);
        }

        setCredits(metadata.creditsList);

        setAuthor(metadata.author);
        setVideoUrl(metadata.videoURL);
        setPerceivableDistance(metadata.perceivableDistance);
        setExperienceTime(metadata.experienceTime);
        setHeadphonesRecommended(metadata.headphonesRecommended);
        setVPS(metadata.vps);

        if(metadata.platforms){
            setiOS(metadata.platforms.iOS);
            setAndroid(metadata.platforms.Android);
            setVisionOS(metadata.platforms.VisionOS);
            setOVR(metadata.platforms.OVR);
        }

        if (metadata.placementType)
            setPlacementType(metadata.placementType);

        let newHasBundle = new Set(hasBundle.values());

        if (metadata.bundleKey_Windows) {
            newHasBundle.add('Windows');
            getBundleMetadata('artwork', 'Windows', metadata.bundleKey_Windows);
        }
        else
            newHasBundle.delete('Windows');

        if (metadata.bundleKey_Android) {
            newHasBundle.add('Android');
            getBundleMetadata('artwork', 'Android', metadata.bundleKey_Android);

        }
        else
            newHasBundle.delete('Android');

        if (metadata.bundleKey_IOS) {
            newHasBundle.add('IOS');
            getBundleMetadata('artwork', 'IOS', metadata.bundleKey_IOS);
        }
        else
            newHasBundle.delete('IOS');

        if (metadata.bundleKey_MacOS) {
            newHasBundle.add('MacOS');
            getBundleMetadata('artwork', 'MacOS', metadata.bundleKey_MacOS);
        }
        else
            newHasBundle.delete('MacOS');

        //! Update this 
        if(metadata.bundleKey_VisionOS)
        {
            newHasBundle.add('VisionOS');
            getBundleMetadata('artwork', 'VisionOS', metadata.bundleKey_VisionOS);
        }
        if(metadata.bundleKey_VisionOS_Metabundle)
        {
            newHasBundle.add('VisionOS_Metabundle');
            getBundleMetadata('artwork', 'VisionOS_Metabundle', metadata.bundleKey_VisionOS_Metabundle);
        }



        if (metadata.bundleKey_Windows_Metabundle) {
            newHasBundle.add('Windows_Metabundle');
            getBundleMetadata('artwork', 'Windows_Metabundle', metadata.bundleKey_Windows_Metabundle);
        }
        else
            newHasBundle.delete('Windows_Metabundle');

        if (metadata.bundleKey_Android_Metabundle) {
            newHasBundle.add('Android_Metabundle');
            getBundleMetadata('artwork', 'Android_Metabundle', metadata.bundleKey_Android_Metabundle);
        }
        else
            newHasBundle.delete('Android_Metabundle');

        if (metadata.bundleKey_IOS_Metabundle) {
            newHasBundle.add('IOS_Metabundle');
            getBundleMetadata('artwork', 'IOS_Metabundle', metadata.bundleKey_IOS_Metabundle);
        }
        else
            newHasBundle.delete('IOS_Metabundle');

        if (metadata.bundleKey_MacOS_Metabundle) {
            newHasBundle.add('MacOS_Metabundle');
            getBundleMetadata('artwork', 'MacOS_Metabundle', metadata.bundleKey_MacOS_Metabundle);
        }
        else
            newHasBundle.delete('MacOS_Metabundle');

        setAccentColor(metadata.accentColor);


        setHasBundle(newHasBundle);

        setLiveInApp(metadata.liveInApp ? true : false);
        setIsTest(metadata.isTest ? true : false);
        setGeocodeLocations(metadata.geocodeLocations ? true : false);
        setAvailableEverywhere(metadata.availableEverywhere ?? false);
        setOverrideAuthor(metadata.overrideAuthor ?? false);
        

    console.log("owner info: " + JSON.stringify(metadata.owner));

        if (metadata.owner && (!ownerInfo || ownerInfo.id !== metadata.owner)) {
            console.log("Getting owner info for: " + metadata.owner + " artwork: " + id);
            mainService.getUserMetadata(metadata.owner).then((user) => {
                let udata = {
                    id: metadata.owner,
                    name: user.firstname + " " + user.lastname,
                    username: user.username
                }
                setOwnerInfo(udata);
            });
        }
    }

    const getBundleMetadata = (type, plafromAndType, key) => {
        mainService.getBundleMetadata(type, id, plafromAndType, key).then((metadata) => {
            //add to bundleMetadatas
            setBundleMetadatas((prev) => {
                let newBundleMetadatas = { ...prev };
                newBundleMetadatas[plafromAndType] = metadata;
                return newBundleMetadatas;
            });

        })
            .catch((error) => {
                console.error("Error getting bundle metadata: " + error);
            });

    }

    const handleArtworkCollaboratorsChanged = (collaborators) => {

        if (!collaborators) {
            navigate('/');
            return;
        }

        let collabs = [];
        if (collaborators) {
            for (const collab in collaborators) {
                collabs.push({ safeEmail: collab, role: collaborators[collab] });
            }
        }
        setCollaborators(collabs);
    }

    const handleArtworkLocationsChanged = (artworkLocations) => {
        let locs = [];

        if (artworkLocations) {
            for (const locid in artworkLocations) {
                if (!locid)
                    continue;
                let loc = artworkLocations[locid];
                loc.locid = locid;
                if (loc.lat && loc.lng)
                    locs.push(loc);
                else
                    console.error("Artwork " + id + " location missing lat/lng. locid: " + locid);
            }
        }

        setArtworkLocations(locs);
    }



    const handleArtworkDelete = (event) => {
        console.log("Artwork delete!!");
        mainService.deleteArtwork(id)
            .then(() => {
                navigate('/');
            })
            .catch((err) => {
                console.log(err);
            })
    }



    const handleEditTitle = (event) => {
        let val = event.target.value;
        let key = "titles/" + selectedLanguage;
        mainService.setArtworkMetadataProperty(id, key, val);
    }

    const handleEditOverrideAuthor = (event) => {
        let val = event.target.checked;
        if(val)
        {
            startYNDialogAction(event, "Are you sure you want to override author? There will be no link between author and their user profile. If the author has a user profile, change the owner on 'Collaborators' tab.", ()=>{mainService.setArtworkMetadataProperty(id, "overrideAuthor", val);});
        }
        else
        mainService.setArtworkMetadataProperty(id, "overrideAuthor", val);
    }

    const handleEditAuthor = (event) => {
        let val = event.target.value;
        mainService.setArtworkMetadataProperty(id, "author", val);
    }

    const handleEditShortDescription = (event) => {
        let val = event.target.value;
        let key = "shortDescriptions/" + selectedLanguage;
        mainService.setArtworkMetadataProperty(id, key, val);
    }
    const handleEditLongDescription = (event) => {
        let val = event.target.value;
        let key = "longDescriptions/" + selectedLanguage;
        mainService.setArtworkMetadataProperty(id, key, val);
    }

    const handleEditPerceivableDistance = (event) => {
        let val = event.target.value;
        mainService.setArtworkMetadataProperty(id, "perceivableDistance", val);
    }
    const handleSetPlacementType = (event) => {
        let val = event.target.value;
        mainService.setArtworkMetadataProperty(id, "placementType", val);
    }
    const handleSetVPS = (event) => {
        let val = event.target.value;
        mainService.setArtworkMetadataProperty(id, "vps", val);
    }
    const handleSetIOS = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "platforms/iOS", val);
    }
    const handleSetAndroid = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "platforms/Android", val);
    }
    const handleSetVisionOS = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "platforms/VisionOS", val);
    }
    const handleSetOVR = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "platforms/OVR", val);
    }

    const handleEditVideoURL = (event) => {
        let val = event.target.value;
        mainService.setArtworkMetadataProperty(id, "videoURL", val);
    }

    const handleEditLiveInApp = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "liveInApp", val);
        mainService.setLiveArtwork(id, val);
    }
    const handleEditIsTest = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "isTest", val);
    }
    const handleEditGeocodeLocations = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "geocodeLocations", val);
    }

    const handleChangeAccentColor = (color) => {
        console.log('color change ' + color);
        mainService.setArtworkMetadataProperty(id, "accentColor", color);
    }
    const handleEditExperienceTime = (event) => {
        //make sure it's a number
        let val = Number(event.target.value);
        if (isNaN(val))
            return;

        mainService.setArtworkMetadataProperty(id, "experienceTime", val);
    }

    const handleEditHeadphonesRecommended = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "headphonesRecommended", val);
    }

    const handleEditAvailableEverywhere = (event) => {
        let val = event.target.checked;
        mainService.setArtworkMetadataProperty(id, "availableEverywhere", val);
    }

    //#region Bundle Handlers
    const handleBundleUpload = (event) => {
        console.log('trying to upload');
        let platform = event.platform;
        let bundleType = event.bundleType;
        if (event.target.files.length === 0)
            return;
        console.log('handling bundle upload ' + platform + ' ' + bundleType);
        setUploadingContent(true);
        mainService.uploadBundle(id, 'artwork', event.target.files[0], platform, bundleType)
            .then(() => {
                setUploadingContent(false);
            });
    }
    const handleBundleDelete = (e) => {
        console.log('bundle delete ' + e.platform + ' ' + e.bundleType);
        setUploadingContent(true);
        mainService.deleteBundle(id, 'artwork', e.platform, e.bundleType)
            .then(() => {
                setUploadingContent(false);
            })
    }

    //#endregion


    const handleAddLocation = (e) => {
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        mainService.addArtworkLocation(id, lat, lng, geocodeLocations);
    }

    const handleDeleteLocation = (locationid) => {
        mainService.deleteArtworkLocation(id, locationid);
    }


    const handleAddCollaborator = () => {
        console.log("Adding collaborator");
        mainService.addArtworkCollaborator(id, collabEmailEdit);
        setCollabEmailEdit("");
    }

    const handleDeleteCollaborator = (e) => {
        console.log("Deleting collaborator");
        mainService.removeArtworkCollaborator(id, e.collabEmail);
    }


    const handleRealtimeDataRulesChanged = (datarules) => {

        let newRealtimeDataRules = [];
        if (datarules) {
            Object.keys(datarules).forEach((id) => {
                let rule = datarules[id];
                rule.propid = id;
                newRealtimeDataRules.push(rule);
            });
        }
        if (selectedControlData) {
            let newSelectedControlData = newRealtimeDataRules.find((rule) => rule.propid === selectedControlData.propid);
            if (newSelectedControlData) {
                console.log("Found selected control data");
                setSelectedControlData(newSelectedControlData);
            }
        }
        setRealtimeDataRules(newRealtimeDataRules);
    }
    const handleLocationDataRulesChanged = (datarules) => {

        let newLocationDataRules = [];
        if (datarules) {
            Object.keys(datarules).forEach((id) => {
                let rule = datarules[id];
                rule.propid = id;
                newLocationDataRules.push(rule);
            });
        }
        setlocationDataRules(newLocationDataRules);
    }
    const handleCloudFunctionsChanged = (cloudFunctions) => {

        let newCloudFunctions = [];
        if (cloudFunctions) {
            Object.keys(cloudFunctions).forEach((name) => {
                let f = cloudFunctions[name];
                f.name = name;
                if (f.lastTriggered) {
                    f.lastTriggered = new Date(f.lastTriggered).toString();
                }
                // if(f.name === "clearRealtimeData" && f.config)
                // setCloudFuncRealtimeProperties(f.config);

                newCloudFunctions.push(f);
            });
        }
        setCloudFunctions(newCloudFunctions);
    }

    const handleAddRealtimeDataRule = (propertyid) => {
        if (!propertyid)
            return;

        let isVarName = (str) => {
            if (typeof str !== 'string') {
                return false;
            }
            if (str.trim() !== str) {
                return false;
            }
            try {
                new Function(str, 'var ' + str);
            } catch (_) {
                return false;
            }
            return true;
        }

        if (!isVarName(propertyid)) {
            return;
        }

        mainService.setRealtimeDataRule(id, propertyid, "number");
        setNewDataRulePropId("");
    }

    const handleEditRealtimeDataRule = (propertyid, value) => {
        mainService.setRealtimeDataRule(id, propertyid, value);
    }

    const handleRemoveRealtimeDataRule = (propertyid) => {
        if (selectedControlData === propertyid) {
            setSelectedControlData('');
            setControlData(null);
        }
        mainService.setRealtimeDataRule(id, propertyid, null);
    }

    const handleClearRealtimeData = () => {
        mainService.clearRealtimeData(id);
    }

    const handleAddLocationDataRule = (propertyid) => {
        if (!propertyid)
            return;

        let isVarName = (str) => {
            if (typeof str !== 'string') {
                return false;
            }
            if (str.trim() !== str) {
                return false;
            }
            try {
                new Function(str, 'var ' + str);
            } catch (_) {
                return false;
            }
            return true;
        }

        if (!isVarName(propertyid)) {
            return;
        }

        mainService.setLocationDataRuleType(id, propertyid, "number");
        setNewDataRulePropId("");
    }

    const handleAddCloudFunction = (cloudFunction) => {
        if (!cloudFunction)
            return;
        if (cloudFunctions.find((f) => f.name === cloudFunction)) {
            console.log("Cloud function already exists");
            return;
        }

        var data = {
            schedule: "none",
            config: {
                executionOrder: 0
            }
        }

        mainService.setArtworkCloudFunction(id, cloudFunction, data);
    }


    const handleEditLocationDataRuleType = (propertyid, value) => {
        mainService.setLocationDataRuleType(id, propertyid, value);
    }
    const handleEditLocationDataRuleTooltip = (propertyid, value) => {
        mainService.setLocationDataRuleTooltip(id, propertyid, value);
    }

    const handleRemoveLocationDataRule = (propertyid) => {
        mainService.setLocationDataRule(id, propertyid, null);
    }
    const handleRemoveCloudFunction = (name) => {
        mainService.setArtworkCloudFunction(id, name, null);
    }
    const handleChangeCloudFunctionData = (name, schedule) => {
        //get curernt data
        var funcData = cloudFunctions.find((f) => f.name === name);
        var data = { ...funcData };
        delete data.name;

        data.schedule = schedule;



        mainService.setArtworkCloudFunction(id, name, data);
    }
    const handleEditCloudFunctionConfig = (name, property, config, cursorPosition) => {
        setEditingCloudFunctionArg(property);
        setEditingCloudFunctionName(name);
        setEditingCloudCursorPosition(cursorPosition);
        mainService.setArtworkCloudFunctionConfig(id, name, property, config);
    }
    const handleTriggerCloudFunction = (functionName, config) => {
        setTriggeredFunction(functionName);
        mainService.triggerCloudFunction(id, functionName, config, (msg) => {
            setTriggeredFunction('');
        });

    }
    const handleCloudFuncitonEnabled = (functionName, enabled) => {
        mainService.setArtworkCloudFunctionEnabled(id, functionName, enabled);
    }

    const handleControlDataChanged = (data) => {
        setControlData(data);
    }

    const handleChangeSelectedControlData = (data) => {
        //add a listener to the selected control data
        if (data) {
            if (selectedControlData !== '') {
                //remove the listener from the previous selected control data
                mainService.removeDataListener("realtimeData/" + id + "/global/all/" + selectedControlData, handleControlDataChanged);
            }
            //add the listener to the new selected control data
            mainService.addDataListener("realtimeData/" + id + "/global/all/" + data, handleControlDataChanged);

            setSelectedControlData(data);
        }
    }


    const handleEditControlData = (value) => {
        if (selectedControlData) {
            let rule = realtimeDataRules.find(x => x.propid === selectedControlData);
            let newData = controlData;

            //try convert data to rule.type
            if (rule.type === "number") {
                newData = value ? parseFloat(value) : null;
            }
            else if (rule.type === "boolean") {
                newData = value ? true : false;
            }
            else if (rule.type === "string") {
                newData = value.toString();
            }
            else if (rule.type === "vec2" || rule.type === "vec3" || rule.type === "vec4") {
                if (!newData) {
                    if (rule.type === "vec2")
                        newData = { x: 0, y: 0 };
                    else if (rule.type === "vec3")
                        newData = { x: 0, y: 0, z: 0 };
                    else if (rule.type === "vec4")
                        newData = { x: 0, y: 0, z: 0, w: 0 };
                }
                if (value.x)
                    newData.x = parseFloat(value.x);
                if (value.y)
                    newData.y = parseFloat(value.y);
                if (value.z)
                    newData.z = parseFloat(value.z);
                if (value.w)
                    newData.w = parseFloat(value.w);
            }
            else if (rule.type === "list") {
                if (value.command === "editValue") {

                    mainService.setGlobalRealtimeList(id, selectedControlData, value.key, value.value);
                }
                else if (value.command === "editKey") {
                    console.log("prev key: " + value.prevKey + " new key: " + value.key + " value: " + value.value);
                    mainService.setGlobalRealtimeList(id, selectedControlData, value.prevKey, null);
                    mainService.setGlobalRealtimeList(id, selectedControlData, value.key, value.value);
                    setEditingDictKey('');
                    setDictKey('');
                }
                else if (value.command === "push") {
                    mainService.pushGlobalRealtimeDict(id, selectedControlData, value.value);
                    setDictVal('');
                }
                return;
            }
            if (((value === undefined) || (rule.type === "number" && isNaN(value))) && value !== null) {
                console.error("error converting data to type:" + rule.type);
                return;
            }
            console.log("setGlobalRealtimeData: " + selectedControlData + " value: " + JSON.stringify(newData));
            mainService.setGlobalRealtimeData(id, selectedControlData, newData);
        }
    }




    const handleSetView = (lat, long) => {
        mapRef.current.setView([lat, long], 18);
    }


    //Effects
    useEffect(() => {

        //on mount
        console.log('EditExperience mounted');
        let uid = mainService.Uid();
        let safeEmail = mainService.safeEmail();

        mainService.addDataListener("adminRoles/" + uid, handleAdminRoleChanged);

        if (id) {
            mainService.addDataListener("artworkMetadatas/" + id, handleArtworkMetadataChanged);
            mainService.addDataListener("artworkCollaborators/" + id, handleArtworkCollaboratorsChanged);
            mainService.addDataListener("artworkLocations/" + id, handleArtworkLocationsChanged);
            mainService.addDataListener("realtimeDataRules/" + id, handleRealtimeDataRulesChanged);
            mainService.addDataListener("locationDataRules/" + id, handleLocationDataRulesChanged);
            mainService.addDataListener("artworkCloudFunctions/" + id, handleCloudFunctionsChanged);
        }
        else {
            navigate('/');
        }

        //on unmount
        return () => {
            console.log('EditExperience unmounted');
            mainService.removeDataListener("adminRoles/" + uid, handleAdminRoleChanged);
            if (id) {
                mainService.removeDataListener("artworkMetadatas/" + id, handleArtworkMetadataChanged);
                mainService.removeDataListener("artworkCollaborators/" + id, handleArtworkCollaboratorsChanged);
                mainService.removeDataListener("artworkLocations/" + id, handleArtworkLocationsChanged);
                mainService.removeDataListener("realtimeDataRules/" + id, handleRealtimeDataRulesChanged);
                mainService.removeDataListener("locationDataRules/" + id, handleLocationDataRulesChanged);
                mainService.removeDataListener("artworkCloudFunctions/" + id, handleCloudFunctionsChanged);
                if (selectedControlData) {
                    mainService.removeDataListener("realtimeData/" + id + "/global/all/" + selectedControlData, handleControlDataChanged);
                }
            }
        }

    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }

    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }


    const handleSearch = async () => {
        console.log("Searching for: " + searchValue);
        let loc = await mainService.getLocationFromSearch(searchValue);
        handleSetView(loc.lat, loc.lng);
    }

    const handleMakeOwner = (collabEmail) => {
        //let master change owner
        if (adminRole !== 'master') {
            //check if mainService.safeEmail() is owner and alert if not
            let ownerEmail = collaborators.find(c => c.role === 'owner').safeEmail;
            if (ownerEmail !== mainService.safeEmail()) {
                alert("Only the owner can change ownership");
                return;
            }
        }

        mainService.changeExperienceOwner(id, collabEmail);
    }


    //Render main info
    const renderMainInfo = () => {
        let owner = ownerInfo ? ((ownerInfo.username ?? "username not set ") + " (" + ownerInfo.name + ")") : "undefined";
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                    <Box sx={{ display: 'flex', flexGrow: 2, flexDirection: 'row' }}>
                        <Box sx={{ display: 'flex', m: '10px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                            <FormControl sx={{ m: '10px' }}>
                                <InputLabel id="select">Language</InputLabel>
                                <Select labelId="select" label="Language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                    <MenuItem value={"English"}>English</MenuItem>
                                    <MenuItem value={"Swedish"}>Swedish</MenuItem>
                                </Select>

                            </FormControl>
                            <TextField sx={{ m: '10px' }} label={"Title (" + selectedLanguage + ")"} variant="outlined" value={title[selectedLanguage] ?? ""} onChange={handleEditTitle} />
                            {/* <TextField disabled sx={{ m: '10px' }} label="Owner" variant="outlined" value={owner} /> */}
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField disabled={!overrideAuthor} sx={{ m: '10px', flex: 2 }} label="Author" variant="outlined" value={overrideAuthor ? author : owner} onChange={handleEditAuthor} />
                            <Switch sx={{ mr: '10px'}} checked={overrideAuthor} onChange={(e) => handleEditOverrideAuthor(e)} />
                            <Typography sx={{mr: '20px'}}>Override</Typography>
                            </Box>
                            <TextField sx={{ m: '10px' }} label="Video URL" variant="outlined" value={videoURL} onChange={handleEditVideoURL} />
                        </Box>
                        <ImageUpload sx={{ display: 'block', width: '100%' }}
                            title={title ? title["English"] : ""}
                            type="cover"
                            mainService={mainService}
                            id={id}
                            storagePath="artworkdata" />

                    </Box>

                    <Box sx={{ display: 'flex', m: '10px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>


                        <TextField multiline maxRows={4} inputProps={{ maxLength: 300 }} sx={{ m: '10px' }} label={"ShortDescription (max. 300 characters, " + selectedLanguage + ")"} variant="outlined" value={shortDescription[selectedLanguage] ?? ""} onChange={handleEditShortDescription} />
                        <TextField multiline sx={{ m: '10px', }} label={"LongDescription (" + selectedLanguage + ")"} variant="outlined" value={longDescription[selectedLanguage] ?? ""} onChange={handleEditLongDescription} />
                        <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center' }}>
                            <FormControl sx={{ m: '10px', flexGrow: 1 }}>
                                <InputLabel id="selectPlacement">Placement Type</InputLabel>
                                <Select labelId="selectPlacement" label="Placement Type" value={placementType} onChange={handleSetPlacementType}>
                                    <MenuItem value="Geospatial">Geospatial</MenuItem>
                                    <MenuItem value="InFrontOfCamera">In Front of Camera</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: '10px', flexGrow: 1 }} disabled={placementType !== "Geospatial"}>
                                <InputLabel id="selectVPS">VPS</InputLabel>
                                <Select labelId="selectVPS" label="VPS" value={vps} onChange={handleSetVPS}>
                                    <MenuItem value="GoogleVPS">Google Geospatial</MenuItem>
                                    <MenuItem value="Lightship">Niantic Lightship</MenuItem>
                                </Select>
                            </FormControl>

                        </Box>
                        <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField sx={{ m: '10px', flexGrow: 1 }} label="perceivable distance (m)" type="number" value={perceivableDistance} onChange={handleEditPerceivableDistance} ></TextField>

                            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                                <Switch sx={{ mr: '10px', my: '10px' }} checked={availableEverywhere} onChange={handleEditAvailableEverywhere} />
                                <Typography>Available Everywhere</Typography>
                                <Tooltip title="If checked, the experience will be available everywhere. If unchecked, the experience will only be available at the locations you add.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </Box>

                        <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                            {/* <MuiColorInput sx={{ m: '10px',flexGrow:1 }} label="Accent Color" value={accentColor} onChange={handleChangeAccentColor} /> */}
                            <Tooltip title="0 means it has no time limit">
                                <TextField sx={{ m: '10px', flexGrow: 1 }} label="Experience Time" type="number" value={experienceTime} onChange={handleEditExperienceTime} />

                            </Tooltip>
                            <Box sx={{ border: 1, borderColor: '#494949', borderRadius: "5px", px: "15px", display: 'flex', m: '10px', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center' }}>
                                Headphones Recommended
                                <Checkbox sx={{ alignSelf: 'right' }} checked={headphonesRecommended} onChange={handleEditHeadphonesRecommended} label="Headphones Recommended" />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ m: '10px', width: '100%', display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Credits dbRoot="artworkMetadatas" credits={credits} mainServiceRef={mainService} id={id} />
                    </Box>
                    <Box sx={{ display: 'flex', m: '10px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch sx={{ mr: '10px' }} checked={iOS} onChange={(e) => handleSetIOS(e)} />
                            <Typography>iOS</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch sx={{ mr: '10px' }} checked={android} onChange={(e) => handleSetAndroid(e)} />
                            <Typography>Android</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch disabled={adminRole !== 'master'} sx={{ mr: '10px' }} checked={visionOS} onChange={(e) => handleSetVisionOS(e)} />
                            <Typography>VisionOS</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch disabled={adminRole !== 'master'} sx={{ mr: '10px' }} checked={ovr} onChange={(e) => handleSetOVR(e)} />
                            <Typography>OVR</Typography>
                        </Box>


                        </Box>
                        <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignSelf: 'stretch', flexDirection: 'row', alignItems: 'center' }}>

                            <FormControl sx={{ m: '10px', width: "30%" }}>
                                <InputLabel id="select">Bundle Platform</InputLabel>
                                <Select labelId="select" label="Bundle Platform" value={bundlePlatform} onChange={(e) => setBundlePlatform(e.target.value)}>
                                    {iOS && (<MenuItem value="IOS">IOS</MenuItem>)}
                                    {android && (<MenuItem value="Android">Android</MenuItem>)}
                                    <MenuItem value="Windows">Windows</MenuItem>
                                    <MenuItem value="MacOS">MacOS</MenuItem>
                                    {visionOS && (<MenuItem value="VisionOS">VisionOS</MenuItem>)}
                                    {ovr && (<MenuItem value="OVR">OVR</MenuItem>)}
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', m: '10px' }}>
                                <BundleUpload platform={bundlePlatform} bundleMetadatas={bundleMetadatas} hasBundle={hasBundle} isUploading={uploadingContent}
                                    onUploadClick={handleBundleUpload} onDeleteClick={(e) => { startYNDialogAction(e, "Are you sure you want to delete the bundle?", handleBundleDelete); }} />
                                <BundleUpload platform={bundlePlatform} bundleType='Metabundle' bundleMetadatas={bundleMetadatas} hasBundle={hasBundle} isUploading={uploadingContent}
                                    onUploadClick={handleBundleUpload} onDeleteClick={(e) => { startYNDialogAction(e, "Are you sure you want to delete the bundle?", handleBundleDelete); }} />
                            </Box>
                        </Box>

                    </Box>





                    <Box sx={{ display: 'flex', m: '10px', flexDirection: 'row', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch sx={{ mr: '10px' }} checked={liveInApp} onChange={(e) => handleEditLiveInApp(e)} />
                            <Typography>Live in app</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch sx={{ mr: '10px' }} checked={isTest} onChange={(e) => handleEditIsTest(e)} />
                            <Typography>Artwork is a test</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, flexShrink: 1, m: '10px', justifyContent: 'center', borderRadius: 1, border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Switch sx={{ mr: '10px' }} checked={geocodeLocations} onChange={(e) => handleEditGeocodeLocations(e)} />
                            <Typography>Geocode locations</Typography>
                        </Box>


                    </Box>
                    <Button variant="contained" sx={{ m: '10px', width: '100%' }} onClick={(e) => {
                        startYNDialogAction(e, "Are you sure you want to delete this artwork? This can't be undone!", handleArtworkDelete);
                    }}>Delete Experience</Button>



                </Box>
            </Box>
        )
    }
    //Render Map 
    const MapController = () => {

        const map = useMapEvents({
            dblclick(e) {
                startYNDialogAction(e, "Add artwork here?", handleAddLocation);
            },
        });

        const handleLocate = () => {
            map.locate();
            map.flyTo(userLocation, 18);
        }


        return (
            <IconButton onClick={() => handleLocate()} sx={{ position: 'absolute', right: '10px', top: '20px', zIndex: 500, }}>
                <MyLocationIcon sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></MyLocationIcon>
            </IconButton>
        );
    }

    const renderMap = () => {

        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '1200px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' }}>
                        <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                            value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                            InputProps={{ endAdornment: <IconButton onClick={() => handleSearch()}><SearchIcon /></IconButton> }} />
                    </Box>
                    <MapContainer ref={mapRef}
                        center={userLocation}
                        zoom={16}
                        scrollWheelZoom={true}
                        maxZoom={26}
                        doubleClickZoom={false}
                        inertia={true}
                        preferCanvas={true}
                        style={{ flexGrow: 1, margin: '10px', overflow: 'hidden', borderRadius: '8px' }}
                    >
                        <TileLayer
                            maxZoom={26}
                            maxNativeZoom={19}
                            url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                            subdomains={['mt1', 'mt2', 'mt3']}
                        />
                        <MapController />

                        <CircleMarker center={userLocation} radius={10} />


                        {artworkLocations.map((loc) =>
                            <Marker key={loc.locid} position={[loc.lat, loc.lng]} icon={markerIcon}>
                                <Popup>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography gutterBottom variant="subtitle2">Artwork Location</Typography>
                                        <Typography gutterBottom variant="subtitle">{"lat: " + loc.lat}</Typography>
                                        <Typography gutterBottom variant="subtitle">{"lng: " + loc.lng}</Typography>
                                        <Typography gutterBottom variant="subtitle">{"alt: " + (loc.alt ?? "null")}</Typography>
                                        <Button variant='contained' onClick={(e) => {
                                            startYNDialogAction(loc.locid, "Are you sure you want to remove this location?", handleDeleteLocation);
                                        }}>
                                            Delete Location
                                        </Button>
                                    </Box>
                                </Popup>
                            </Marker>
                        )}


                    </MapContainer>

                </Box>
            </Box>
        )
    }

    //Render Collaborators 
    const renderCollaborators = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                    {/* {adminRole === 'master' ? ( */}
                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '32px' }}>
                            <TextField sx={{ m: '10px', flexGrow: 1, minWidth: '150px' }} label="Email" variant="outlined"
                                value={collabEmailEdit} onChange={(e) => setCollabEmailEdit(e.target.value)} />

                            <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Add " + collabEmailEdit + " as a collaborator?", handleAddCollaborator);
                            }}>Add Collaborator</Button>

                        </Box>
                    </React.Fragment>
                    {/* ) : (null)} */}

                    {collaborators.map((collab) => (
                        <Box key={collab.safeEmail} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ mr: '8px' }}>{collab.safeEmail.replaceAll('%2E', '.')}</Typography>

                            <Box sx={{ flexGrow: 1 }} />
                            {collab.role === 'owner' ? (<Box sx={{ borderRadius: 1, bgcolor: 'grey', padding: '5px' }}>Owner</Box>) : (
                                <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => { handleMakeOwner(collab.safeEmail) }}>Make Owner</Button>
                            )}

                            {collab.safeEmail !== mainService.safeEmail() ? (
                                <IconButton onClick={(e) => {
                                    e.collabEmail = collab.safeEmail;
                                    startYNDialogAction(e, "Are you sure you want to remove this collaborator?", handleDeleteCollaborator);
                                }}>

                                    <DeleteForeverIcon />
                                </IconButton>
                            ) : (null)}

                        </Box>
                    ))}

                </Box>
            </Box>
        )
    }


    const renderRealtimeData = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>



                    <Box sx={{ display: 'flex', alignItems: 'stretch', mb: '64px' }}>
                        <Button variant="contained" sx={{ m: '10px' }} onClick={() => handleAddRealtimeDataRule(newDataRulePropId)}>Add Realtime Data Rule</Button>
                        <TextField sx={{ m: '10px', minWidth: '150px' }} label="Property ID" variant="outlined" value={newDataRulePropId}
                            onChange={(e) => setNewDataRulePropId(e.target.value)} />
                    </Box>


                    {realtimeDataRules.map((rule) => (
                        <Box key={rule.propid} sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', }}>

                            <Typography variant="h6" sx={{ flexGrow: 1, ml: '10px' }}>{rule.propid}</Typography>
                            <Select
                                sx={{ minWidth: '200px', m: '16px' }}
                                value={rule.type}
                                onChange={(e) => handleEditRealtimeDataRule(rule.propid, e.target.value)}
                            >
                                <MenuItem value={"number"}>number</MenuItem>
                                <MenuItem value={"string"}>string</MenuItem>
                                <MenuItem value={"boolean"}>boolean</MenuItem>
                                <MenuItem value={"vec2"}>vec2</MenuItem>
                                <MenuItem value={"vec3"}>vec3</MenuItem>
                                <MenuItem value={"vec4"}>vec4</MenuItem>
                                <MenuItem value={"list"}>list</MenuItem>
                            </Select>

                            <IconButton onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete this rule? This will also delete any existing data with this property id", () => handleRemoveRealtimeDataRule(rule.propid));
                            }}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Box>
                    ))}

                    <Typography variant="h6" sx={{ mt: '64px' }}>Global Control</Typography>
                    <Typography variant="subtitle1">Control the value of a property for all locations and users.</Typography>
                    <Typography variant="subtitle1">Make sure to input correct value type</Typography>
                    <FormControl sx={{ display: 'flex', flexWrap: 'wrap', my: '10px', maxWidth: "350px" }}>
                        <InputLabel id="controlledData">Data to control</InputLabel>
                        <Select labelId="controlledData" label="Data to control" value={selectedControlData} onChange={(e) => handleChangeSelectedControlData(e.target.value)}>
                            {realtimeDataRules.map((rule) => (
                                <MenuItem key={rule.propid} value={rule.propid}>{rule.propid}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selectedControlData ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
                            < ControlDataField />
                        </Box>
                    ) : (null)}

                    <Button sx={{ mt: '64px' }} variant="contained" onClick={(e) => {
                        startYNDialogAction(e, "Are you sure you want to clear all data for this artwork?", () => handleClearRealtimeData());
                    }}>
                        Clear All Data
                    </Button>
                </Box>
            </Box>
        );
    }
    const ControlDataField = () => {
        let ctrlData = controlData ?? null;
        let rule = realtimeDataRules.find((rule) => rule.propid === selectedControlData);


        if (rule.type === "number" || rule.type === "string") {
            return (
                <TextField type={rule.type === "number" ? "number" : "text"} autoFocus sx={{ m: '10px', minWidth: '150px' }} label="Value" variant="outlined" value={ctrlData !== null ? ctrlData : ""}
                    onChange={(e) => handleEditControlData(e.target.value)} />
            )
        }
        else if (rule.type === "boolean") {
            if (typeof ctrlData !== "boolean")
                ctrlData = false;
            return (
                <Switch sx={{ m: '10px' }} checked={ctrlData !== null ? ctrlData : false} onChange={(e) => handleEditControlData(e.target.checked ?? null)} />
            )
        }
        else if (rule.type === "vec2" || rule.type === "vec3" || rule.type === "vec4") {
            if (!ctrlData || !ctrlData.x)
                ctrlData = {};
            ctrlData.x = ctrlData && ctrlData.x ? ctrlData.x : 0;
            ctrlData.y = ctrlData && ctrlData.y ? ctrlData.y : 0;
            ctrlData.z = ctrlData && ctrlData.z ? ctrlData.z : 0;
            ctrlData.w = ctrlData && ctrlData.w ? ctrlData.w : 0;
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', m: '10px' }}>
                    <TextField type="number" autoFocus sx={{ m: '10px', minWidth: '150px' }} label="X" variant="outlined" value={ctrlData.x !== null ? ctrlData.x : ""}
                        onChange={(e) => handleEditControlData({ x: e.target.value, y: ctrlData.y })} />
                    <TextField type="number" autoFocus sx={{ m: '10px', minWidth: '150px' }} label="Y" variant="outlined" value={ctrlData.y !== null ? ctrlData.y : ""}
                        onChange={(e) => handleEditControlData({ x: ctrlData.x, y: e.target.value })} />
                    {rule.type === "vec3" || rule.type === "vec4" ? (
                        <TextField type="number" autoFocus sx={{ m: '10px', minWidth: '150px' }} label="Z" variant="outlined" value={ctrlData.z !== null ? ctrlData.z : ""}
                            onChange={(e) => handleEditControlData({ x: ctrlData.x, y: ctrlData.y, z: e.target.value })} />
                    ) : (null)}
                    {rule.type === "vec4" ? (
                        <TextField type="number" autoFocus sx={{ m: '10px', minWidth: '150px' }} label="W" variant="outlined" value={ctrlData.w !== null ? ctrlData.w : ""}
                            onChange={(e) => handleEditControlData({ x: ctrlData.x, y: ctrlData.y, z: ctrlData.z, w: e.target.value })} />
                    ) : (null)}
                </Box>
            )
        }
        else if (rule.type === "list") {
            // console.log("editing list " + JSON.stringify(ctrlData));
            return (

                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Typography> Do not change the key values if you wish to use as list</Typography>
                    {ctrlData && Object.keys(ctrlData).map(key => (
                        <Box key={key} sx={{ display: 'flex', alignItems: 'center', m: '10px' }}>
                            <TextField autoFocus={editingDictKey === key} label="Key" sx={{ mx: "10px" }}
                                value={editingDictKey === key ? dictKey : key}
                                onBlur={(e) => { setEditingDictKey(''); setDictKey('') }}
                                onFocus={(e) => { setEditingDictKey(key) }}
                                onChange={(e) => setDictKey(e.target.value)}></TextField>
                            {editingDictKey === key ? (
                                <IconButton onMouseDown={(e) => {
                                    console.log("editing key " + key + " to " + dictKey);
                                    handleEditControlData({ command: 'editKey', prevKey: key, key: dictKey, value: ctrlData[key] })
                                }}>
                                    <DoneIcon />
                                </IconButton>
                            ) : (null)}
                            <TextField label="Value" sx={{ mx: "10px" }} autoFocus={editingDictKeyVal === key} value={ctrlData[key]} onFocus={(e) => { setEditingDictKeyVal(key) }} onBlur={(e) => setEditingDictKeyVal('')} onChange={(e) => handleEditControlData({ command: 'editValue', key: key, value: e.target.value })}>{ctrlData[key]}</TextField>
                            <IconButton onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete this list/dictionary entry?", () => handleEditControlData({ command: 'editValue', key: key, value: null }));
                            }}>
                                <DeleteForeverIcon />
                            </IconButton>

                        </Box>

                    ))}

                    <Box sx={{ display: 'flex', alignItems: 'center', m: '10px' }}>
                        <TextField autoFocus={editingDictKey === '' && editingDictKeyVal === '' && (!dictVal || dictVal === '')} sx={{ m: '10px', minWidth: '150px' }} label="New Value" variant="outlined" value={dictVal ?? ''} onChange={(e) => setDictVal(e.target.value)} />

                        <Button variant="contained" sx={{ m: '10px' }} onClick={() => { handleEditControlData({ command: 'push', value: dictVal }); setDictVal(''); }} >Push Item</Button>
                    </Box>
                </Box>
            )

        }
        else {
            return null;
        }



    }
    const CloudFunctionExpression = ({ cloudFunction }) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'stretch', flexDirection: 'column', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>
                    <TextField label="RealtimeData Args" sx={{ m: '10px' }}
                        autoFocus={editingCloudFunctionArg === "realtimeDataArgs" && editingCloudFunctionName === cloudFunction.name}
                        value={cloudFunction.config && cloudFunction.config.realtimeDataArgs ? cloudFunction.config.realtimeDataArgs.toString() : []}
                        onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "realtimeDataArgs", e.target.value.replace(" ", "").split(","))}
                    ></TextField>
                    <TextField label="Server Args" sx={{ m: '10px' }}
                        autoFocus={editingCloudFunctionArg === "serverArgs" && editingCloudFunctionName === cloudFunction.name}
                        value={cloudFunction.config && cloudFunction.config.serverArgs ? cloudFunction.config.serverArgs.toString() : []}
                        onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "serverArgs", e.target.value.replace(" ", "").split(","))}
                    ></TextField>
                    {cloudFunction.name === "setValueWithExpression" && (
                        <TextField label="Destination Property" sx={{ m: '10px' }}
                            autoFocus={editingCloudFunctionArg === "destPropertyId" && editingCloudFunctionName === cloudFunction.name}
                            value={cloudFunction.config && cloudFunction.config.destPropertyId ? cloudFunction.config.destPropertyId : ""}
                            onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "destPropertyId", e.target.value)}
                        ></TextField>
                    )}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>

                    <TextField label="Location Id" sx={{ m: '10px' }}
                        autoFocus={editingCloudFunctionArg === "locationId" && editingCloudFunctionName === cloudFunction.name}
                        value={cloudFunction.config && cloudFunction.config.locationId ? cloudFunction.config.locationId : ""}
                        onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "locationId", e.target.value, e.target.selectionStart)}
                        onFocus={(e) => e.target.setSelectionRange(editingCloudCursorPosition, editingCloudCursorPosition)}
                    ></TextField>
                    <TextField label="Scope" sx={{ m: '10px' }}
                        autoFocus={editingCloudFunctionArg === "scope" && editingCloudFunctionName === cloudFunction.name}
                        value={cloudFunction.config && cloudFunction.config.scope ? cloudFunction.config.scope : ""}
                        onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "scope", e.target.value, e.target.selectionStart)}
                        onFocus={(e) => e.target.setSelectionRange(editingCloudCursorPosition, editingCloudCursorPosition)}
                    ></TextField>
                    <TextField label="list2D ID" sx={{ m: '10px' }}
                        autoFocus={editingCloudFunctionArg === "list2DId" && editingCloudFunctionName === cloudFunction.name}
                        value={cloudFunction.config && cloudFunction.config.list2DId ? cloudFunction.config.list2DId : ""}
                        onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "list2DId", e.target.value, e.target.selectionStart)}
                        onFocus={(e) => e.target.setSelectionRange(editingCloudCursorPosition, editingCloudCursorPosition)}
                    ></TextField>
                </Box>
                <TextField scrollEnabled label="expression" sx={{ m: '10px' }}
                    autoFocus={editingCloudFunctionArg === "expression" && editingCloudFunctionName === cloudFunction.name}
                    value={cloudFunction.config && cloudFunction.config.expression ? cloudFunction.config.expression : ""}
                    onChange={(e) => handleEditCloudFunctionConfig(cloudFunction.name, "expression", e.target.value, e.target.selectionStart)}
                    onFocus={(e) => e.target.setSelectionRange(editingCloudCursorPosition, editingCloudCursorPosition)}
                ></TextField>
            </Box>)
    }
    const renderLocationData = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>



                    <Box sx={{ display: 'flex', alignItems: 'stretch', mb: '64px' }}>
                        <Button variant="contained" sx={{ m: '10px' }} onClick={() => handleAddLocationDataRule(newDataRulePropId)}>Add Location Data Rule</Button>
                        <TextField sx={{ m: '10px', minWidth: '150px' }} label="Property ID" variant="outlined" value={newDataRulePropId}
                            onChange={(e) => setNewDataRulePropId(e.target.value)} />
                    </Box>


                    {locationDataRules.map((rule) => (
                        <Box key={rule.propid} sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>

                            <Typography variant="h6" sx={{ ml: '10px', maxWidth: '250px', flexGrow: 1 }}>{rule.propid}</Typography>
                            <TextField multiline sx={{ flexGrow: 1, maxWidth: '350px', my: '15px' }} label="Instruction" variant="outlined" value={rule.tooltip} onChange={(e) => handleEditLocationDataRuleTooltip(rule.propid, e.target.value)} />
                            <Select
                                sx={{ minWidth: '200px', m: '16px', flexGrow: 1, maxWidth: '100px' }}
                                value={rule.type}
                                onChange={(e) => handleEditLocationDataRuleType(rule.propid, e.target.value)}
                            >
                                <MenuItem value={"number"}>number</MenuItem>
                                <MenuItem value={"string"}>string</MenuItem>
                                <MenuItem value={"boolean"}>boolean</MenuItem>
                                <MenuItem value={"vec2"}>vec2</MenuItem>
                                <MenuItem value={"vec3"}>vec3</MenuItem>
                                <MenuItem value={"vec4"}>vec4</MenuItem>
                                <MenuItem value={"list"}>list</MenuItem>
                            </Select>

                            <IconButton onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete this rule?", () => handleRemoveLocationDataRule(rule.propid));
                            }}>
                                <DeleteForeverIcon />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
    const renderCloudFunctions = () => {
        let naming = {
            "createArtworkLocationPairs": "Pair Locations",
            "clearRealtimeData": "Clear Realtime Data",
            "storeArtworkRealtimeData": "Copy Realtime Data to Storage",
            "setValueWithExpression": "Set Value with Expression",
        }
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>



                    <Box sx={{ display: 'flex', alignItems: 'stretch', mb: '64px' }}>
                        <Button variant="contained" sx={{ m: '10px' }} onClick={() => handleAddCloudFunction(newCloudFunction)}>Add Cloud Function</Button>
                        <FormControl sx={{ m: '10px' }}>
                            <InputLabel id="select">Function</InputLabel>
                            <Select sx={{ m: '10px', minWidth: '250px' }} label="select" variant="outlined" value={newCloudFunction} onChange={(e) => setNewCloudFunction(e.target.value)} >
                                <MenuItem value={"createArtworkLocationPairs"}>Pair Locations</MenuItem>
                                <MenuItem value={"clearRealtimeData"}>Clear Realtime Data</MenuItem>
                                <MenuItem value={"storeArtworkRealtimeData"}>Copy Realtime Data to Storage</MenuItem>
                                {adminRole === "master" && (<MenuItem value={"setValueWithExpression"}>Set Value With Expression</MenuItem>)}
                            </Select>
                        </FormControl>
                    </Box>



                    {cloudFunctions.map((func) => (
                        <Box key={func.name} sx={{ my: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', borderBottom: '1px solid rgba(255, 255, 255, 0.3)' }}>
                            <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 1, alignItems: 'center' }}>

                                <Typography variant="h6" sx={{ flexGrow: 1, ml: '10px', width: '80%' }}>{naming[func.name]}</Typography>


                                <Switch sx={{ mr: '10px' }} checked={func.enabled ?? true} onChange={(e) => handleCloudFuncitonEnabled(func.name, e.target.checked)} />
                                <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>
                                    <Button variant="contained" sx={{ m: '10px' }} onClick={() => handleTriggerCloudFunction(func.name, func.config)}>Trigger</Button>
                                </Box>
                                {triggeredFunction === func.name ? (
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                        <CircularProgress />
                                    </Box>
                                ) : (null)}
                                <IconButton onClick={(e) => {
                                    console.log("Going to delete rule", naming[func.name]);
                                    startYNDialogAction(e, "Are you sure you want to delete this rule?", () => handleRemoveCloudFunction(func.name));
                                }}>
                                    <DeleteForeverIcon />
                                </IconButton>

                            </Box>
                            {func.lastTriggered ? (
                                <Typography noWrap sx={{ flexGrow: 1, ml: '10px' }}>Last Triggered: {func.lastTriggered}</Typography>
                            ) : (null)}
                            <Box sx={{ ml: '10px', my: '10px', width: '80%', display: 'flex', alignItems: 'stretch', }}>
                                <FormControl sx={{ width: '300px' }}>
                                    <InputLabel id="shcedule">Schedule</InputLabel>
                                    <Select sx={{ m: '10px', minWidth: '250px' }} label="schedule" variant="outlined" value={func.schedule} onChange={(e) => handleChangeCloudFunctionData(func.name, e.target.value)} >
                                        <MenuItem value={"none"}>None</MenuItem>
                                        <MenuItem value={"daily"}>Daily</MenuItem>
                                        <MenuItem value={"hourly"}>Hourly</MenuItem>
                                        <MenuItem value={"every 5 minutes"}>Every 5 minutes</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField type="number" InputProps={{ inputProps: { min: 0, max: 49 } }} label="Execution Order" sx={{ m: '10px' }}
                                    value={func.config && func.config.executionOrder ? func.config.executionOrder : 0}
                                    onChange={(e) => handleEditCloudFunctionConfig(func.name, "executionOrder", e.target.value)}

                                ></TextField>
                            </Box>
                            {func.name === "clearRealtimeData" && (
                                <Box sx={{ ml: '10px', alignItems: 'stretch', flexDirection: 'column', flexGrow: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'stretch', flexGrow: 1 }}>

                                        <FormControl sx={{ mx: '10px', mb: '10px', width: 300 }}>
                                            <InputLabel id="properties-label">RealtimeData Properties</InputLabel>
                                            <Select
                                                sx={{ mr: '10px', minWidth: '250px' }}
                                                labelId="properties-label"
                                                id="properties"
                                                multiple
                                                value={func.config && func.config.realtimeDataProperties ? func.config.realtimeDataProperties : []}
                                                onChange={(e) => handleEditCloudFunctionConfig(func.name, "realtimeDataProperties", e.target.value)}
                                            >
                                                {realtimeDataRules.map((rule) => (
                                                    <MenuItem
                                                        key={rule.propid}
                                                        value={rule.propid}
                                                    >
                                                        {rule.propid}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {adminRole === "master" && (
                                            <ToggleButton sx={{ alignSelf: 'center' }} selected={func.config.useExpression ?? false} onChange={() => handleEditCloudFunctionConfig(func.name, "useExpression", (!func.config.useExpression ?? false))}>
                                                <CodeIcon />
                                            </ToggleButton>
                                        )}


                                    </Box>
                                    {adminRole === "master" && func.config.useExpression === true && (
                                        <CloudFunctionExpression cloudFunction={func} />
                                    )}
                                </Box>
                            )}
                            {adminRole === "master" && func.name === "setValueWithExpression" && (
                                <CloudFunctionExpression cloudFunction={func} />

                            )}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }



    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                    <TopBar title={'Edit Experience'} backNavigate={'/'} />


                    <Box sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                            <Tab label="Main Info" />
                            <Tab onClick={() => {
                                navigator.geolocation.getCurrentPosition(function (position) {
                                    setUserLocation([position.coords.latitude, position.coords.longitude]);
                                    handleSetView(position.coords.latitude, position.coords.longitude);


                                });
                            }} label="Map" />
                            <Tab label="Collaborators" />
                            <Tab label="Realtime Data" />
                            <Tab label="Location Data" />
                            <Tab label="Cloud Functions" />
                        </Tabs>
                    </Box>

                    {tabValue === 0 ?
                        renderMainInfo()
                        : tabValue === 1 ?
                            renderMap()
                            : tabValue === 2 ?
                                renderCollaborators()
                                : tabValue === 3 ?
                                    renderRealtimeData()
                                    : tabValue === 4 ?
                                        renderLocationData()
                                        : tabValue === 5 ?
                                            renderCloudFunctions()
                                            : (null)}

                    {renderYNDialog()}
                </Box>
            )}
        </ThemeProvider>
    );
}



export default EditExperience; 