import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
  },

  typography: {
    fontFamily: "roboto",
  }
});

export default theme;