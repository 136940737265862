import { Router, Navigate } from "react-router-dom";
import React from 'react';

import { ThemeProvider } from  '@mui/system';
import theme from './theme';

import MainService from "./MainService"; 
import MainContext from "./MainContext"; 

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


export default class PrivateRoute extends React.Component{
	
	static contextType = MainContext; 
	
	constructor(props){
		super(props); 
		this.state = {auth: MainService.AUTHING}; 
	}
	
	componentDidMount(){
		
		const mainService = this.context;
		
		this.setState({auth: mainService.GetAuthed()}); 
		
		mainService.AddAuthChangeCallback( (authed)=>{
			setTimeout(()=>{
				
				this.setState({auth: authed}); 
				
			}, 500);
		}); 
	}
	
	componentWillUnmount(){
		
	}
	
	render(){
	
		let routeRender = null;
		
		if(this.state.auth === MainService.AUTHED){
			routeRender = ()=>{return this.props.children}; 
		}
		
		else if(this.state.auth === MainService.AUTHING){
			routeRender = ()=>{return(
			<ThemeProvider theme={theme}>
				<Box sx={{marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
					<CircularProgress />
				</Box>
			</ThemeProvider>
			)}; 
		}
		
		else {
			routeRender = ()=>{return(<Navigate to={ {pathname: '/signin' } }/>)}; 
		}
		
		
		return routeRender(); 
	}
}