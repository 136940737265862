import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import validator from 'validator';

import MainContext from "../MainContext";
import TopBar from "../components/TopBar";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
// import Button from "@mui/material-next/Button";
import CircularProgress from '@mui/material/CircularProgress';
import { Slider, IconButton, ToggleButton, TextField, Tabs, Tab, Switch, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';


import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents, CircleMarker, Circle, Rectangle, FeatureGroup } from 'react-leaflet'
import L, { featureGroup, map } from 'leaflet';
import { DraftControl } from "react-leaflet-draft";


import artMarker from '../assets/artwork-marker.svg';
import orgMarker from '../assets/rkf_logo.png';

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import { set } from "firebase/database";
import BundleUpload from "../components/BundleUpload";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchWindow from "../components/SearchDialog";
import SearchDialog from "../components/SearchDialog";
import ImageUpload from "../components/ImageUpload";
import Credits from "../components/Credits";
import { name } from "dayjs/locale/en-gb";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Timezones } from "../Timezones";
import ViewInArIcon from '@mui/icons-material/ViewInAr';

const storageRoot = 'exhibitiondata';

const markerIcon = new L.Icon({
    iconUrl: artMarker,
    iconRetinaUrl: artMarker,
    iconAnchor: [25, 50],
    iconSize: new L.Point(50, 50)
});

const orgMarkerIcon = new L.Icon({
    iconUrl: orgMarker,
    iconRetinaUrl: orgMarker,
    iconAnchor: [15, 15],
    iconSize: new L.Point(30, 30)
});






const EditExhibition = () => {
    //use 
    const mainService = useContext(MainContext);

    const navigate = useNavigate();
    const mapRef = useRef();
    const mapEditControlRef = useRef();
    const featureGroupRef = useRef();
    //states
    const [loadingMode, setLoadingMode] = useState(false);
    const [uploadingContent, setUploadingContent] = useState(false);
    const [adminRole, setAdminRole] = useState('collab');
    const [searchParams] = useSearchParams();
    const [myArtworks, setMyArtworks] = useState([]);
    const [showArtworkSerachDialog, setShowArtworkSerachDialog] = useState(false);
    const [showOrganisationSerachDialog, setShowOrganisationSerachDialog] = useState(false);


    const [tabValue, setTabValue] = useState(0);

    const [titles, setTitles] = useState({});
    const [subtitles, setSubtitles] = useState({});
    const [address, setAddress] = useState('');
    const [shortDescription, setShortDescription] = useState({});
    const [longDescription, setLongDescription] = useState({});
    const [website, setWebsite] = useState('');
    const [searchValue, setSearchValue] = useState('');
    // const [instancesNum, setExhibitionInstancesNum] = useState(0);
    const [showAllArtworks, setShowAllArtworks] = useState(true);

    const [organisations, setOrganisations] = useState([]);
    const [myOrganisations, setMyOrganisations] = useState([]);
    const [liveArtworks, setLiveArtworks] = useState([]);
    const [locationDataRules, setlocationDataRules] = useState([]);
    const [exhibitionLocation, setExhibitionLocation] = useState({});
    const [bundlePlatform, setBundlePlatform] = useState('IOS');

    const [liveInApp, setLiveInApp] = useState(false);

    const [artworkLocations, setArtworkLocations] = useState([]);
    const [artworkLocationDatas, setArtworkLocationDatas] = useState([]);

    const [curators, setCurators] = useState([]);
    const [curatorEmail, setCuratorEmail] = useState("");
    const [selectedArtworkId, setSelectedArtworkId] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [addressError, setAddressError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [mapLayers, setMapLayers] = useState([]);
    const [mapDrawingEnabled, setMapDrawingEnabled] = useState(true);
    const [area, setArea] = useState(null);
    const [profileImagesUrls, setProfileImagesUrls] = useState({});
    const [contentImagesUrls, setContentImagesUrls] = useState({});

    const [hasBundle, setHasBundle] = useState(new Set());
    const [placementType, setPlacementType] = useState('Geospatial');
    const [bundleMetadatas, setBundleMetadatas] = useState({});

    const [artworks, setArtworks] = useState([]);
    const [isEditingArea, setIsEditingArea] = useState(false);
    const [thumbnailURL, setCoverImageURL] = useState('');
    const [thumbnailKey, setThumbnailKey] = useState('');
    const [credits, setCredits] = useState([]);
    const [timezone, setTimezone] = useState('Europe/London');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [exhImageExists, setExhImageExists] = useState(false);

    const eid = searchParams.get('eid');
    

    const exhibitionMarkerIcon = new L.Icon({
        iconUrl: orgMarker,
        iconRetinaUrl: mainService.getImageThumbnailUrl('exhibitiondata','mapmarker','100x100',eid),
        iconAnchor: [15, 15],
        iconSize: new L.Point(30, 30)
    });

    //callbacks
    const handleAdminRoleChanged = (role) => {
        setAdminRole(role);
    }

    const handleExhibitionMetadataChanged = (metadata) => {

        if (!metadata) {
            navigate('/', { state: "exhibition" });
            return;
        }

        if(metadata.titles)
        setTitles(metadata.titles);
        if(metadata.subtitles)
        setSubtitles(metadata.subtitles);
        setAddress(metadata.address);
        setWebsite(metadata.website);

        setArea(metadata.area);
        if (metadata.area) {

            //add layer to map
            setMapDrawingEnabled(false);

        }


        setLiveInApp(metadata.liveInApp ? true : false);


        // Bundle metadata

        if (metadata.placementType)
            setPlacementType(metadata.placementType);

        let newHasBundle = new Set(hasBundle.values());

        if (metadata.bundleKey_Windows) {
            newHasBundle.add('Windows');
            getBundleMetadata('exhibition', 'Windows', metadata.bundleKey_Windows);
        }
        else
            newHasBundle.delete('Windows');

        if (metadata.bundleKey_Android) {
            newHasBundle.add('Android');
            getBundleMetadata('exhibition', 'Android', metadata.bundleKey_Android);

        }
        else
            newHasBundle.delete('Android');

        if (metadata.bundleKey_IOS) {
            newHasBundle.add('IOS');
            getBundleMetadata('exhibition', 'IOS', metadata.bundleKey_IOS);
        }
        else
            newHasBundle.delete('IOS');

        if (metadata.bundleKey_MacOS) {
            newHasBundle.add('MacOS');
            getBundleMetadata('exhibition', 'MacOS', metadata.bundleKey_MacOS);
        }
        else
            newHasBundle.delete('MacOS');



        if (metadata.bundleKey_Windows_Metabundle) {
            newHasBundle.add('Windows_Metabundle');
            getBundleMetadata('exhibition', 'Windows_Metabundle', metadata.bundleKey_Windows_Metabundle);
        }
        else
            newHasBundle.delete('Windows_Metabundle');

        if (metadata.bundleKey_Android_Metabundle) {
            newHasBundle.add('Android_Metabundle');
            getBundleMetadata('exhibition', 'Android_Metabundle', metadata.bundleKey_Android_Metabundle);
        }
        else
            newHasBundle.delete('Android_Metabundle');

        if (metadata.bundleKey_IOS_Metabundle) {
            newHasBundle.add('IOS_Metabundle');
            getBundleMetadata('exhibition', 'IOS_Metabundle', metadata.bundleKey_IOS_Metabundle);
        }
        else
            newHasBundle.delete('IOS_Metabundle');

        if (metadata.bundleKey_MacOS_Metabundle) {
            newHasBundle.add('MacOS_Metabundle');
            getBundleMetadata('exhibition', 'MacOS_Metabundle', metadata.bundleKey_MacOS_Metabundle);
        }
        else
            newHasBundle.delete('MacOS_Metabundle');

        setHasBundle(newHasBundle);
        setLiveInApp(metadata.liveInApp ? true : false);






        if (metadata.shortDescriptions)
            setShortDescription(metadata.shortDescriptions);
        if (metadata.longDescriptions)
            setLongDescription(metadata.longDescriptions);

        if (metadata.timezone)
            setTimezone(metadata.timezone);
        setStartDate(metadata.startDate);
        setEndDate(metadata.endDate);
        setCredits(metadata.creditsList);
        if (metadata.artworks) {
            let newArtworks = Object.keys(metadata.artworks);
            if (artworks !== newArtworks) {
                let promises = [];
                //get all urls
                newArtworks.forEach((artid) => {
                    promises.push(mainService.getImageThumbnailUrl("artworkdata", "cover","100x100", artid));
                });
                setArtworks(metadata.artworks ? Object.keys(metadata.artworks) : null);
                Promise.all(promises).then((urls) => {
                    let newUrls = {};
                    urls.forEach((url, index) => {
                        // console.log("artwork thumbnail url: " + url);
                        newUrls[newArtworks[index]] = url;
                    });
                    setContentImagesUrls(newUrls);
                });
            }
        }
        if (metadata.organisations) {
            let newOrgs = Object.keys(metadata.organisations);
            if (organisations !== newOrgs) {
                let promises = [];
                //get all urls
                newOrgs.forEach((orgid) => {
                    promises.push(mainService.getImageThumbnailUrl('organisationdata','profile', '100x100', orgid));
                });
                setOrganisations(metadata.organisations ? newOrgs : null);
                Promise.all(promises).then((urls) => {
                    let newUrls = {};
                    urls.forEach((url, index) => {
                        newUrls[newOrgs[index]] = url;
                        console.log("org thumbnail url: " + url);
                    });

                    setProfileImagesUrls(newUrls);
                });

            }
        }





    }
    const handleExhibitionLocationChanged = (location) => {
        if (location) {
            for (const locid in location) {
                setExhibitionLocation(location[locid]);
            }
        }


    }

    const handleCuratorsChanged = (curators) => {

        if (!curators) {
            navigate('/', { state: "exhibition" });
            return;
        }

        let curats = [];
        if (curators) {
            for (const cur in curators) {
                curats.push({ safeEmail: cur, role: curators[cur] });
            }
        }
        setCurators(curats);
    }

    const handleLiveArtworksChanged = (artworks) => {
        let artworkids = [];
        for (const artworkid in artworks) {
            artworkids.push(artworkid);
        }


        let newMyArtworks = [];
        let myNewLocationDataRules = [];
        let promiseList = [];
        artworkids.forEach((aid) => {
            if(!aid)
            {
                console.error("artwork id is null");
            }
            else {
                promiseList.push(
                    mainService.getArtworkMetadata(aid)
                        .then((data) => {
                            if(data)
                            {
                                data.id = aid;
                                if (data.isTest === false)
                                newMyArtworks.push(data);
                            }
                        })
                );
                promiseList.push(
                    mainService.getArtworkLocationDataRules(aid)
                    .then((data) => {
                        if (data) {
                            // go through all keys and add exhibitionid
                            for (const key in data) {
                                let prop = data[key];
                                prop.propid = key;
                                prop.artid = aid;
                                myNewLocationDataRules.push(prop);
                            }
                        }
                    })
                    )
                }
        });
        Promise.all(promiseList)
            .then(() => {
                //sort alphabetically by title
                newMyArtworks.sort((a, b) => (a.titles["English"] > b.titles["English"]) ? 1 : -1);
                setLiveArtworks(newMyArtworks);
                setlocationDataRules(myNewLocationDataRules);
            });
    }
    const handleArtworkLocationDataChanged = (data, returnData) => {
        if (!data) {
            mainService.removeDataListener("artworkLocations/" + returnData.artid + "/" + returnData.locid, handleArtworkLocationDataChanged);
            return;
        }
        setArtworkLocationDatas((oldarray) => {
            let arr = [...oldarray];

            let locData = arr.find(loc => loc.artid === returnData.artid && loc.locid === returnData.locid);
            //get index of locData
            let index = arr.indexOf(locData);
            if (!locData) {
                data.artid = returnData.artid;
                data.locid = returnData.locid;
                arr.push(data);
            }
            else {
                data.artid = returnData.artid;
                data.locid = returnData.locid;
                arr[index] = data;
            }
            // console.log("handleArtworkLocationDataChanged: " + JSON.stringify(arr));


            return arr;
        }
        );
        console.log("new data: " + JSON.stringify(artworkLocationDatas));
    }
    const handleAdminOrgRelationshipsChanged = (relationships) => {
        let promises = [];
        let newMyOrgs = [];
        for (const orgid in relationships) {
            promises.push(mainService.getOrganisationMetadataProperty(orgid, "name"));
        }
        Promise.all(promises).then((names) => {
            names.forEach((name, index) => {
                let data = {};
                data.id = Object.keys(relationships)[index];
                data.name = name;
                newMyOrgs.push(data);
            });
            setMyOrganisations(newMyOrgs);

        });
    }

    const handleOrgArtLocationsRelationshipsChanged = (relationships) => {
        let artLocs = [];
        for (const exhibitionid in relationships) {
            for (const locid in relationships[exhibitionid]) {
                let data = {};
                data.locid = locid;
                data.artid = exhibitionid;
                artLocs.push(data);
            }
        }


        //compare artLocs to artworkLocations and remove listeners for removed relationships
        if (artworkLocations) {
            let removed = artworkLocations.filter(loc => !artLocs.some(artLoc => artLoc.artid === loc.artid && artLoc.locid === loc.locid));
            removed.forEach(loc => {
                mainService.removeDataListener("artworkLocations/" + loc.artid + "/" + loc.locid, handleArtworkLocationDataChanged);
            });
        }



        let newLocations = [];
        let promiseList = [];
        artLocs.forEach((loc) => {
            promiseList.push(
                mainService.getArtworkLocation(loc.artid, loc.locid)
                    .then((data) => {
                        if (!data) {
                            console.error("data is null: " + loc.artid + " " + loc.locid + " deleting relationship");
                            mainService.deleteExhibitionArtworkLocationRelationship(eid, loc.artid, loc.locid);
                        }
                        else {
                            data.artid = loc.artid;
                            data.locid = loc.locid;
                            newLocations.push(data);
                        }
                    })
            );
        });
        Promise.all(promiseList)
            .then(() => {
                setArtworkLocations(newLocations);
                //add listeners for all new locations
                let added = newLocations.filter(loc => !artworkLocations.some(artLoc => artLoc.artid === loc.artid && artLoc.locid === loc.locid));
                added.forEach(loc => {
                    console.log("adding listener for artwork location: " + loc.artid + " " + loc.locid);
                    mainService.addDataListener("artworkLocations/" + loc.artid + "/" + loc.locid, handleArtworkLocationDataChanged, { artid: loc.artid, locid: loc.locid });
                });
                //change selected artwork if all instances have been placed for the current selected artwork
                if (selectedArtworkId) {
                    let count = newLocations.filter(loc => loc.artid === selectedArtworkId).length;
                    console.log("count for selected artwork " + count);
                    // if (count >= instancesNum) {
                    //     let nextArtwork = liveArtworks.find(artwork => artwork.id !== selectedArtworkId && newLocations.filter(loc => loc.artid === artwork.id).length < instancesNum);
                    //     console.log("next artwork: " + JSON.stringify(nextArtwork));
                    //     if (nextArtwork)
                    //         setSelectedArtworkId(nextArtwork.id);
                    // }
                }

            });
    }
    const handleEditStartDate = (val) => {
        // console.log('start date: ' + val.UTC());
        // setStartDate(val);
        let dt = dayjs(val).isValid() && dayjs.utc(val).toISOString();
        console.log('start date: ' + dt);
        handleEditMetadataProperty("startDate", dt);
    }
    const handleEditEndDate = (val) => {
        let dt = dayjs(val).isValid() && dayjs(val).toISOString();
        handleEditMetadataProperty("endDate", dt);
    }
    const handleEditLiveInApp = (event) => {
        let val = event.target.checked;
        if(val === true)
        {
            //check if there are any artworks
            if(artworks.length === 0)
            {
                alert("You must add at least one artwork to the exhibition before you can set it live in the app");
                return;
            }
            if(artworkLocations.length === 0)
            {
                alert("You must place at least one artwork before you can set the exhibition live in the app");
                return;
            }

        }
        mainService.setExhibtionLiveInApp(eid, val);
    }


    const handleArtworkRelationshipsChanged = (relationships) => {
        let artworkids = [];
        for (const artworkid in relationships) {
            artworkids.push(artworkid);
        }

        let newMyArtworks = [];
        let promiseList = [];
        artworkids.forEach((artworkid) => {
            promiseList.push(
                mainService.getArtworkMetadata(artworkid)
                    .then((data) => {
                        if (data) {
                            data.id = artworkid;
                            newMyArtworks.push(data);
                        }
                        else {
                            console.error('artwork metadata not found: ' + artworkid);
                        }
                    })
            );
        });

        Promise.all(promiseList)
            .then(() => {
                setMyArtworks(newMyArtworks);
                if (myArtworks.length === 0)
                    setTabValue(1);
            });
    }
    const handleExhibitionDelete = (event) => {
        console.log("Exhibition delete!!");
        mainService.deleteExhibition(eid)
            .then(() => {
                navigate('/', { state: "exhibition" });
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const handleArtworksChanged = (artworks) => {
        console.log('artworks changed: ' + Object.keys(artworks).length);
        let newMyArtworks = [];
        let promiseList = [];
        for (const artworkid in artworks) {
            let data = artworks[artworkid];
            data.id = artworkid;
            newMyArtworks.push(data);
        };


        setMyArtworks(newMyArtworks);

    }


    const handleEditTitle = (event) => {
        let val = event.target.value;
        mainService.setExhibitionTitle(eid, selectedLanguage, val);
    }
    const handleEditAddress = (event) => {
        let val = event.target.value;
        setAddress(val);
    }
    const handleFinishEditAddress = (event) => {
        let val = event.target.value;
        mainService.setExhibitionAddress(eid, val, (suc) => {
            setAddressError(!suc);
        });
    }
    const handleEditShortDescription = (event) => {
        let val = event.target.value;
        mainService.setExhibitionMetadataProperty(eid, "shortDescriptions/" + selectedLanguage, val);
    }
    const handleEditLongDescription = (event) => {
        let val = event.target.value;
        mainService.setExhibitionMetadataProperty(eid, "longDescriptions/" + selectedLanguage, val);
    }
    const handleEditWebsite = (event) => {
        let val = event.target.value;

        setWebsiteError(!validator.isURL(val));

        mainService.setExhibitionWebsite(eid, val);
    }
    const handleEditMetadataProperty = (property, value) => {
        mainService.setExhibitionMetadataProperty(eid, property, value);
    }

    const handleEditTimezone = (event) => {
        let val = event.target.value;
        handleEditMetadataProperty("timezone", val);
        dayjs.tz.setDefault(val);

    }

    const handleAddLocation = (e) => {
        let lat = e.latlng.lat;
        let lng = e.latlng.lng;
        let geocodeLocation = getArtworkMetadata(selectedArtworkId).geocodeLocations ?? false;
        console.log("Adding location: " + lat + " " + lng + " geocode: " + geocodeLocation);
        mainService.addArtworkLocationExhibition(eid, selectedArtworkId, lat, lng, geocodeLocation);
    }

    const handleDeleteLocation = (data) => {
        mainService.deleteArtworkLocation(data.artid, data.locid);
    }

    const handleOnLocationDataEdit = (exhibitionid, locationid, propertyid, value) => {
        mainService.setLocationData(exhibitionid, locationid, propertyid, value);
    }


    const handleAddAdmin = () => {
        console.log("Adding admin");
        mainService.addExhibitionAdmin(eid, curatorEmail);
        setCuratorEmail("");
    }

    const handleDeleteCurator = (e) => {
        console.log("Deleting admin");
        mainService.deleteExhibitionAdmin(eid, e.curatorEmail);
    }

    const handleSearch = async () => {
        let loc = await mainService.getLocationFromSearch(searchValue);
        handleSetView(loc.lat, loc.lng);
    }


    const handleSetView = (lat, lng) => {
        mapRef.current.setView([lat, lng], 18);
    }

    const handleEditLocDataButton = (exhibitionid, locationid) => {
        setSelectedLocationId(locationid);
        setSelectedArtworkId(exhibitionid);
        setLocationDataDialogOpen(true);
    }

    const handleExhibitionNoteEdit = (text) => {
        console.log("handleExhibitionNoteEdit " + text);
        let currData = artworkLocationDatas.find((loc) => loc.locid === selectedLocationId);
        let data = currData["exhibitionNote"] ?? {};
        data[selectedLanguage] = text;
        mainService.setArtworkLocationData(selectedArtworkId, selectedLocationId, "exhibitionNote", data);
    }
    const handleAddArtworkClicked = (e) => {
        console.log("handleAddArtworkClicked");
        setShowArtworkSerachDialog(true);
    }
    const handleRemoveArtworkClicked = (id) => {
        console.log("handleRemoveArtworkClicked");
        mainService.removeArtworkFromExhibition(eid, id);
    }
    const handleAddOrganisationClicked = (e) => {
        setShowOrganisationSerachDialog(true);
    }
    const handleRemoveOrganisationClicked = (id) => {
        mainService.setExhibitionMetadataProperty(eid, "organisations/" + id, null);
    }

    //#region Bundle Handlers

    const getBundleMetadata = (type, plafromAndType, key) => {
        mainService.getBundleMetadata(type, eid, plafromAndType, key).then((metadata) => {
            //add to bundleMetadatas
            setBundleMetadatas((prev) => {
                let newBundleMetadatas = { ...prev };
                newBundleMetadatas[plafromAndType] = metadata;
                return newBundleMetadatas;
            });

        })
            .catch((error) => {
                console.error("Error getting bundle metadata: " + error);
            });

    }
    const handleBundleUpload = (event) => {
        console.log('trying to upload');
        let platform = event.platform;
        let bundleType = event.bundleType;
        if (event.target.files.length === 0)
            return;
        console.log('handling bundle upload ' + platform + ' ' + bundleType);
        setUploadingContent(true);
        mainService.uploadBundle(eid, 'exhibition', event.target.files[0], platform, bundleType)
            .then(() => {
                setUploadingContent(false);
            });
    }
    const handleBundleDelete = (e) => {
        console.log('bundle delete ' + e.platform + ' ' + e.bundleType);
        setUploadingContent(true);
        mainService.deleteBundle(eid, 'exhibition', e.platform, e.bundleType)
            .then(() => {
                setUploadingContent(false);
            })
    }


    //#endregion


    //Effects
    useEffect(() => {

        //on mount
        console.log('EditExhibition mounted');
        let uid = mainService.Uid();
        let safeEmail = mainService.safeEmail();

        mainService.addDataListener("adminRoles/" + uid, handleAdminRoleChanged);
        mainService.addDataListener("liveArtworks", handleLiveArtworksChanged);
        mainService.addDataListener("adminOrganisationRelationships/" + safeEmail, handleAdminOrgRelationshipsChanged);

        mainService.imageExists(mainService.getImageThumbnailUrl('exhibitiondata','mapmarker','100x100',eid),(exists)=>{
            console.log("exhibition image exists: " + exists);
            setExhImageExists(exists);
        });


        if (eid) {
            mainService.addDataListener("exhibitionMetadatas/" + eid, handleExhibitionMetadataChanged);
            mainService.addDataListener("exhibitionArtworkLocationRelationships/" + eid, handleOrgArtLocationsRelationshipsChanged);
            mainService.addDataListener("exhibitionAdmins/" + eid, handleCuratorsChanged);
            mainService.addDataListener("exhibitionLocations/" + eid, handleExhibitionLocationChanged);

        }
        else {
            navigate('/', { state: "exhibition" });
        }

        //on unmount
        return () => {
            console.log('EditExhibition unmounted');
            mainService.removeDataListener("adminRoles/" + uid, handleAdminRoleChanged);
            mainService.removeDataListener("liveArtworks", handleLiveArtworksChanged);
            mainService.removeDataListener("adminOrganisationRelationships/" + safeEmail, handleAdminOrgRelationshipsChanged);

            if (eid) {
                mainService.removeDataListener("exhibitionMetadatas/" + eid, handleExhibitionMetadataChanged);
                mainService.removeDataListener("exhibitionArtworkLocationRelationships/" + eid, handleOrgArtLocationsRelationshipsChanged);
                mainService.removeDataListener("exhibitionAdmins/" + eid, handleCuratorsChanged);
                mainService.removeDataListener("exhibitionLocations/" + eid, handleExhibitionLocationChanged);
                if (artworkLocations && artworkLocations.length > 0)
                    artworkLocations.forEach((artworkLocation) => {
                        mainService.removeDataListener("artworkLocations/" + artworkLocation.exhibitionid + "/" + artworkLocation.locationid, handleArtworkLocationDataChanged);
                    });
            }
        }

    }, []);

    //Setup Dialog box yn popup
    const [yesNoDialogOpen, setYesNoDialogOpen] = React.useState(false);
    const [locationDataDialogOpen, setLocationDataDialogOpen] = React.useState(false);
    const [selectedLocationId, setSelectedLocationId] = React.useState("");
    const [yesNoDialogText, setYesNoDialogText] = React.useState("");
    const [yesNoDialogCall, setYesNoDialogCall] = React.useState({ callback: null });

    const startYNDialogAction = (event, text, callback) => {
        setYesNoDialogOpen(true);
        setYesNoDialogText(text);
        let e = event;

        setYesNoDialogCall({
            callback: () => {
                if (callback)
                    callback(e);
            }
        });
    }

    const renderLocationDataDialog = () => {

        let rules = locationDataRules.filter((rule) => rule.artid === selectedArtworkId);
        let locationData = artworkLocationDatas.find((loc) => loc.locid === selectedLocationId);

        return (
            <Dialog
                open={locationDataDialogOpen}
                onClose={() => { setLocationDataDialogOpen(false) }}
                fullWidth={true}
            >
                <DialogTitle >
                    {getArtworkMetadata(selectedArtworkId).title}
                </DialogTitle>
                <DialogContent>
                    <Typography >Enter location-specific infromation</Typography>
                    <Box sx={{ flexGrow: 1, mx: '8px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                        {rules && locationData && rules.map(rule => (
                            <Box sx={{ display: 'flex', my: '5px', alignItems: 'center', padding: '10px', backgroundColor: "#262626", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                                <Typography key={rule.propid + "_tooltip"} sx={{ flexGrow: 1 }}>{rule.tooltip}</Typography>
                                <TextField key={rule.propid} sx={{ maxWidth: '170px', borderRadius: "5px", border: '1px solid rgba(255,255,255,0.2)', my: '8px' }}
                                    label={rule.propid} variant="outlined" value={locationData["locationData"] ? (locationData["locationData"][rule.propid] ?? "") : ""} onChange={(e) => handleOnLocationDataEdit(selectedArtworkId, selectedLocationId, rule.propid, e.target.value)}></TextField>
                            </Box>
                        ))}
                        <FormControl sx={{ display: 'flex', flexWrap: 'wrap', my: '10px' }}>
                            <InputLabel id="select">Language</InputLabel>
                            <Select labelId="select" label="Language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                <MenuItem value={"English"}>English</MenuItem>
                                <MenuItem value={"Swedish"}>Swedish</MenuItem>
                            </Select>

                        </FormControl>
                        {locationData &&
                            <TextField label="Why did you choose this location?" variant="outlined" multiline sx={{ flexGrow: 1, my: '10px' }}
                                value={locationData["exhibitionNote"] ? locationData["exhibitionNote"][selectedLanguage] ?? "" : ""}
                                onChange={(e) => handleExhibitionNoteEdit(e.target.value)} />}

                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setLocationDataDialogOpen(false); }}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }


    const renderYNDialog = () => {
        return (
            <Dialog
                open={yesNoDialogOpen}
                onClose={() => { setYesNoDialogOpen(false) }}
            >
                <DialogTitle >
                    {yesNoDialogText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setYesNoDialogOpen(false); }}>No</Button>
                    <Button onClick={() => { yesNoDialogCall.callback(); setYesNoDialogOpen(false); }} autoFocus>Yes</Button>
                </DialogActions>
            </Dialog>
        )
    }
    //Setup Prompt box
    const [promptOpen, setPromptOpen] = React.useState(false);
    const [promptText, setPromptText] = React.useState("");
    const startPromptAction = (event, text) => {
        setPromptOpen(true);
        setPromptText(text);
        let e = event;

    }
    const renderPrompt = () => {
        return (
            <Dialog
                open={promptOpen}
                onClose={() => { setPromptOpen(false) }}
            >
                <DialogTitle >
                    {promptText}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => { setPromptOpen(false); }} autoFocus>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }


    //Render main info
    const renderMainInfo = () => {

        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                    <Box sx={{ display: 'flex', flexGrow: 2, flexDirection: 'row'}}>
                        <Box sx={{ display: 'flex', m:'10px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                            <Typography sx={{ m: '10px',  }} variant="h7">Time and Place</Typography>    
                            <TextField sx={{ m: '10px' }} label="Address" variant="outlined" value={address} onChange={handleEditAddress} onBlur={handleFinishEditAddress} />
                            {addressError && <Typography sx={{ m: '10px', }} variant="subtitle1" color={'red'}>Address not recognised. Please enter a valid address.</Typography>}
                            <TextField sx={{ m: '10px' }} label="Website" variant="outlined" value={website} onChange={handleEditWebsite} />
                            {websiteError && <Typography sx={{ m: '10px', }} variant="subtitle1" color={'red'}>Website is not an URL. Please enter correct website address (eg. www.example.com)</Typography>}
                            <FormControl sx={{ m: "10px" }}>
                                <InputLabel id="tz">Time zone</InputLabel>
                                <Select labelId="tz" label="Time zone" value={timezone} onChange={handleEditTimezone}>
                                    {Timezones.sort().map((tz, index) =>
                                        <MenuItem key={index} value={tz}>{tz}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                                <DateTimePicker sx={{ maxWidth: '900px', flexGrow: 1, mx: "30px", width: '90%' }} renderInput={(props) => <TextField {...props} sx={{ m: '10px' }} />} label="Opening date" value={dayjs(startDate)} onChange={handleEditStartDate} />
                                <DatePicker sx={{ maxWidth: '900px', flexGrow: 1, width: '90%' }} renderInput={(props) => <TextField {...props} sx={{ m: '10px' }} />} label="End date" value={dayjs(endDate)} onChange={handleEditEndDate} />
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ display: 'flex', m:'10px', p:'10px', flexDirection: 'column', alignItems: 'stretch', width: '100%', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Typography>Cover</Typography>
                        <ImageUpload sx={{ display: 'block',  width:'100%' }} 
                        title={titles ? titles["en"] : ""} 
                        key={"cover"}
                        type="cover"
                        mainService={mainService}
                        id={eid}
                        storagePath="exhibitiondata" />
                        <Typography>Map marker</Typography>
                        <ImageUpload sx={{ display: 'block',  width:'100%' }} 
                        key={"mapmarker"}
                        title={titles ? titles["en"] : ""} 
                        type="mapmarker"
                        mainService={mainService}
                        id={eid}
                        width = {'100px'}
                        height = {'100px'}
                        storagePath="exhibitiondata" />
                        </Box>
                    </Box>
                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box sx={{ m: '10px', display: 'flex', flexDirection: 'horizontal',alignSelf:'stretch'}} variant="h7">
                            <Typography sx={{flexGrow: 2,  m: '10px',  }} variant="h7">Localized Text</Typography>
                            <FormControl sx={{ display: 'flex', flexGrow:1 }}>
                                <InputLabel id="select">Language</InputLabel>
                                <Select labelId="select" label="Language" value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                                    <MenuItem value={"en"}>English</MenuItem>
                                    <MenuItem value={"sv"}>Swedish</MenuItem>
                                </Select>

                            </FormControl>
                        </Box>
                        <TextField sx={{ m: '10px', flexGrow: 1, minWidth: '150px' }} label="Title" variant="outlined" value={titles[selectedLanguage] ?? ""} onChange={handleEditTitle} />
                        <TextField sx={{ m: '10px', flexGrow: 1, minWidth: '150px' }} label="Subtitle" variant="outlined" value={subtitles[selectedLanguage] ?? ""} onChange={(e)=>{handleEditMetadataProperty("subtitles/"+ selectedLanguage, e.target.value)}} />
                        <TextField multiline maxRows={4} inputProps={{ maxLength: 300 }} sx={{ m: '10px', }} label={"Description (" + selectedLanguage + "; max. 300 characters)"} variant="outlined" value={shortDescription[selectedLanguage] ?? ""} onChange={handleEditShortDescription} />
                        <TextField multiline sx={{ m: '10px', }} label={"LongDescription (" + selectedLanguage + ")"} variant="outlined" value={longDescription[selectedLanguage] ?? ""} onChange={handleEditLongDescription} />
                        <Credits dbRoot="exhibitionMetadatas" id={eid} credits={credits} mainServiceRef={mainService} language={selectedLanguage} />
                    </Box>


                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box sx={{ m: '10px', flexDirection: 'horizontal' }} variant="h7">
                            <Typography sx={{ m: '10px', }} variant="h7">Content</Typography>
                            <IconButton sx={{}} onClick={handleAddArtworkClicked}>
                                <AddIcon />
                            </IconButton>


                        </Box>
                        <List dense={true}>

                            {artworks ? artworks.map((id, index) =>

                                <ListItem key={id}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveArtworkClicked(id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            {contentImagesUrls[id] ? (
                                                <Avatar alt={getArtworkMetadata(id).titles ? getArtworkMetadata(id).titles["English"] ?? "" : ""} src={contentImagesUrls[id]} />
                                            ) : <ViewInArIcon />}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={getArtworkMetadata(id).titles ? getArtworkMetadata(id).titles["English"] ?? "" : ""}
                                        secondary={getArtworkMetadata(id).artist ?? ""}
                                    />
                                </ListItem>


                            ) : null}




                        </List>
                    </Box>


                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>
                        <Box sx={{ m: '10px', flexDirection: 'horizontal' }} variant="h7">
                            <Typography sx={{ m: '10px', }} variant="h7">Organisations</Typography>
                            <IconButton sx={{}} onClick={handleAddOrganisationClicked}>
                                <AddIcon />
                            </IconButton>


                        </Box>
                        <List dense={true}>

                            {organisations ? organisations.map((id, index) =>

                                <ListItem key={id}
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveOrganisationClicked(id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <Avatar alt={getOrganisationName(id)} src={profileImagesUrls[id]} />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={getOrganisationName(id)}
                                    />
                                </ListItem>


                            ) : null}




                        </List>
                    </Box>
                    <Box sx={{ m: '10px', display: 'flex', flexDirection: 'column', flexShrink: 1, flexGrow: 1, flexWrap: 'wrap', backgroundColor: "#171717", borderRadius: "10px", border: '1px solid rgba(255,255,255,0.2)' }}>

                        <Typography sx={{ m: '10px', }} variant="h7">Exhibition Layer</Typography>
                        <Box sx={{ m: '10px', display: 'flex', flexShrink: 1, flexGrow: 1 }}>

                            <FormControl sx={{ m: '10px', width: "30%" }}>
                                <InputLabel id="select">Bundle Platform</InputLabel>
                                <Select labelId="select" label="Bundle Platform" value={bundlePlatform} onChange={(e) => setBundlePlatform(e.target.value)}>
                                    <MenuItem value="IOS">IOS</MenuItem>
                                    <MenuItem value="Android">Android</MenuItem>
                                    <MenuItem value="Windows">Windows</MenuItem>
                                    <MenuItem value="MacOS">MacOS</MenuItem>
                                </Select>
                            </FormControl>
                            {/* <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', flexShrink: 1, flexGrow: 1  , justifyContent: 'center', alignItems: 'center'}}> */}
                            <BundleUpload platform={bundlePlatform} bundleMetadatas={bundleMetadatas} hasBundle={hasBundle} isUploading={uploadingContent}
                                onUploadClick={handleBundleUpload} onDeleteClick={(e) => { startYNDialogAction(e, "Are you sure you want to delete the bundle?", handleBundleDelete); }} />
                            {/* </Box> */}
                        </Box>
                    </Box>
                    {adminRole === 'master' ? (
                        <React.Fragment>

                            <Box sx={{ mt: '50px', mb: '20px', borderTop: '1px solid rgba(255, 255, 255, 0.5)' }}>
                                Master User Settings
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: '32px' }}>
                                <Switch sx={{ mr: '10px' }} checked={liveInApp} onChange={handleEditLiveInApp} />
                                <Typography>Live in app</Typography>
                            </Box>


                            <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Are you sure you want to delete this exhibition? This can't be undone!", handleExhibitionDelete);
                            }}>Delete Exhibition</Button>

                        </React.Fragment>
                    ) : (null)}



                </Box>
            </Box>
        )
    }

    //Render Map 
    const handleOnCreateMapEdit = (e) => {
        console.log(e);
        setMapDrawingEnabled(false);


        const { layerType, layer } = e;
        if (layerType === 'polygon') {
            //check there is already a layer in mapLayers

            const { _leaflet_id } = layer;

            let geoData = layer.toGeoJSON();


            mainService.setExhibitionArea(eid, geoData);
            console.log('setting editing to false');
            setIsEditingArea(false);



        }

    }
    const handleOnEditMapEdit = (e) => {
        console.log(e);
        const {
            layers: { _layers },
        } = e;

        let layer = Object.values(_layers)[0];
        const { _leaflet_id } = layer;


        let data = layer.toGeoJSON();


        mainService.setExhibitionArea(eid, data);
        console.log('setting editing to false');
        setIsEditingArea(false);

    }
    const handleOnDeleteMapEdit = (e) => {
        console.log(e);
        setMapDrawingEnabled(true);
        mainService.setExhibitionArea(eid, null);
        setIsEditingArea(false);
        console.log('setting editing to false');

        // const {
        //     layers: { _layers },
        //   } = e;

        //   Object.values(_layers).map(({ _leaflet_id }) => {
        //     setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
        //   });
    }
    const handleDialogArtworkSelected = (id) => {
        mainService.addArtworkToExhibition(eid, id);
        setShowArtworkSerachDialog(false);
    }
    const handleDialogOrganisationSelected = (id) => {
        mainService.setExhibitionMetadataProperty(eid, "organisations/" + id, true);
        setShowOrganisationSerachDialog(false);
    }

    const MapController = () => {
        const map = useMapEvents({
            dblclick(e) {
                if (!selectedArtworkId) {
                    startPromptAction(e, "Please select an artwork to add to the exhibition");
                    return;
                }
                if (!isEditingArea)
                    startYNDialogAction(e, "Add artwork here?", handleAddLocation);
            },
        });

        const handleLocate = () => {
            map.locate();
            // map.flyTo(userLocation, map.getZoom());
        }


        return (

            <Box sx={{ position: 'absolute', right: '10px', top: '10px', zIndex: 500, alignContent: 'center', justifyContent: 'center' }}>

                <ToggleButton value={showAllArtworks} onChange={(e) => setShowAllArtworks(!showAllArtworks)}>
                    <AutoAwesomeMotionIcon opacity={showAllArtworks ? 1 : 0.7} sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></AutoAwesomeMotionIcon>
                </ToggleButton>
                <IconButton onClick={() => handleLocate()}>
                    <MyLocationIcon sx={{ zIndex: 500, color: 'rgb(20, 20, 20)', width: '32px', height: '32px' }} ></MyLocationIcon>
                </IconButton>
            </Box>
        );
    }
    const handleSelectedArtworkChange = (event, exhibitionid) => {
        setSelectedArtworkId(exhibitionid);
    };
    let _editableFG = null;

    const _onFeatureGroupReady = (ref) => {
        if (ref === null) {
            return;
        }
        if (!area) {
            return;
        }
        _editableFG = ref;

        let leafletGeoJSON = new L.GeoJSON(area);

        if (ref.getLayers().length === 0)
            leafletGeoJSON.eachLayer(layer => ref.addLayer(layer));

    };

    const renderMap = () => {

        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '1200px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' }}>
                        <FormControl sx={{ minWidth: '200px', maxWidth: '400px', mx: '16px', my: '8px', flexGrow: 1 }}>
                            <InputLabel id="select">Artwork to place</InputLabel>
                            {/* <Select labelId="select" label="Artwork to place" value={selectedArtworkId && artworkLocations.filter(l => l.artid === selectedArtworkId).length < instancesNum ? selectedArtworkId : ""} onChange={(e) => handleSelectedArtworkChange(e, e.target.value)}> */}
                            <Select labelId="select" label="Artwork to place" value={selectedArtworkId} onChange={(e) => handleSelectedArtworkChange(e, e.target.value)}>
                                {artworks && artworks.length > 0 && liveArtworks.filter(a => artworks.includes(a.id)).map((artwork, index) =>
                                    //only add artworks that are not already placed
                                    // artworkLocations.filter(l => l.artid === artwork.id).length < instancesNum &&
                                    <MenuItem key={index} value={artwork.id}>{artwork.artist + " : " + artwork.titles["English"] ?? ""}</MenuItem>

                                )}
                            </Select>
                        </FormControl>


                        <TextField sx={{ flexGrow: 1, minWidth: '150px', mx: '16px', my: '8px' }} label="Search" variant="outlined"
                            value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                            InputProps={{ endAdornment: <IconButton onClick={() => handleSearch()}><SearchIcon /></IconButton> }} />
                        <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center', alignItems: 'center', mx: '16px', my: '8px' }}>
                            <TextField sx={{ mx: '10px' }} type="number" label="Latitude" variant="outlined" value={lat} onChange={(e) => setLat(parseFloat(e.target.value))} />
                            <TextField sx={{ mx: '10px' }} type="number" label="Longitude" variant="outlined" value={lng} onChange={(e) => setLng(parseFloat(e.target.value))} />
                            <IconButton onClick={() => handleSetView(lat, lng)}><SearchIcon /></IconButton>
                        </Box>
                        <Button sx={{ mx: '10px' }} variant="contained" onClick={(e) => handleManualPlace({ latlng: { lat: lat, lng: lng } })}>Place</Button>
                    </Box>


                    <MapContainer
                        ref={mapRef}
                        center={exhibitionLocation}
                        zoom={16}
                        scrollWheelZoom={true}
                        maxZoom={26}
                        doubleClickZoom={false}
                        inertia={true}
                        preferCanvas={true}
                        style={{ flexGrow: 1, margin: '10px', overflow: 'hidden', borderRadius: '8px', minHeight: '600px' }}
                    >

                        <FeatureGroup ref={reactFGref => {
                            _onFeatureGroupReady(reactFGref);
                        }} >

                            <EditControl onEditStart={(e) => { setIsEditingArea(true) }} onDrawStart={(e) => { setIsEditingArea(true) }} onCreated={handleOnCreateMapEdit} onEdited={handleOnEditMapEdit} onDeleted={handleOnDeleteMapEdit}
                                draw={{
                                    rectangle: false,
                                    polyline: false,
                                    circle: false,
                                    circlemarker: false,
                                    marker: false,
                                    polygon: mapDrawingEnabled ? {
                                        allowIntersection: false,
                                        shapeOptions: {
                                            color: 'red'
                                        }
                                    } : false
                                }}

                            />
                        </FeatureGroup>
                        <TileLayer
                            maxZoom={26}
                            maxNativeZoom={19}
                            url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                            subdomains={['mt1', 'mt2', 'mt3']}
                        />
                        <MapController />

                        <Marker position={exhibitionLocation} icon={exhImageExists ? exhibitionMarkerIcon : orgMarkerIcon} />
                        {lat && lng && <CircleMarker center={[lat, lng]} radius={10} pathOptions={{ color: 'orange' }} />}
                        <ArtworkLocationMarkers />


                    </MapContainer>

                </Box>
            </Box>
        )
    }
    const handleManualPlace = (e) => {
        startYNDialogAction(e, "Add artwork here?", handleAddLocation);
    }




    const ArtworkLocationMarkers = () => {
        let artworkLocationsToDisplay = artworkLocations.filter((loc) => (loc.artid === selectedArtworkId || showAllArtworks));
        return (artworkLocationsToDisplay.map((loc) =>
            <Marker key={loc.locid} position={[loc.lat, loc.lng]} opacity={loc.artid === selectedArtworkId ? 1 : 0.8} title={getArtworkMetadata(loc.artid).titles ? getArtworkMetadata(loc.artid).titles["English"] : getArtworkMetadata(loc.artid).title} icon={markerIcon}>
                <Popup>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography gutterBottom variant="subtitle2">{getArtworkMetadata(loc.artid).titles ? getArtworkMetadata(loc.artid).titles["English"] : getArtworkMetadata(loc.artid).title}</Typography>
                        <Typography gutterBottom variant="subtitle">by {getArtworkMetadata(loc.artid).artist}</Typography>
                        <Typography gutterBottom variant="subtitle">{"lat: " + loc.lat}</Typography>
                        <Typography gutterBottom variant="subtitle">{"lng: " + loc.lng}</Typography>
                        <Typography gutterBottom variant="subtitle">{"alt: " + loc.alt ?? "null"}</Typography>
                        <Button sx={{ mt: "15px" }} variant='contained' onClick={(e) => {
                            handleEditLocDataButton(loc.artid, loc.locid);
                        }}>
                            Edit Location Data
                        </Button>
                        {liveArtworks.some((artwork) => artwork.id === loc.artid) &&
                            <Button sx={{ mt: "10px" }} color='warning' variant='contained' onClick={(e) => {
                                startYNDialogAction({ artid: loc.artid, locid: loc.locid }, "Are you sure you want to remove this location?", handleDeleteLocation);
                            }}>
                                Delete Location
                            </Button>
                        }
                    </Box>
                </Popup>
            </Marker>)
        )
    }

    const getArtworkMetadata = (exhibitionid) => {
        if (!liveArtworks || !liveArtworks.some((artwork) => artwork.id === exhibitionid))
            return { title: "Test artwork location - contact support!" };
        return liveArtworks.find((artwork) => artwork.id === exhibitionid);
    }
    const getOrganisationName = (organisationid) => {
        if (!myOrganisations || !myOrganisations.some((org) => org.id === organisationid))
            return null;
        return myOrganisations.find((org) => org.id === organisationid).name;
    }

    //Render Collaborators 
    const renderAdmins = () => {
        return (
            <Box sx={{ flexGrow: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '900px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>

                    <React.Fragment>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: '32px' }}>
                            <TextField sx={{ m: '10px', flexGrow: 1, minWidth: '150px' }} label="Email" variant="outlined"
                                value={curatorEmail} onChange={(e) => setCuratorEmail(e.target.value)} />

                            <Button variant="contained" sx={{ m: '10px' }} onClick={(e) => {
                                startYNDialogAction(e, "Add " + curatorEmail + " as a admin?", handleAddAdmin);
                            }}>Add Admin</Button>

                        </Box>
                    </React.Fragment>

                    {curators.map((curator) => (
                        <Box key={curator.safeEmail} sx={{ height: '50px', px: '8px', borderBottom: '1px solid rgba(255, 255, 255, 0.3)', display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ mr: '8px' }}>{curator.safeEmail.replaceAll('%2E', '.')}</Typography>
                            <Box sx={{ flexGrow: 1 }} />

                            {curator.safeEmail!== mainService.safeEmail() && adminRole === 'master' ? (
                                <IconButton onClick={(e) => {
                                    e.curatorEmail = curator.safeEmail;
                                    startYNDialogAction(e, "Are you sure you want to remove this collaborator?", handleDeleteCurator);
                                }}>
                                    <DeleteForeverIcon />
                                </IconButton>
                            ) : (null)}

                        </Box>
                    ))}

                </Box>
            </Box>
        )
    }



    //Main
    return (
        <ThemeProvider theme={theme}>
            {loadingMode ? (
                <Box sx={{ marginTop: '64px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', }}>
                    <TopBar title={'Edit Exhibition'} backNavigate={'/'}/>


                    <Box sx={{ alignSelf: 'stretch', display: 'flex', justifyContent: 'center' }}>
                        <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
                            <Tab label="Exhibition Info" />
                            <Tab label="Map" />
                            <Tab label="Admins" />
                        </Tabs>
                    </Box>

                    {tabValue === 0 ?
                        renderMainInfo()
                        : tabValue === 1 ?
                            renderMap()
                            : tabValue === 2 ?
                                renderAdmins()
                                : (null)}
                    {renderLocationDataDialog()}
                    {renderYNDialog()}
                    {renderPrompt()}
                    <SearchDialog open={showArtworkSerachDialog} onClose={() => setShowArtworkSerachDialog(false)} searchResults={artworks ? liveArtworks.filter(a => !artworks.includes(a.id)) : liveArtworks} onSelected={handleDialogArtworkSelected} />
                    <SearchDialog open={showOrganisationSerachDialog} onClose={() => setShowOrganisationSerachDialog(false)} searchResults={organisations ? myOrganisations.filter(o => !organisations.includes(o.id)) : myOrganisations} onSelected={handleDialogOrganisationSelected} />
                </Box>
            )}
        </ThemeProvider>
    );
}

export default EditExhibition; 