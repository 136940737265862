import { Routes, Router, MemoryRouter, BrowserRouter, Switch, Route ,Navigate} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import React from 'react';

import Home from "./pages/Home"; 
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail";
import EditExperience from "./pages/EditExperience";
import EditOrganisation from "./pages/EditOrganisation";
import Guide from "./pages/Guide";
import AppGuide from "./pages/AppGuide";
import ArtworkLocations from "./pages/ArtworkLocations";
import FAQ from "./pages/FAQ";
import EditProfile from "./pages/EditProfile"; 
import EditExhibition from "./pages/EditExhibition";
import AppConfig from "./pages/AppConfig";
import MasterControls from "./pages/MasterControls";

const MyRoutes = () => {
    return(
        <BrowserRouter >
            <Routes>
                <Route path="/" exact={true} element={<PrivateRoute> <Home/> </PrivateRoute>} />
                <Route path="/editexperience" element={<PrivateRoute> <EditExperience/> </PrivateRoute>} />
                <Route path="/editorganisation" element={<PrivateRoute> <EditOrganisation/> </PrivateRoute>} />
                <Route path="/editexhibition" element={<PrivateRoute> <EditExhibition/> </PrivateRoute>} />
                <Route path="/settings" element={<PrivateRoute> <EditProfile/> </PrivateRoute>} />
                <Route path="/signup" element={<SignUp/>} />
                <Route path="/signin" element={<SignIn/>} />
                <Route path="/resetpassword" element={<ResetPassword/>} />
                <Route path="/verifyemail" element={<VerifyEmail/>} />
                <Route path="/guide" element={<Guide/>} />
                <Route path="/appguide" element={<AppGuide/>} />
                <Route path="/artworklocations" element={<ArtworkLocations/>} />
                <Route path="/faq" element={<FAQ/>} />
                <Route path="/appconfig" element={<AppConfig/>} />
                <Route path="/mctrl" element={<MasterControls/>} />
            </Routes>
        </BrowserRouter>
    )
}

export default MyRoutes;