import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ThemeProvider } from '@mui/system';
import theme from '../theme';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";

const FAQ = () => {

    useEffect(() => {

        console.log('FAQ mounted');

        return () => {
            console.log('FAQ unmounted');
        }
    }, []);

    //Main
    return (
        <ThemeProvider theme={theme}>

<Box sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
            px: '16px'
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              px: '10px',
              height: '60px',
              borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
              width: '100%',
              maxWidth: '700px'
            }}>
              <Typography>
                <Link to={'/'} style={{ color: 'white' }}>Meadow Console</Link>
              </Typography>
            </Box>
      
            <Box sx={{
              maxWidth: '700px',
              width: '100%',
              flexGrow: 1,
              mx: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              pt: '32px'
            }}>
                    <Box sx={{ maxWidth: '700px', flexGrow: 1, mx: '16px', display: 'flex', flexDirection: 'column', alignItems: 'stretch', pt: '32px' }}>
                        <Typography sx={{ my: '16px' }} variant='h4'>Vanliga frågor och svar om appen</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Vilket är lösenordet för att få tillgång till appen?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Lösenordet är “member”.</Typography>
                        
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Hur går man ur ett konstverk i Appen?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Swipa in från sidan på telefonen när du är i kameravyn. Tryck på knappen högst upp till vänster, sen Exit artwork. Detta kommer att förtydligas med information i appen.</Typography>
                        
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kommer appen att finnas på svenska?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja, det kommer i nästa uppdatering.</Typography>
                        
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kommer det finnas förklaringar av appens funktioner?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja, det kommer finnas en tydlig förklaring för alla funktioner. Detta kommer i nästa uppdatering.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Jag kan inte se några verk på kartan?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Just nu har appen ett fel där verk som är placerad mer än en halv km bort inte syns på kartan. Detta är löst och kommer i nästa uppdatering.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Funkar appen utan internetuppkoppling?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Nej. Kartan behöver internet för att se var du är, och AR-vyn behöver internet för att placera verken korrekt i den fysiska världen. Om ni ser att ni har dåligt internet på en av platserna för verken så byt placering till en med bättre internet.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Appen fungerar inte på min telefon / är inte kompatibel med min telefon?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Inte alla telefoner är kompatibla med AR. Här är en lista över Android-telefoner som är kompatibla med AR. För iPhone är det alla telefoner från 6s och uppåt.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body"><u><a href="https://developers.google.com/ar/devices" style={{ color: 'white' }} target="_blank">Här är en lista över Android-telefoner som är kompatibla med AR. </a></u></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">För iPhone är det alla telefoner från 6s och uppåt.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kommer appen vara tillgänglig för alla?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja, från utställningens öppningsdag 20 maj fram till sista dag för utställningen 30 september kommer appen vara tillgänglig för alla. Vårt mål är att så många som möjligt ska få uppleva Meadow.</Typography>

                        <Typography sx={{ my: '16px' }} variant='h4'>Vanliga frågor och svar om konstverken</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Behöver vi söka tillstånd för placeringen av verken?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Nej, eftersom verken är digitala och ses via appen Meadow så krävs inte tillstånd för att visa dem. Om ni väljer en plats för någon av verken som ligger i nära anslutning till t.ex. en butik eller annan verksamhet och ni har möjlighet får ni gärna berätta för personal/butiksinnehavare om utställningen och verket. Detta är dock valfritt och inte ett krav, men kan vara ett bra sätt att sprida ordet om Meadow.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kan man placera om verken när man vill?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja. Det är upp till er att välja placeringar. Era placeringar av verken på kartan uppdateras direkt i appen. Detta så att ni kan byta position vid tex byggarbete, eller om ni inser att en placering inte fungerar av någon annan anledning.</Typography>
                   
                        <Typography sx={{ my: '16px' }} variant="body"><strong>Vad gör vi om platstypen inte finns i vår kommun?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">I konstnärernas texter om platstyperna vill de förmedla en känsla/idé som de förknippar sitt verk med. Här får ni tänka hur brett ni vill och göra egna associationer om vad som skulle kunna passa som en alternativ plats. Vill ni bolla någon placering med oss får ni gärna hör av er, men ni bestämmer själva var ni vill placera verken.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kommer det finnas förklaringar till konstverkens funktioner?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja, de verk som har mer komplexa interaktioner kommer att ha en av konstnärerna specifikt utvecklad förklaring.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Vad gör vi om det inte går att placera verket exakt vid platstypen (t.ex. en bänk eller gatubrunn)?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">I vissa fall kan det vara svårt att placera verket tillräckligt precist pga. kvaliteten på satellitbilden. Om så är fallet, placera verket så nära platsen ni kan, och under ”edit location data” får ni gärna skriva en instruktion till betraktaren i stil med ”sätt dig på bänken” eller ”gå fram till gatubrunnen”.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Hur stor plats tar verken?</strong></Typography>
                        <ul>
                        <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Alla verken tar upp olika plats. Oavsett storlek så är det bra om det finns extra plats runt verken och de inte är i ett trångt utrymme, detta för att flera ska kunna uppleva verket samtidigt och för att besökare ska vara bekväma att gå runt i verken. Om ni väger mellan olika platser så välj den större.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Åsa Cederqvist: Verket i sig är cirka 5x5 meter, men kräver en större yta för att flera ska kunna uppleva det samtidigt. Varje besökare upplever verket själv, så om två ska uppleva det samtidigt krävs alltså en yta på minst 10x10m.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Adam James: cirka 25x25 meter</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Pastelae: cirka 30x30 meter</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Space Popular: cirka 10x10 meter</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">SONG: cirka 4x4 meter</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">James Webb: verket anpassar sig efter betraktaren.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Eric Magassa: verket anpassar sig efter betraktaren</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Oscar Häggström: verket anpassar sig efter betraktaren, men platsen måste vara öppen så att betraktaren kan röra sig.</Typography>
                            </li>
                            <li style={{ marginBottom: '16px' }}>
                                <Typography sx={{ my: '16px' }} component="body">Lundahl & Seitl: verket anpassar sig efter betraktaren, men platsen måste vara öppen så att betraktaren kan röra sig.</Typography>
                            </li>
                        </ul>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Kommer det finnas ytterligare information om verken?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Ja, konstnärerna och kuratorn kommer att skriva en längre text om varje verk som finns tillgängligt i appen.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Vad menas med verksspecifik information, och hur lägger vi in den?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Vissa verk behöver specific information gällande just er placering av verket. När ni har placerat ett verk kan ni trycka på kartnålen och sen på Edit Location Data, för att fylla i informationen. Det är inte alla verk som ber om detta.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Vad menas med fountain radius i Lundahl & Seitl's verk?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Lundahl & Seitl ber alla konstföreningar att skriva in radie på den plats de har valt för deras verk. Detta för att verket ska få rätt storlek för att passa in på just den platsen ni valt. I de fall ni har placerat verket vid en fontän så är radien mitten på fontänen till kanten. I de fall fontänen inte är rund väljer ni den största radien. I de fall ni har placerat ut verket vid ett träd kan ni välja en radie som fungerar bra runt trädet, tex 2m.</Typography>

                        <Typography sx={{ my: '16px' }} variant="body"><strong>Adam James ber om en bild till sitt verk?</strong></Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Adam James ber alla deltagande föreningar att dela en bild som ni känner på något vis symboliserar er. Adam beskriver det såhär: Jag skulle väldigt gärna vilja att ni som organisation delar med mig av en bild av något slag som på vilket sätt ni än väljer att tolka den säger något om er som kollektiv. Det kan vara politiskt, satiriskt, poetiskt, hittat, medskapat, nytt, gammalt, abstrakt eller vad du än väljer. Det kan vara något som talar till dina värderingar, ambitioner, prestationer eller till och med ånger. Enkelt uttryckt, det finns inget rätt eller fel val.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">Det finns två sätt att bidra med en bild, välj det som passar er bäst:</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">1. Ladda upp en url till bilden när ni placerar ut verket, genom att trycka på placeringen och sen Location Data.</Typography>
                        <Typography sx={{ my: '16px' }} variant="body">2. Skicka bild + namn på er förening till adam@mradamjames.com</Typography>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider >
    );
}

export default FAQ; 