import React, {useState, useEffect, useContext } from "react";

import MainService from "../MainService"; 
import MainContext from "../MainContext"; 

import { ThemeProvider } from  '@mui/system';
import theme from '../theme';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const ResetPassword = () => {
    const mainService = useContext(MainContext);


    const handleSubmit = (event) => {
        event.preventDefault();
		const data = new FormData(event.currentTarget);
        let obj = {
            email: data.get('email'),
        }
		mainService.SendPasswordResetEmail(obj.email);
        //console.log(obj);
    };

  	return (
	<ThemeProvider theme={theme}>

		<Typography sx={{m:'64px'}} align='center' variant='h4'>Meadow Console</Typography>
      	<Container component="main" maxWidth="xs">
			  
			
			<Box sx={{marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
				Sign in
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{ mt: 3, mb: 2 }}
					>
					Send Password Reset
					</Button>
					<Grid container>
						<Grid item xs>
							<Link href="/signin" variant="body2">
							Sign in
							</Link>
						</Grid>
						<Grid item>
							<Link href="/signup" variant="body2">
							{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid>
				</Box>
        	</Box>
      	</Container>
    </ThemeProvider>
	);
}

export default ResetPassword; 